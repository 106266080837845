import { environment } from '@env/environment';

const genericEndpointInterpolation = (
  baseurl: string,
  path: string,
  params: (string | number)[] = []
): string => {
  const interpoled = params.reduce<string>(
    (old, curr, i) => old.split(`{{${i}}}`).join(curr.toString()),
    path
  );
  return baseurl + interpoled;
};

export const enum BackEndpoints { // MUST start with a slash (/)
  Root = '/',

  // new services
  CustomerPlans = '/customer/listCustomerPlans',
  HasAccessToOnlineClass = '/customer/hasAccessToAppointmentLiveClasses',
  ListCustomerOpenGymUnit = '/customer/listCustomerOpenGymUnit',
  CognitoToken = '/bodytechCompany/auth',
  CustomerData = '/customer/data',

  // baby & kids
  UrlKidsGrid = '/babykids/listActivitiesGrid/{{0}}',
  KidsValidateCpf = '/babykids/validateCpf',
  KidsSchedule = '/babykids/scheduleActivity',
  KidsWaitingList = '/babykids/registerWaitingList/{{0}}',

  // Authentication
  AppToken = '/legacy/auth/getAppToken',
  Login = '/legacy/auth/login',
  Logout = '/legacy/auth/logout',
  ResetUserAccessToken = '/legacy/auth/resetUserAccessToken/cpf/{{0}}/registration/{{1}}',

  // Cliente Já Existente - Cartão de Crédito
  GetCustomerCreditCard = '/legacy/customer/getCreditCard/{{0}}',
  UpdateCustomerCreditCard = '/legacy/customer/updateCreditCard',
  UpdateCustomerCreditCardWithRenew = '/legacy/contract/renew/{{0}}',

  // Cupom
  Coupon = '/legacy/purchase/{{0}}/applyDiscountCoupon/{{1}}',
  CuponCliente = '/legacy/purchase/customer/applyDiscountCoupon/{{0}}',

  // Novo Cliente : Passo 1
  RegistrationCheckPayment = '/legacy/purchase/{{0}}/getPaymentMethods',
  RegistrationGetItem = '/legacy/purchase/{{0}}/getShoppingCartInfo',
  RegistrationSetItem = '/legacy/purchase/{{0}}/setShoppingCartItems',
  RegistrationStart = '/legacy/purchase/{{0}}',

  // Novo Cliente : Passo 2
  RegistrationFinish = '/legacy/purchase/{{0}}/finish',
  RegistrationSendAddress = '/legacy/purchase/{{0}}/bindAddressToCustomer',
  RegistrationSendPersonal = '/legacy/purchase/{{0}}/registerCustomer',

  // Compra BT Pass: Passo 1
  RegistrationIncomeStart = '/legacy/purchase/incomes/{{0}}',
  LoggedRegistrationIncomeStart = '/legacy/purchase/customer/incomes/gym/{{0}}',

  // Others
  CheckOperations = '/legacy/customer/checkOperations/gym/{{0}}/groupPlan/{{1}}/plan/{{2}}',
  ConfirmationNewAccount = '/legacy/user/confirmNewAccount/{{0}}',
  Contract = '/legacy/customer/listContracts',
  ContractFinish = '/legacy/contract/cancel/{{0}}/confirm',
  ContractPayment = '/legacy/contract/cancel/{{0}}/paymentMethod',
  ContractStart = '/legacy/contract/cancel/{{0}}',
  Grade = '/legacy/others/listGrades',
  Nation = '/legacy/others/listNationalities',
  NewAccess = '/legacy/user/new/gym/{{0}}',
  PreSalePurchase = '/legacy/purchase/customer/presale/list',
  ProfileLists = '/legacy/others/listProfiles',
  Purchase = '/legacy/customer/listPurchases',
  RecoveryEmail = '/legacy/user/recoveryEmail/cpf/{{0}}/registration/{{1}}',
  RecoveryPasswordFinish = '/legacy/user/updatePassword',
  Relationship = '/legacy/others/listRelationships',
  ResetUserAccess = '/legacy/user/resetAccess/{{0}}',
  Training = '/legacy/customer/listWorkouts/withDetails',
  UpdateAccount = '/legacy/user/edit',
  UpdateUser = '/legacy/customer/edit',
  UserData = '/legacy/customer/getData',
  ValidateCPF = '/legacy/customer/validateCPF/{{0}}',
  ValidateTokenForeignMinor = '/legacy/customer/validateForeignerMinorToken/{{0}}',

  // Pre-venda : Novo Cliente
  PreSaleNewClientFinish = '/legacy/purchase/{{0}}/presale/finish',
  PreSaleNewClientStart = '/legacy/purchase/{{0}}/presale/',

  // Pre-venda : Cliente
  PreSaleCancel = '/legacy/purchase/customer/presale/cancel/gym/{{0}}',
  PreSaleClientFinish = '/legacy/purchase/customer/presale/finish',
  PreSaleStart = '/legacy/purchase/customer/presale/gym/{{0}}',

  // Venda : Passo 1
  PurchaseStart = '/legacy/purchase/customer/gym/{{0}}/plan/{{1}}',
  PurchaseGetItem = '/legacy/purchase/customer/getShoppingCartInfo',
  PurchaseSetItem = '/legacy/purchase/customer/setShoppingCartItems',
  PurchaseCheckPayment = '/legacy/purchase/customer/getPaymentMethods',

  // Venda : Passo 2
  PurchaseFinish = '/legacy/purchase/customer/finish',

  // Trancamento
  consultExtensionData = '/legacy/vacation/consultExtensionData/{{0}}/{{1}}',
  cancelScheduledVacation = '/legacy/vacation/cancelScheduledVacation/{{0}}',
  cancelCurrentVacation = '/legacy/vacation/cancelCurrentVacation/{{0}}',
  setVacation = '/legacy/vacation/setVacation/{{0}}',
  setVacationDCC = '/legacy/vacation/setVacationDCC/{{0}}',
  cancelVacationDCC = '/legacy/vacation/cancelVacationDCC/{{0}}',

  // DocuSign
  checkDocusignTransaction = '/legacy/contract/checkDocusignTransaction/{{0}}/{{1}}/{{2}}',

  // BT Pass
  btPassCardsList = '/legacy/income/btpass/cards',
  btPassGrid = '/legacy/income/btpass/grid',

  // EVO
  EvoCheckMigratingGym = '/evo/unit/isMigratedUnit',
  EvoMigratedUnitsList = '/evo/unit/listEvoMigratedUnits',
  EvoSendRequest = '/legacy/evo/sendRequest',
}

export const enum EvoBackEndpoints {
  Root = '/',
  Login = '/api/v1/members/auth',
  ResetPassword = '/api/v1/members/resetPassword',
  Configuration = '/api/v1/configuration',
  Membership = '/api/v1/membership',
  Activities = '/api/v1/activities',
}

export const back = (
  path: BackEndpoints,
  ...params: (string | number)[]
): string => {
  return genericEndpointInterpolation(
    environment.services.baseUrl,
    path,
    params
  );
};

export const evoBack = (
  path: EvoBackEndpoints,
  ...params: (string | number)[]
): string => {
  return genericEndpointInterpolation(
    environment.services.evoBaseUrl,
    path,
    params
  );
};

export const isMicroservices = (url: string) =>
  url.startsWith(environment.apollo.microservicesBaseUrl);

export const isDocuSignMicroservices = (url: string) =>
  url.startsWith(environment.docmod.microservicesBaseUrl);

export const isToOldCanais = (url: string) =>
  url.startsWith(back(BackEndpoints.Root));

export const enum BlogEndpoints { // MUST start with a slash (/)
  ApiUrl = '/wp-json/wp/v2',
  Media = '/media/{{0}}',
  Post = '/posts/{{0}}',
  Posts = '/posts',
}

export const blog = (
  path: BlogEndpoints,
  ...params: (string | number)[]
): string => {
  return genericEndpointInterpolation(
    `${environment.wordpress.baseUrl}${BlogEndpoints.ApiUrl}`,
    path,
    params
  );
};

export class ExternalEndpoints {
  // MUST be the entire URL, including the protocol (e.g. https://my.domain.com/bla)
  public static readonly backFacebook =
    'https://graph.facebook.com/{{0}}/picture?width=143&height=143';
}

export const external = (
  path: string,
  ...params: (string | number)[]
): string => {
  return genericEndpointInterpolation('', path, params);
};

export const enum SurEndpoints { // MUST start with a slash (/)
  Root = '/',
  AppToken = '/auth',
  CorporateForm = '/corporateForm/',
  CustomerPlans = '/customer/listCustomerPlans',
  FaqBot = '/faq',
  Login = '/login',
  Url = '/graphql',
  UrlGymUnit = '/gymUnit',
  UrlRecoveryEmail = '/userChannel/retrieveEmail',
  UrlSendForgotPasswordEmail = '/userChannel/sendForgotPasswordEmail',
  UrlSendRandomPasswordEmail = '/userChannel/sendRandomPasswordEmail',
  UrlSendUnblockEmail = '/userChannel/sendUnblockEmail',
  UrlUnblockEmail = '/userChannel/unblock/{{0}}',
  UrlUpdateUserPassword = '/userChannel/updatePassword',
}

export const sur = (
  path: SurEndpoints,
  ...params: (string | number)[]
): string => {
  return genericEndpointInterpolation(environment.apollo.baseUrl, path, params);
};

// tslint:disable-next-line:max-classes-per-file
export class AppEndpoints {
  public static get(value = '', ...params: (string | number)[]) {
    // Replaces {{i}} with params[i]
    return params.reduce<string>(
      (old, curr, i) => old.split(`{{${i}}}`).join(curr.toString()),
      value
    );
  }
}
