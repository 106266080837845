import { Component, Input } from '@angular/core';
import { Training } from '@models';

@Component({
  selector: 'app-training-table',
  templateUrl: './training-table.component.html',
  styleUrls: ['./training-table.component.scss'],
})
export class TrainingTableComponent {
  @Input() jsonData: Training[] = [];
}
