import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-concept-fr-card',
  templateUrl: './concept-fr-card.component.html',
  styleUrls: ['./concept-fr-card.component.scss'],
})
export class ConceptFrCardComponent {
  @Input() card: any[];
}
