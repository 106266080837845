import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PriceService } from '@services';
import { ResolveResult } from './type-resolve-bundle';
import { PriceRange } from '@models';

@Injectable()
export class PriceRangeResolveGuard implements Resolve<PriceRange> {
  constructor(private readonly priceService: PriceService) {}

  resolve(): ResolveResult<PriceRange> {
    return this.priceService.getMinMaxPrice();
  }
}
