import { Component, OnInit } from '@angular/core';
import { ModalType } from '@models';
import { ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-schedule',
  templateUrl: './modal-schedule.component.html',
  styleUrls: ['./modal-schedule.component.scss'],
})
export class ModalScheduleComponent
  extends ModalCommonComponent
  implements OnInit {
  sliderConfig = {
    step: 1,
    margin: 2,
    start: [6, 22],
    connect: true,
  };
  schedule = {
    weekday: [0, 24],
    saturday: [0, 24],
    sunday: [0, 24],
    holiday: [0, 24],
  };

  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.schedule;
  }

  update(index: string, event: [number, number]) {
    this.schedule[index] = event;
    this.modalObj.data = this.schedule;
  }
}
