import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BtCompanyResponse } from '@models';
import { Observable } from 'rxjs';

@Injectable()
export class BtCompanyPageService {
  constructor(private readonly http: HttpClient) {}

  getInfo(): Observable<BtCompanyResponse> {
    return this.http.get<BtCompanyResponse>('assets/data/bt-company.json');
  }
}
