import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeneratePasswordService {
  possiblesValues = 'QWERTYUPASDFGHJKZXCVBNMqwertyupasdfghjkzxcvbnm';

  generatePassword(passwordLenght: number): string {
    let password = '';
    for (let i = 0; i < passwordLenght; i++) {
      password +=
        i % 3 === 0
          ? this.possiblesValues.charAt(
              Math.floor(Math.random() * this.possiblesValues.length)
            )
          : Math.floor(Math.random() * 8) + 2;
    }
    return password;
  }
}
