import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionBtItem } from '@models';

@Component({
  selector: 'app-action-bt',
  templateUrl: './action-bt.component.html',
  styleUrls: ['./action-bt.component.scss'],
})
export class ActionBtComponent {
  readonly linkAppBT = 'https://bodytech.page.link/Home';
  readonly linkBTFit = 'https://btfit.page.link/Home';
  public linkWannaJoin = 'https://homolog.bodytech.company/academias';
  public linkRegulation =
    'https://terms.homolog.bodytech.company/terms/btflexivel_regulamento.pdf';

  constructor(readonly route: ActivatedRoute) {
    this.linkWannaJoin = this.route.snapshot.data.config.links.wannaJoin;
    this.linkRegulation = this.route.snapshot.data.config.links.terms;
  }

  readonly actions: ActionBtItem[] = [
    {
      icon: '/assets/action_bt/icon-7dias.svg',
      title: 'Garantimos 7 dias para você experimentar',
      description:
        'Por confiarmos tanto no protocolo seguido pelo nosso time, oferecemos 7 dias. Você contrata nosso plano e se não gostar, pode cancelar em até 7 dias sem custo.',
    },
    {
      icon: '/assets/action_bt/icon-convite.svg',
      title: 'Convide seus amigos para treinar por 30 dias',
      description:
        'A vantagem queridinha entre nós e nossos amigos continua valendo. Qualquer cliente pode convidar pessoas para treinarem por 30 dias pagando apenas uma taxa de ativação.',
    },
    {
      icon: '/assets/action_bt/icon-btfit.svg',
      title: 'App BTFIT Premium com programas e aulas coletivas ao vivo',
      description: `Clientes BT têm direito a uma assinatura do BTFIT, o app da Bodytech com séries, programas e coletivas para complementar os treinos. <a href="${this.linkBTFit}">Abra pelo celular e baixe o app.</a>`,
    },
  ];

  scrollToActions() {
    const el = document.getElementById('our-actions');
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
