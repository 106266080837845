import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalType, GymUnitGroupPlan, ModalGymPlanObject } from '@models';
import { ModalService } from '@services/modal.service';
import { AnalyticsService } from '@services/analytics.service';
import { GymService } from '@services/gym.service';
import { LoadingService } from '@services';
import { Brand, BrandService } from '@services/brand.service';

@Component({
  selector: 'app-gym-detail-plan',
  templateUrl: './gym-detail-plan.component.html',
  styleUrls: ['./gym-detail-plan.component.scss'],
})
export class GymDetailPlanComponent {
  @Input() gymUnitGroupPlan: GymUnitGroupPlan;
  @Input() plansExpanded: boolean;
  @Input() isLandingPageKids = false;

  @Output() plansExpandedChange = new EventEmitter<boolean>();

  private readonly CD_BT_KIDS_LEBLON: number = 63;
  private readonly CD_SP_IGUATEMI: number = 34;
  private readonly FITNESS_ESTADUAL: number[] = [172, 173, 476, 177];

  brand: Brand;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly gymService: GymService,
    private readonly modalService: ModalService,
    private readonly loadingService: LoadingService,
    readonly brandService: BrandService
  ) {
    this.brand = brandService.getBrand();
  }

  planExpandToggle() {
    this.plansExpandedChange.emit(!this.plansExpanded);
    // This forces a screen update that fix the swiper arrows
    window.scrollBy(0, 1);
  }

  showPlanDetail() {
    this.analyticsService.trackEvent(
      `Mais Informações - Plano ${
        this.gymUnitGroupPlan.portalName || this.gymUnitGroupPlan.plan.shortName
      }`,
      'Vendas',
      `Detalhes Academia - ${this.gymUnitGroupPlan.gymUnit.portalName}`
    );

    if (this.FITNESS_ESTADUAL.includes(this.gymUnitGroupPlan.plan.id)) {
      this.gymService.getGymNamesWithState().subscribe((gymWithState) => {
        this.gymUnitGroupPlan.plan.gymRegion = gymWithState
          .filter(
            (g) =>
              g.address.city.state.initials ===
              this.gymUnitGroupPlan.gymUnit.address.city.state.initials
          )
          .filter(
            (g) =>
              g.id !== this.gymUnitGroupPlan.gymUnit.id &&
              g.id !== this.CD_SP_IGUATEMI &&
              g.id !== this.CD_BT_KIDS_LEBLON
          );

        this.openModal();
      });
    } else {
      this.openModal();
    }
  }

  openModal() {
    this.loadingService.startLoading();
    this.gymService
      .getListAvailableGymUnitByPlan(
        this.gymUnitGroupPlan.gymUnit.id,
        this.gymUnitGroupPlan.plan.id
      )
      .subscribe((res) => {
        this.loadingService.stopLoading();
        this.modalService.show({
          title: `Plano ${
            this.gymUnitGroupPlan.portalName ||
            this.gymUnitGroupPlan.plan.shortName ||
            this.gymUnitGroupPlan.plan.longName
          }`,
          type: ModalType.gymPlan,
          message: this.gymUnitGroupPlan.longDescription,
          data: {
            combinatedMessage: res.message,
            plan: this.gymUnitGroupPlan.plan,
            gym: this.gymUnitGroupPlan.gymUnit,
            availableActivities: this.gymUnitGroupPlan.availableActivities,
            gymsAvailableOnPlan: res.gymUnitListIncludedByPlan,
          },
        } as ModalGymPlanObject);
      });
  }
}
