import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GymUnitPeriodicity } from '@models';

@Injectable()
export class GymUnitPeriodicityService {
  constructor(private readonly apollo: Apollo) {}

  getSuccessorPlanBySGAPlan(
    sgaPlanName: string,
    periodicityName: string,
    gymUnitId: number
  ): Observable<GymUnitPeriodicity> {
    return this.apollo
      .query({
        query: AppGraphql.queryGetSuccessorPlanBySGAPlan,
        variables: { sgaPlanName, periodicityName, gymUnitId },
      })
      .pipe(map(JSONUtil.turnApolloMutable('getSuccessorPlanBySGAPlan')));
  }
}
