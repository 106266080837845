import { Component, OnInit } from '@angular/core';
import { GymBenefitModel, ModalBenefitsObject, ModalType } from '@models';
import { ModalService } from '@services/modal.service';
import { ModalCommonComponent } from '@sharedcomponents/modal/modal-common.component';

@Component({
  selector: 'app-modal-gym-benefits',
  templateUrl: './modal-gym-benefits.component.html',
  styleUrls: ['./modal-gym-benefits.component.scss'],
})
export class ModalGymBenefitsComponent
  extends ModalCommonComponent
  implements OnInit {
  public benefits: GymBenefitModel[] = [];
  public modalObj: ModalBenefitsObject;

  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.gymBenefits;
  }

  init() {
    this.benefits = this.modalObj.benefits;
  }

  hidden() {
    this.benefits = [];
    this.modalObj = null;
  }
}
