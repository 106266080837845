import { Component, OnInit, Input } from '@angular/core';
import { Gym, ModalGymSelect, ModalGymSelectObject, ModalType } from '@models';
import { AnalyticsService, ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-gym-select',
  templateUrl: './modal-gym-select.component.html',
  styleUrls: ['./modal-gym-select.component.scss'],
})
export class ModalGymSelectComponent
  extends ModalCommonComponent
  implements OnInit {
  @Input() analyticsCategory: string;
  @Input() analyticsTag: string;

  gyms: ModalGymSelect;
  gymSelected: number;
  search: string;
  modalObj: ModalGymSelectObject;

  constructor(
    private readonly analyticsService: AnalyticsService,
    protected modalService: ModalService
  ) {
    super();
    this.type = ModalType.gymSelect;
  }

  init() {
    this.gyms = this.modalObj.gyms || [];
    this.gymSelected = this.modalObj.selected;
    this.search = '';
  }

  select(gym: Gym) {
    this.modalObj.data = gym;
    this.analyticsEvent();
    super.confirm();
  }

  analyticsEvent() {
    if (this.modalObj.data) {
      this.analyticsService.trackEvent(
        `Academia - ${this.modalObj.data.portalName}`,
        this.analyticsCategory,
        this.analyticsTag
      );
    }
  }
}
