import { Component, OnInit } from '@angular/core';
import { ModalType } from '@models';
import { ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-credit-card-info',
  templateUrl: './modal-credit-card-info.component.html',
  styleUrls: ['./modal-credit-card-info.component.scss'],
})
export class ModalCreditCardInfoComponent
  extends ModalCommonComponent
  implements OnInit {
  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.creditCardInfo;
  }
}
