import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appLazyCreate]',
})
export class LazyCreateDirective {
  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly renderer: Renderer2
  ) {}

  @Input() set appLazyCreate(showOrCreate: boolean) {
    if (this.isCreated()) {
      this.toogleClass('d-none', showOrCreate);
    } else {
      if (showOrCreate) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }

  isCreated(): boolean {
    return this.viewContainer.length > 0;
  }

  toogleClass(klass: string, show: boolean) {
    const comp = this.templateRef.elementRef.nativeElement.previousSibling;
    if (show) {
      this.renderer.removeClass(comp, klass);
    } else {
      this.renderer.addClass(comp, klass);
    }
  }
}
