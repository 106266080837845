import {
  Component,
  AfterViewInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { HeaderService } from '@services';
import { BasicHeaderConfig } from '@models/configs';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from '@services/brand.service';

export enum NewRecoveryAccountStages {
  RecoveryEmailByCpfOrRegistration = 0,
  FoundAndShowEmail,
  CreateNewAccount,
  UpdateExistingAccount,
  UpdateOrCreateSuccess,
}

@Component({
  templateUrl: './new-recovery-account.component.html',
})
export class NewRecoveryAccountComponent implements AfterViewInit {
  @ViewChild('header') headerComponent: TemplateRef<any>;

  config: BasicHeaderConfig;
  user: any;

  currentStage = NewRecoveryAccountStages.RecoveryEmailByCpfOrRegistration;
  newRecoveryAccountStages = NewRecoveryAccountStages;

  queryParams: any = null;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly headerService: HeaderService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.config = this.activatedRoute.snapshot.data.config.config;
    this.queryParams = this.activatedRoute.snapshot.queryParams;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
