export * from './modal-agenda/modal-agenda.component';
export * from './modal-alert/modal-alert.component';
export * from './modal-confirm/modal-confirm.component';
export * from './modal-error/modal-error.component';
export * from './modal-gym-benefit/modal-gym-benefit.component';
export * from './modal-kids-details/modal-kids-details.component';
export * from './modal-kids-schedule/modal-kids-schedule.component';
export * from './modal-presale-details/modal-presale-details.component';
export * from './modal-price-range/modal-price-range.component';
export * from './modal-schedule/modal-schedule.component';
export * from './modal-gym-plan/modal-gym-plan.module';
export * from './modal-gym-select/modal-gym-select.module';
export * from './modal-gym-select-by-city/modal-gym-select-by-city.module';
export * from './modal-gym-age/modal-gym-age.module';
export * from './modal-gym-activity/modal-gym-activity.module';
export * from './modal-btpass-pass-info/modal-btpass-pass-info.module';
export * from './modal-gym-benefit/modal-gym-benefit.module';
export * from './modal-gym-benefit/modal-gym-benefit.module';
export * from './modal-single-select/modal-single-select.module';

import { ModalAgendaComponent } from './modal-agenda/modal-agenda.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalCreditCardInfoComponent } from './modal-credit-card-info/modal-credit-card-info.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalKidsDetailsComponent } from './modal-kids-details/modal-kids-details.component';
import { ModalKidsScheduleComponent } from './modal-kids-schedule/modal-kids-schedule.component';
import { ModalPresaleDetailsComponent } from './modal-presale-details/modal-presale-details.component';
import { ModalPriceRangeComponent } from './modal-price-range/modal-price-range.component';
import { ModalRegistrationLockingComponent } from './modal-registration-locking/modal-registration-locking.component';
import { ModalScheduleComponent } from './modal-schedule/modal-schedule.component';
import { ModalTabSelectComponent } from './modal-tab-select/modal-tab-select.component';
import { ModalTeacherSelectComponent } from './modal-teacher-select/modal-teacher-select.component';

export * from './modal-agenda/modal-agenda.component';
export * from './modal-alert/modal-alert.component';
export * from './modal-btpass-pass-info/modal-btpass-pass-info.component';
export * from './modal-confirm/modal-confirm.component';
export * from './modal-error/modal-error.component';
export * from './modal-gym-plan/modal-gym-plan.component';
export * from './modal-kids-details/modal-kids-details.component';
export * from './modal-kids-schedule/modal-kids-schedule.component';
export * from './modal-presale-details/modal-presale-details.component';
export * from './modal-price-range/modal-price-range.component';
export * from './modal-schedule/modal-schedule.component';

export const modalComponents = [
  ModalAgendaComponent,
  ModalAlertComponent,
  ModalConfirmComponent,
  ModalCreditCardInfoComponent,
  ModalErrorComponent,
  ModalKidsDetailsComponent,
  ModalKidsScheduleComponent,
  ModalPresaleDetailsComponent,
  ModalPriceRangeComponent,
  ModalRegistrationLockingComponent,
  ModalScheduleComponent,
  ModalTabSelectComponent,
  ModalTeacherSelectComponent,
];
