import { Component, OnInit } from '@angular/core';
import { GymBenefit, ModalGymBenefitsObject, ModalType } from '@models';
import { AnalyticsService, ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-gym-benefit',
  templateUrl: './modal-gym-benefit.component.html',
  styleUrls: ['./modal-gym-benefit.component.scss'],
})
export class ModalGymBenefitComponent
  extends ModalCommonComponent
  implements OnInit {
  benefits: GymBenefit[];
  modalObj: ModalGymBenefitsObject;

  constructor(
    private readonly analyticsService: AnalyticsService,
    protected modalService: ModalService
  ) {
    super();
    this.type = ModalType.gymBenefit;
  }

  init() {
    this.benefits = this.modalObj.benefits || [];
  }

  confirm() {
    this.analyticsEvent();

    this.modalObj.data = this.benefits.filter((benefit) => benefit.selected);
    super.confirm();
  }

  analyticsEvent() {
    // Filtra os serviços selecionados e concatena seus nomes numa string, separando cada nome por ', '
    const st = this.benefits
      .filter((b) => b.selected)
      .map((benefit) => `${benefit.name}`)
      .join(', ');

    if (st) {
      this.analyticsService.trackEvent(
        `Serviços (${st})`,
        'Academias',
        `Filtros`
      );
    }
  }
}
