import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-social-login-buttons',
  templateUrl: './social-login-buttons.component.html',
  styleUrls: ['./social-login-buttons.component.scss'],
})
export class SocialLoginButtonsComponent {
  @Output() loginClicked = new EventEmitter<string>();

  login(button: string) {
    this.loginClicked.emit(button);
  }
}
