import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { SimpleLink } from '@models';
import {
  HeaderDefaultLinksResponse,
  HeaderLinksResponse,
} from '@models/responses/header-links-response.model';
import {
  BreakpointsService,
  screenSizeMap,
} from '@services/breakpoints.service';
import { HeaderService } from '@services/header.service';
import { PortalConfigService } from '@services/portal-config.service';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('headerContainer', { static: true }) headerContainer: ElementRef;
  @ViewChild('headerContent', { static: true, read: ViewContainerRef })
  headerContent: ViewContainerRef;

  private subs: Subscription;
  private templateListener: Subscription;

  barHeightArray = [64, 64, 80, 80, 80];
  barHeightClose = 80;
  headerHeight = 120;
  opacity = 1;
  width = 0;
  links: SimpleLink[];
  linksNavOpen: HeaderLinksResponse;
  linksLogged: SimpleLink[];
  loginListener: Subscription;

  constructor(
    private readonly headerService: HeaderService,
    private readonly breakpointsService: BreakpointsService,
    private readonly portalConfigService: PortalConfigService
  ) {}

  ngOnInit() {
    this.getHeaderMenu();

    this.templateListener = this.headerService.template.subscribe((data) => {
      this.headerContent.clear();
      if (data) {
        this.headerContent.createEmbeddedView(data);
        setTimeout(
          () =>
            (this.headerHeight =
              this.headerContainer.nativeElement.clientHeight),
          0
        );
      }
    });
    this.subs = this.breakpointsService.emitter.subscribe((size) => {
      this.barHeightClose = screenSizeMap(size, this.barHeightArray);
      this.headerHeight = this.headerContainer.nativeElement.clientHeight;
    });
  }

  /**
   * Returns links to be render in header.
   * Request to `header-menu` in portal configuration and if doesn't have the correct response use local `header-links.json` file.
   *
   * @todo To the day of this comment, only **Formula** has the correct response in `header-menu`.
   * Evaluate if would be interesting implement this solution to **BT** as well.
   */
  getHeaderMenu() {
    this.portalConfigService
      .getPortalConfiguration<HeaderDefaultLinksResponse>('header-menu')
      .pipe(
        mergeMap((response) =>
          response.nav ? of(response) : this.headerService.getHeaderLinks()
        ),
        catchError((_) => {
          return this.headerService.getHeaderLinks();
        })
      )
      .subscribe((response) => {
        this.links = response.nav;
        this.linksNavOpen = response.navOpen;
        this.linksLogged = response.logged;
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.templateListener.unsubscribe();
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    this.opacity = Math.max(
      1 - window.pageYOffset / (this.headerHeight - this.barHeightClose),
      0
    );
  }
}
