import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { HomeResponse } from '@models';
import { HeaderService } from '@services/header.service';
import { BannerService } from '@services';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  json: HomeResponse;
  banners: any;
  constructor(
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
    private readonly bannerService: BannerService
  ) {}

  ngOnInit() {
    this.json = this.route.snapshot.data.config.home;
    this.bannerService.listCurrentPortalHomeBannerHeaders().subscribe((res) => {
      this.banners = res;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
