import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-concept-fr',
  templateUrl: './concept-fr.component.html',
  styleUrls: ['./concept-fr.component.scss'],
})
export class ConceptFrComponent {
  @Input() json: any;
}
