import { Component } from '@angular/core';

@Component({
  selector: 'app-concept-objectives',
  templateUrl: './concept-objectives.component.html',
  styleUrls: [
    '../concept.component.scss',
    './concept-objectives.component.scss',
  ],
})
export class ConceptObjectivesComponent {}
