<div class="landing-header">
  <div class="btfit-logo">
    <img src="assets/btfit/btfit_logo.svg" alt="Logotipo BTFIT" />
  </div>
  <div class="d-flex justify-content-center">
    <div class="content-container">
      <div class="oswald-bold-100 header-content">
        TREINE ONDE E QUANDO QUISER
      </div>
      <div class="oswald-extralight-30 header-content">
        O BTFIT é um aplicativo da Bodytech Company que oferece aulas coletivas
        e treinos com ou sem equipamentos, além de desafios de 7, 15, 20 e 30
        dias. Você como cliente tem acesso à versão premium.
      </div>
      <div class="oswald-bold-30 header-content">
        SIGA O PASSO A PASSO ABAIXO PARA TER O BTFIT
      </div>
    </div>
    <div class="fake-image">
      <img
        class="header-image"
        src="assets/btfit/BTDIGITAL_MobileHeader.webp"
        alt="Tela BTFIT"
      />
    </div>
  </div>
  <div class="curve"></div>
  <div class="arrow"></div>
</div>

<div class="step-one">
  <div class="step-one-content">
    <div class="oswald-bold-60 step-title">PASSO 1</div>
    <div class="oswald-extralight-40">
      Caso ainda não tenha, crie seu login {{ isBT ? "Bodytech" : "Fórmula" }}.
      Clique em “Novo cliente / Não consegue logar?” e siga os passos para obter
      seu acesso.
    </div>
  </div>
  <img
    class="btfit-screen"
    [src]="
      isBT
        ? 'assets/btfit/BTDIGITAL_Passo1_BT.webp'
        : 'assets/btfit/BTDIGITAL_Passo1_FR.webp'
    "
    alt="Tela BTFIT"
  />
</div>
<div class="step-three">
  <div class="step-three-content">
    <div class="oswald-bold-60 step-title">PASSO 2</div>
    <div class="oswald-extralight-40">
      Faça download do BTFIT e use seu login
      {{ isBT ? "Bodytech" : "Fórmula" }}.
    </div>
  </div>
  <img
    class="double-btfit-screen"
    src="assets/btfit/BTDIGITAL_Passo3e4.webp"
    alt="Tela BTFIT"
  />
</div>

<div class="final-step">
  <img
    class="btfit-screen"
    src="assets/btfit/BTDIGITAL_TelaBTFIT.webp"
    alt="Tela BTFIT"
  />
  <div class="final-step-content">
    <div class="oswald-bold-60">
      PRONTO. AGORA VOCÊ JÁ É UM USUÁRIO PREMIUM DO BTFIT!
    </div>
    <div class="apps-buttons">
      <a href="https://apps.apple.com/br/app/id1034954940?mt=8" target="_blank">
        <img
          class="button"
          src="assets/btfit/disponivel-apple.webp"
          alt="Botão App Store"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.btfit"
        target="_blank"
      >
        <img
          class="button"
          src="assets/btfit/disponivel-google.webp"
          alt="Botão Play Store "
        />
      </a>
    </div>
  </div>
  <div class="apps-buttons-tablet-larger">
    <a href="https://apps.apple.com/br/app/id1034954940?mt=8" target="_blank">
      <img
        class="button"
        src="assets/btfit/disponivel-apple.webp"
        alt="Botão App Store"
      />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.btfit"
      target="_blank"
    >
      <img
        class="button"
        src="assets/btfit/disponivel-google.webp"
        alt="Botão Play Store "
      />
    </a>
  </div>
</div>

<div class="opensans-regular-30 promotion-info">
  <ng-container *ngIf="isBT; else isFormula">
    Promoção válida até 31/03/2021 para clientes maiores de 12 anos de todos os
    planos Bodytech, exceto clientes do plano BT Pass. Consulte o regulamento da
    promoção
  </ng-container>
  <ng-template #isFormula>
    Promoção válida até 31/03/2021 para clientes maiores de 12 anos de todos os
    planos Fórmula. Consulte o regulamento da promoção
  </ng-template>
  <a
    class="opensans-regular-30"
    [href]="termsLink"
    target="_blank"
    rel="noopener"
    >aqui</a
  >.
</div>

<div [style.background-color]="isBT ? '#42B9C0' : '#e13336'" class="footer">
  <img
    class="footer-image"
    [src]="
      isBT
        ? 'assets/btfit/Bodytech_LogoWhite.svg'
        : 'assets/btfit/Formula_LogoWhite.png'
    "
    [alt]="isBT ? 'Logotipo Bodytech' : 'Logotipo Fórmula'"
  />
</div>
