import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SelectItem } from '@models';
import { GymService } from '@services';
import { ResolveResult } from './type-resolve-bundle';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketGymResolveGuard implements Resolve<SelectItem[]> {
  constructor(private readonly gymService: GymService) {}

  resolve(): ResolveResult<SelectItem[]> {
    return this.gymService
      .getGymNames()
      .pipe(
        map((json) => json.map((gym) => ({ id: gym.id, text: gym.portalName })))
      );
  }
}
