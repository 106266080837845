import { EventEmitter, Injectable } from '@angular/core';
import { ModalObject, ModalType } from '@models';

@Injectable()
export class ModalService {
  modalEmitter = new EventEmitter<boolean | ModalObject>();

  show(modal: ModalObject) {
    this.modalEmitter.emit(modal);
  }

  hide() {
    this.modalEmitter.emit(false);
  }

  init() {
    this.modalEmitter.next({
      type: ModalType._close,
      title: '',
      message: '',
    });
  }
}
