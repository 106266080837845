import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error',
  template: `
    <div class="d-flex align-items-center" [ngClass]="'flex-' + display">
      <img
        [ngClass]="display === 'row' ? 'mr-3' : 'mb-3'"
        [src]="icon"
        alt="Ícone de erro ao carregar conteúdo"
      />
      <p class="m-0" [innerHTML]="errorMessage | safeHtml"></p>
    </div>
  `,
  styles: ['img { width: 55px; }'],
})
export class ErrorComponent {
  @Input() icon = '/assets/icons/falha_geral.svg';
  @Input() display: 'row' | 'column' = 'row';
  @Input() errorMessage = `Esta seção encontra-se indisponível no momento.<br>
    Verifique sua conexão ou tente novamente mais tarde.`;
}
