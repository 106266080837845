import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlogMediaResponse, BlogPostResponse } from '@models';
import { blog, BlogEndpoints } from '@utils/app-endpoints';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BrandService, Brand } from './brand.service';

@Injectable()
export class BlogService {
  private readonly VIEW_IN_SITE_CATEGORY = '54';
  private readonly FORMULA_CATEGORY = '64';

  brand: Brand;

  constructor(
    private readonly brandService: BrandService,
    private readonly http: HttpClient
  ) {
    this.brand = this.brandService.getBrand();
  }

  getMedia(id: number | string) {
    return this.http
      .get<BlogMediaResponse>(blog(BlogEndpoints.Media, id), {
        observe: 'response',
      })
      .pipe(map((res) => res.body));
  }

  getPostPage(
    page?: number | string,
    perPage?: number | string,
    sticky?: boolean | string
  ): Observable<HttpResponse<BlogPostResponse[]>> {
    let param = new HttpParams();
    if (page) {
      param = param.set('page', page.toString());
    }
    if (perPage) {
      param = param.set('per_page', perPage.toString());
    }
    if (sticky) {
      param = param.set('categories', this.VIEW_IN_SITE_CATEGORY);
    }
    if (this.brand === 'fr') {
      param = param.set('categories_exclude', this.FORMULA_CATEGORY);
    }

    return this.http.get<BlogPostResponse[]>(blog(BlogEndpoints.Posts), {
      params: param,
      observe: 'response',
    });
  }

  getPostById(id: number | string): Observable<BlogPostResponse> {
    const url = blog(BlogEndpoints.Post, id);
    return this.http.get<BlogPostResponse>(url);
  }
}
