import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalBtpassPassInfoComponent } from './modal-btpass-pass-info.component';
import { ProductQuantityChoicerModule } from '@shared/components/product-quantity-choicer/product-quantity-choicer.module';
import { CapitalizeModule } from '@pipes';

@NgModule({
  declarations: [ModalBtpassPassInfoComponent],
  imports: [
    CapitalizeModule,
    CommonModule,
    ModalModule,
    ProductQuantityChoicerModule,
  ],
  exports: [ModalBtpassPassInfoComponent],
})
export class ModalBtpassPassInfoModule {}
