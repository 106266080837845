<form class="form" #formPersonal="ngForm">
  <div class="text-center" *ngIf="typeOfProduct !== typesOfProducts.Income">
    <a id="already-client" class="title text-primary link" routerLink="/cliente/login" [queryParams]="redirectToMe">
      Já sou um cliente {{ brandService.fullBrandName }}
    </a>
  </div>

  <div class="title title-section section">Dados principais</div>

  <div class="row">
    <div class="col-12 col-md">
      <label for="personal-name" class="label-input" for="name">Nome</label>
      <input
        id="personal-name"
        name="name"
        #userName="ngModel"
        class="form-control"
        required
        autocomplete="name"
        pattern="^[a-zA-Z\u00C0-\u017F-]{3,}(?: [a-zA-Z\u00C0-\u017F-]+){1,9}$"
        [(ngModel)]="formPersonalData.name">
      <app-error-message [control]="userName"
        [messages]="{ pattern: 'Informe nome e sobrenome' }"></app-error-message>

      <label for="personal-cpf" class="label-input">CPF</label>
      <input
        id="personal-cpf"
        name="cpf"
        #userCPF="ngModel"
        class="form-control"
        required
        [imask]="docMask"
        appCpfValidator
        autocomplete="document-main"
        [(ngModel)]="formPersonalData.cpf">
      <app-error-message [control]="userCPF"></app-error-message>

      <label for="personal-birthdate" class="label-input">Data de nascimento</label>
      <input
        id="personal-birthdate"
        name="birthdate"
        #userBday="ngModel"
        class="form-control"
        required
        [imask]="dateMask"
        autocomplete="bday"
        [(ngModel)]="formPersonalData.birthdate">
      <app-error-message [control]="userBday"></app-error-message>

      <label for="personal-gender" class="label-input">Sexo</label>
      <app-select
        id="personal-gender"
        #gender="ngModel"
        name="gender"
        class="form-custom"
        [(ngModel)]="formPersonalData.gender"
        [list]="genderOptions"
        ngDefaultControl
        required></app-select>
      <app-error-message [control]="gender"></app-error-message>

    </div>

    <div class="col-12 col-md col-right">
      <label for="personal-phone" class="label-input">Celular</label>
      <input
        id="personal-phone"
        name="phone"
        #userPhone="ngModel"
        class="form-control"
        required
        [imask]="phoneMask"
        autocomplete="tel tel-national home"
        [(ngModel)]="formPersonalData.phone">
      <app-error-message [control]="userPhone"></app-error-message>

      <label for="personal-email" class="label-input">E-mail</label>
      <input
        id="personal-email"
        name="email"
        #userEmail="ngModel"
        class="form-control"
        required
        autocomplete="email"
        [pattern]="emailRegexp"
        (keyup)="emailCheck.control.updateValueAndValidity()"
        [(ngModel)]="formPersonalData.email">
      <app-error-message [control]="userEmail"
        [messages]="{ pattern: 'E-mail inválido' }"></app-error-message>

      <label for="personal-email-check" class="label-input">Confirmar e-mail</label>
      <input
        type="email"
        id="personal-email-check"
        name="emailCheck"
        #emailCheck="ngModel"
        class="form-control"
        required [appMatch]="formPersonalData.email"
        autocomplete="off"
        [pattern]="emailRegexp"
        [(ngModel)]="formPersonalData.emailCheck"
        onpaste="return false;"
        onCopy="return false"
        >
      <app-error-message [control]="emailCheck"
        [messages]="{ match: 'Confirmação de e-mail falhou' }"></app-error-message>

    </div>
  </div>
  <div class="section checkbox-section">
    <app-checkbox
      name="accept-mail"
      [(check)]="formPersonalData.newsletter"
      message="Aceito receber e-mail/SMS/WhatsApp com informações importantes e ofertas especiais {{brandService.fullBrandName}}."
    ></app-checkbox>
  </div>

  <div class="link-section text-center" *ngIf="adhesionUrl">
    <a id="know-adhesion-term" appClickable class="title text-primary link" [href]="adhesionUrl" target="_blank" rel="noopener">
      Conheça o termo de adesão
    </a>
  </div>
</form>
