import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CustomerCreditCard,
  LegacyCustomerCreditCard,
  Gym,
  UpdateCreditCard,
  UpdateCreditCardWithRenew,
} from '@models';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CustomerService {
  constructor(private readonly http: HttpClient) {}

  getCustomerCreditCard(gymUnitId: number) {
    return this.http
      .get(back(BackEndpoints.GetCustomerCreditCard, gymUnitId))
      .pipe(
        map((data: LegacyCustomerCreditCard) => {
          const creditCardNumber = data.NumeroCartaoMascarado.substr(
            data.NumeroCartaoMascarado.length - 4,
            4
          );
          return {
            creditCardDueDate: data.DataValidade,
            creditCardFlag: data.NomeBandeira,
            creditCardName: data.NomeCliente,
            creditCardNumber,
            nextChargeDate: '',
            nextChargePrice: '',
          } as CustomerCreditCard;
        })
      );
  }

  updateCustomerCreditCard(
    creditCardData: UpdateCreditCard,
    gymUnitId?: number
  ) {
    const backendEndpoint = gymUnitId
      ? back(BackEndpoints.UpdateCustomerCreditCard, gymUnitId)
      : back(BackEndpoints.UpdateCustomerCreditCard);
    return this.http.post(backendEndpoint, creditCardData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  updateCustomerCreditCardWithRenew(
    creditCardData: UpdateCreditCardWithRenew,
    gymUnitId: number
  ) {
    return this.http
      .post(
        back(BackEndpoints.UpdateCustomerCreditCardWithRenew, gymUnitId),
        creditCardData
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  listCustomerOpenGymUnit() {
    return this.http.get(back(BackEndpoints.ListCustomerOpenGymUnit)).pipe(
      map((data: any) => {
        const gyms = data.gymUnitList;
        gyms.forEach((gym: Gym) => {
          if (
            gym.address &&
            gym.address.city &&
            gym.address.city.state &&
            gym.address.city.state.initials
          ) {
            gym.portalNameWithState = `${gym.address.city.state.initials} - ${gym.portalName}`;
          } else {
            gym.portalNameWithState = gym.portalName;
          }
        });
        gyms.sort(
          (
            a: { portalNameWithState: number },
            b: { portalNameWithState: number }
          ) => {
            return a.portalNameWithState < b.portalNameWithState ? -1 : 1;
          }
        );
        return { gyms, plan: data.customerPlan.id };
      })
    );
  }
}
