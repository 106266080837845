import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParameterCodec,
  HttpParams,
  HttpRequest,
  HttpUrlEncodingCodec,
} from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, ObservableInput, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AlertMessage, MessageMap } from '@models';
import { AlertMessageService } from '@services/alert-message.service';
import { AuthService } from '@services/auth.service';
import { LoadingService } from '@services/loading.service';
import { AppConstants } from '@utils/app-constants';
import {
  back,
  BackEndpoints,
  blog,
  BlogEndpoints,
  evoBack,
  EvoBackEndpoints,
  isDocuSignMicroservices,
  isMicroservices,
  isToOldCanais,
  sur,
  SurEndpoints,
} from '@utils/app-endpoints';
import { JSONUtil } from '@utils/json-util';

class PlainTextEncoder implements HttpParameterCodec {
  encodeKey = (key: string): string => key;
  encodeValue = (value: string): string => value;
  decodeKey = (key: string): string => key;
  decodeValue = (value: string): string => value;
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  static readonly ignoreStatusWhenRetry = [
    AppConstants.HTTP_OK,
    AppConstants.HTTP_BAD_REQUEST,
    AppConstants.HTTP_SERVER_ERROR,
  ];
  static readonly ignoreReasonsWhenRetry = [
    AppConstants.LEGACY_ERROR.INVALID_LOGIN_OR_PASSWORD,
    AppConstants.LEGACY_ERROR.EXISTING_USER,
  ];
  static readonly maxTimeout = 30000;
  static readonly ignoreUrl = [
    back(BackEndpoints.AppToken),
    back(BackEndpoints.CognitoToken),
    back(BackEndpoints.EvoCheckMigratingGym),
    blog(BlogEndpoints.ApiUrl),
    evoBack(EvoBackEndpoints.Root),
    sur(SurEndpoints.AppToken),
  ];
  static readonly interceptUrl = [
    back(BackEndpoints.Root),
    sur(SurEndpoints.Root),
    environment.apollo.microservicesBaseUrl,
    environment.docmod.microservicesBaseUrl,
  ];

  paramNotEncode = [
    'tokenreset',
    'codigoacesso',
    'tokenConfirmacao',
    'confirmacao',
  ];

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly router: Router
  ) {}

  intercept(
    originalRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!window.navigator.onLine) {
      this.loadingService.stopLoading();
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET)
      );

      return throwError(MessageMap.SEM_CONEXAO_INTERNET);
    }

    const url = originalRequest.url;
    const legacyOpenUrl =
      url.startsWith(back(BackEndpoints.Root) + 'legacy') &&
      !this.authService.getUser();
    const babyKidsUrl =
      url.startsWith(back(BackEndpoints.Root) + 'babykids') &&
      !this.authService.getUser();
    const getAuthDocuSign = originalRequest.body?.operationName === 'getAuth';

    if (
      !AuthInterceptor.interceptUrl.some((value) => url.startsWith(value)) ||
      AuthInterceptor.ignoreUrl.some((value) => url.startsWith(value)) ||
      legacyOpenUrl ||
      babyKidsUrl ||
      getAuthDocuSign
    ) {
      return this.ignoreRequest(originalRequest, next);
    }

    return this.requestWithToken(next, originalRequest);
  }

  requestWithToken(next: HttpHandler, request: HttpRequest<any>) {
    return this.authService
      .getAppToken(this.getTokenKey(request.url))
      .pipe(
        mergeMap((token: string) => this.requestHandle(next, request, token))
      );
  }

  requestHandle(next: HttpHandler, request: HttpRequest<any>, token: string) {
    const authRequest = this.cloneRequestWithEncodedParams(request, token);

    return next.handle(authRequest).pipe(
      // Catch Error will invalidate token and try again, if necessary
      catchError((err) => this.handleError(err, next, request)),
      retryWhen((attempt) => attempt.pipe(mergeMap(this.shouldRetry))),
      catchError((err) => this.handleGenericError(err))
    );
  }

  cloneRequestWithEncodedParams(request: HttpRequest<any>, token: string) {
    const oldparams = request.params;
    const oldparamsEncoder = new HttpUrlEncodingCodec();
    const plainTextEncoder = new PlainTextEncoder();
    let newparams = new HttpParams({ encoder: plainTextEncoder });

    oldparams.keys().forEach((key) => {
      const eKey = oldparamsEncoder.encodeKey(key);
      oldparams
        .getAll(key)
        .map((value) => {
          if (this.paramNotEncode.includes(key)) {
            return encodeURIComponent(value);
          }

          return oldparamsEncoder.encodeValue(value);
        })
        .forEach((eValue) => {
          newparams = newparams.append(eKey, eValue);
        });
    });

    const appUserToken = this.authService.getAppUserToken();
    const cognitoToken = this.authService.getCognitoToken();
    const docuSignToken = this.authService.getDocSignToken();

    const requestUpdateParams: any = {
      params: newparams,
    };

    if (isDocuSignMicroservices(request.url)) {
      requestUpdateParams.setHeaders = {
        authorization: `Bearer ${docuSignToken}`,
      };
    } else if (isMicroservices(request.url)) {
      requestUpdateParams.setHeaders = { Authorization: cognitoToken };
    } else if (isToOldCanais(request.url) && appUserToken) {
      requestUpdateParams.setHeaders = { 'X-Authorization': appUserToken };
    } else {
      requestUpdateParams.setHeaders = { 'X-Authorization': token };
    }

    return request.clone(requestUpdateParams);
  }

  private getTokenKey(url: string) {
    if (isDocuSignMicroservices(url)) {
      return AppConstants.STOR_DOCUSIGN_TOKEN;
    } else if (isMicroservices(url)) {
      return AppConstants.STOR_COGNITO_TOKEN;
    } else if (isToOldCanais(url)) {
      return AppConstants.STOR_APP_USER_TOKEN;
    } else {
      return AppConstants.STOR_SUR_TOKEN;
    }
  }

  /** Check if it's service which consults if the gym is migrating. */
  isMigratingGymService(url: string): boolean {
    return url.includes(BackEndpoints.EvoCheckMigratingGym);
  }

  shouldRetry(error: any, currentRetry: number) {
    return currentRetry > 1 ||
      (error.status >= 400 && error.status < 600) ||
      AuthInterceptor.ignoreStatusWhenRetry.includes(error.status) ||
      AuthInterceptor.ignoreReasonsWhenRetry.some(
        (reason) => error.error.errorCode === reason
      )
      ? throwError(error)
      : timer(500);
  }

  preventMultiplesRetries(
    error: HttpErrorResponse,
    currentRetry: number
  ): Observable<any> {
    const shouldntRetryUrls = [BackEndpoints.EvoSendRequest];

    return currentRetry > 1 ||
      shouldntRetryUrls.some((value) => error.url.includes(value))
      ? throwError(error)
      : timer(500);
  }

  handleError(
    err: HttpErrorResponse,
    next: HttpHandler,
    request: HttpRequest<any>
  ): Observable<any> | ObservableInput<any> {
    if (isDevMode) {
      console.warn(`Caught error ${err.status} from ${request.url}`, err);
    }

    const url = request.url || '';

    if (url.startsWith(back(BackEndpoints.Root) + 'legacy')) {
      return this.handleErrorCanais(err);
    } else if (url.startsWith(back(BackEndpoints.Root))) {
      return this.handleErrorLogged(err);
    } else if (url.startsWith(sur(SurEndpoints.Root))) {
      return this.handleErrorSur(err, next, request);
    } else if (url.startsWith(environment.apollo.microservicesBaseUrl)) {
      return this.handleMicrosserviceError(err, next, request);
    } else if (url.startsWith(environment.docmod.microservicesBaseUrl)) {
      return this.handleDocuSignMicrosserviceError(err, next, request);
    }

    return throwError(err);
  }

  handleMicrosserviceError(
    err: HttpErrorResponse,
    next: HttpHandler,
    request: HttpRequest<any>
  ): Observable<any> | ObservableInput<any> {
    if (err.status === AppConstants.HTTP_UNAUTHORIZED) {
      this.authService.clearAppToken(AppConstants.STOR_COGNITO_TOKEN);
      return this.requestWithToken(next, request);
    }
  }

  handleDocuSignMicrosserviceError(
    err: HttpErrorResponse,
    next: HttpHandler,
    request: HttpRequest<any>
  ): Observable<any> | ObservableInput<any> {
    if (err.status === AppConstants.HTTP_UNAUTHORIZED) {
      this.authService.clearAppToken(AppConstants.STOR_DOCUSIGN_TOKEN);
      return this.requestWithToken(next, request);
    }
  }

  handleErrorCanais(
    err: HttpErrorResponse
  ): Observable<any> | ObservableInput<any> {
    const reason = err.error || 'Undefinied';

    if (err.status === AppConstants.HTTP_FORBIDDEN) {
      if (
        reason.errorCode === AppConstants.LEGACY_ERROR.INVALID_LOGIN_OR_PASSWORD
      ) {
        throwError({ message: reason });
      } else if (
        reason.errorCode === AppConstants.LEGACY_ERROR.INVALID_USER_TOKEN ||
        reason.message.startsWith('Token de Usuário')
      ) {
        this.alertMessageService.showToastr(
          AlertMessage.warning(MessageMap.SESSAO_EXPIRADA)
        );
        this.authService.logout(this.router.url);
        return [];
      }
    }

    return throwError(err);
  }

  handleErrorSur(
    err: HttpErrorResponse,
    next: HttpHandler,
    request: HttpRequest<any>
  ): Observable<any> | ObservableInput<any> {
    if (
      err.status === AppConstants.HTTP_UNAUTHORIZED &&
      JSONUtil.get(err, 'error.code') &&
      JSONUtil.get(err, 'error.code').startsWith(
        AppConstants.SUR_ERROR.INVALID_APP_TOKEN
      )
    ) {
      this.authService.clearAppToken(AppConstants.STOR_SUR_TOKEN);
      return this.requestWithToken(next, request);
    }

    return throwError(err);
  }

  handleErrorLogged(
    err: HttpErrorResponse
  ): Observable<any> | ObservableInput<any> {
    if (err.status === AppConstants.HTTP_UNAUTHORIZED) {
      this.alertMessageService.showToastr(
        AlertMessage.warning(MessageMap.SESSAO_EXPIRADA)
      );
      this.authService.logout(this.router.url);
      return [];
    }

    return throwError(err);
  }

  handleGenericError(err: HttpErrorResponse): ObservableInput<any> {
    this.loadingService.stopLoading();
    return throwError(err);
  }

  ignoreRequest(originalRequest: HttpRequest<any>, next: HttpHandler) {
    return next.handle(originalRequest).pipe(
      retryWhen((attempt) =>
        attempt.pipe(mergeMap(this.preventMultiplesRetries))
      ),
      catchError((err) => {
        console.log('Ignored request error', err);
        if (err.status === AppConstants.HTTP_UNKNOWN) {
          console.warn(`Is ${originalRequest.url} offline?`);
          throw [err];
        }
        if (err.status >= 400 && err.status < 500) {
          // Client errors
          throw err;
        }
        return [];
      })
    );
  }
}
