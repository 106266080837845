import { NgModule } from '@angular/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PopoverFadeDirective } from './popover-fade.directive';

@NgModule({
  imports: [PopoverModule.forRoot()],
  declarations: [PopoverFadeDirective],
  exports: [PopoverFadeDirective],
})
export class PopoverFadeModule {}
