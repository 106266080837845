import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MembershipModel,
  MembershipMoreInfoModalModel,
  MembershipOfferModel,
  ModalType,
} from '@models';
import { AnalyticsService } from '@services/analytics.service';
import { EvoMembershipService } from '@services/evo/evo-membership.service';
import { GymService } from '@services/gym.service';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { MembershipStatus } from '@utils/gym-util';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
})
export class MembershipComponent implements OnInit {
  @Input() hasSubscribeButton = true;
  @Input() membership: MembershipModel;
  @Input() hideMoreInfo?: boolean;

  @Output() offersIsContractChange = new EventEmitter<boolean>();
  /**
   * It's triggered when a click on show more button, primary use to
   * scroll to top section of memberships.
   *
   * @param delay - The value emitted represent the duration of accordion contraction animation.
   */
  @Output() clickShowMore = new EventEmitter<number>();

  get offersIsContract() {
    return this._offersIsContract;
  }

  @Input() set offersIsContract(newValue: boolean) {
    this._offersIsContract = newValue;

    this.offersIsContractChange.emit(this.offersIsContract);
  }

  private _offersIsContract = false;
  readonly membershipStatus = MembershipStatus;

  highlightOffer: MembershipOfferModel;
  /** This variable was create to mitigate mutation in `membership` object. */
  localMembership: MembershipModel;

  constructor(
    public readonly evoMembershipService: EvoMembershipService,
    private readonly analyticsService: AnalyticsService,
    private readonly gymService: GymService,
    private readonly loadingService: LoadingService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.localMembership = JSON.parse(JSON.stringify(this.membership));

    this.sortMembershipOffers();
    this.formatMembershipOffers();
    this.setHighlightOffer();
  }

  clickShowMoreButton(): void {
    this.offersIsContract = !this.offersIsContract;

    if (this.offersIsContract) {
      const accordionContractAnimationDuration = 400;
      this.clickShowMore.emit(accordionContractAnimationDuration);
    }
  }

  clickJoinButtonTrackAnalytics(offerCommitment: string): void {
    this.analyticsService.trackEvent(
      `Clique em "Matricule-se" - ${this.localMembership.title} - ${offerCommitment}`,
      'Vendas',
      `Formulário de vendas - ${this.evoMembershipService.gymName}`
    );
  }

  showMoreInfoModal(): void {
    this.analyticsService.trackEvent(
      `Mais Informações - Plano ${this.localMembership.title}`,
      'Vendas',
      `Detalhes Academia - ${this.evoMembershipService.gymName}`
    );

    /** @todo Remove when EVO migration finish. */
    if (this.evoMembershipService.legacy) {
      this.legacyShowMoreInfoModal();
      return;
    }

    this.modalService.show({
      title: `Plano ${this.localMembership.title}`,
      type: ModalType.gymPlan,
      message: this.localMembership.moreInfoModal.description,
      data: this.localMembership.moreInfoModal.data,
    } as MembershipMoreInfoModalModel);
  }

  /** @todo Remove when EVO migration finish. */
  private legacyShowMoreInfoModal(): void {
    this.loadingService.startLoading();
    this.gymService
      .getListAvailableGymUnitByPlan(
        this.evoMembershipService.legacyGymId,
        this.localMembership.legacy.id
      )
      .subscribe((res) => {
        this.loadingService.stopLoading();

        this.modalService.show({
          title: `Plano ${this.localMembership.title}`,
          type: ModalType.gymPlan,
          message: this.localMembership.moreInfoModal.description,
          data: {
            ...this.localMembership.moreInfoModal.data,
            gymsAvailableOnPlan: res.gymUnitListIncludedByPlan,
          },
        });
      });
  }

  /**
   * After sorting the highlight offer will always be the first one.
   * And set the Fitness membership to get the special offer label.
   */
  private setHighlightOffer(): void {
    this.highlightOffer = this.localMembership.offers[0];

    this.localMembership.offers.shift();

    if (this.localMembership.title !== 'fitness') {
      return;
    }

    this.highlightOffer.specialOffer =
      this.highlightOffer.status === this.membershipStatus.Available;
  }

  /**
   * This method changes the order of the offer array.
   * It was created to swap two offer position, so we can control manually the order
   * that the offers appears on the portal.
   */
  private swapOfferPosition(pos1: number, pos2: number): void {
    const value1 = this.localMembership.offers[pos1];
    const value2 = this.localMembership.offers[pos2];

    this.localMembership.offers[pos1] = value2;
    this.localMembership.offers[pos2] = value1;
  }

  /**
   * Sorting order by commitment:
   *
   * 1. Annual;
   * 1. Biannual;
   * 3. Semiannual;
   * 4. Quarterly;
   * 5. Monthly Subscription (credit card);
   * 6. Monthly;
   */
  private sortMembershipOffers(): void {
    this.localMembership.offers.sort((a, b) => b.months - a.months);

    /** Monthly offer must be after Credit Card offer. */
    const monthlyPos = this.localMembership.offers.length - 1;
    const dccPos = this.localMembership.offers.length - 2;
    this.swapOfferPosition(monthlyPos, dccPos);
  }

  private formatMembershipOffers(): void {
    this.localMembership.offers.forEach((offer) => {
      offer.prices.offer = Math.round(offer.prices.offer);
    });
  }
}
