import { of } from 'rxjs';
import { PortalConfigService } from '@services';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class PortalConfigResolveGuard implements Resolve<any> {
  /**
   * COMO USAR
   *
   * Na declaração da rota, inclua este resolve com a key que você preferir.
   * Ex.:
   * {
   *    path: ... ,
   *    component: ... ,
   *    resolve: {
   *      ... ,
   *      minhaConfigMuitoLegal: PortalConfigResolveGuard,
   *      ... ,
   *    },
   *    data: { ... }
   * }
   *
   * No parâmetro `data` da rota, inclua uma propriedade com o mesmo nome, que
   * deve ser um objeto onde as keys podem ser qualquer nome, mas os values
   * devem ser a "tag" que representa a `PortalConfig` que deverá ser buscada.
   * Ex.:
   * {
   *    ...
   *    data: {
   *      minhaConfigMuitoLegal: {
   *        foo: 'tag-1',
   *        bar: 'tag-2',
   *        baz: 'tag-2',
   *      }
   *    }
   * }
   *
   */

  constructor(private readonly portalConfigService: PortalConfigService) {}

  resolve(route: ActivatedRouteSnapshot): ResolveResult<any> {
    const config = Object.entries(route.routeConfig.resolve).find(
      (x) => x[1] === this.constructor
    );

    if (config && route.data[config[0]]) {
      return this.portalConfigService.getPortalConfigurations<any>(
        route.data[config[0]],
        route.params
      );
    } else {
      return of(undefined);
    }
  }
}
