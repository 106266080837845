import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Tab } from '@models';
import { HeaderService } from '@services';
import { BrandService } from '@services/brand.service';
import { TabControllerComponent } from '../shared/abstracts/tab-controller.component';

@Component({
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent
  extends TabControllerComponent
  implements OnInit, AfterViewInit {
  imageHeader: string;
  headerTitle: string;
  isFormula = false;

  public contactEmail: string;

  readonly tabs: Tab[] = [
    { id: 0, name: 'Novo chamado', url: '/contato/novo' },
    { id: 1, name: 'Andamento do chamado', url: '/contato/andamento' },
  ];

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly headerService: HeaderService,
    protected readonly sanitazer: DomSanitizer,
    protected readonly brandService: BrandService
  ) {
    super(route, headerService);
  }

  ngOnInit() {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
    this.isFormula = this.brandService.getBrand() === 'fr';
    this.contactEmail = this.isFormula
      ? 'contato@formulaacademia.com.br'
      : 'contato@bodytech.com.br';
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  openWhatsapp() {
    if (this.isFormula) {
      window.open('http://wa.me/5521978763147', '_blank');
    } else {
      window.open('http://wa.me/5521993410670', '_blank');
    }
  }
}
