import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from 'ngx-swiper-wrapper';
import { TemplateHightlightModule } from '@directives';
import { IncomeResolveGuard } from '@guards/income.guard';
import {
  EvoRedirectInterceptor,
  XAuthorizationInterceptor,
} from '@interceptors';
import { BTPassService, EvoGymService, EvoMembershipService } from '@services';
import { ErrorModule } from '@shared/components/error/error.module';
import { LoadingContentModule } from '@shared/components/loading-content/loading-content.module';
import { ModalGymSelectByCityModule } from '@shared/components/modal/modal-gym-select-by-city/modal-gym-select-by-city.module';
import { GetAppsModule } from '@sharedcomponents/get-apps/get-apps.module';
import { HeaderStaticImageModule } from '@sharedcomponents/header-static-image/header-static-image.module';
import { ModalBtpassPassInfoModule } from '@sharedcomponents/modal';
import { ModalGymsAppModule } from '@sharedcomponents/modal/modal-gyms-app/modal-gyms-app.module';
import { StateGymFilterModule } from '@sharedcomponents/state-gym-filter/state-gym-filter.module';
import { RegistrationModule } from 'app/registration/registration.module';
import { BtPassGridComponent } from './bt-pass-grid/bt-pass-grid.component';
import { BtPassPlanCardComponent } from './bt-pass-plans-list/bt-pass-plan-card/bt-pass-plan-card.component';
import { BtPassPlansListComponent } from './bt-pass-plans-list/bt-pass-plans-list.component';
import { BtPassComponent } from './bt-pass.component';
import { BtPassRoutingModule } from './bt-pass.routing';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  declarations: [
    BtPassComponent,
    BtPassGridComponent,
    BtPassPlansListComponent,
    BtPassPlanCardComponent,
  ],
  imports: [
    BtPassRoutingModule,
    CommonModule,
    ErrorModule,
    GetAppsModule,
    HeaderStaticImageModule,
    LoadingContentModule,
    ModalBtpassPassInfoModule,
    ModalGymsAppModule,
    ModalGymSelectByCityModule,
    StateGymFilterModule,
    SwiperModule,
    TemplateHightlightModule,
    RegistrationModule,
  ],
  providers: [
    BTPassService,
    EvoGymService,
    EvoMembershipService,
    IncomeResolveGuard,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EvoRedirectInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XAuthorizationInterceptor,
      multi: true,
    },
  ],
})
export class BtPassModule {}
