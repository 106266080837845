import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OrderByModule } from '@pipes';
import { RemoveBrandModule } from '@pipes/remove-brand/remove-brand.module';
import { SafeHtmlModule } from '@pipes/safe-html/safe-html.module';
import { ModalGymPlanComponent } from './modal-gym-plan.component';

@NgModule({
  declarations: [ModalGymPlanComponent],
  imports: [
    CommonModule,
    ModalModule,
    OrderByModule,
    RemoveBrandModule,
    SafeHtmlModule,
  ],
  exports: [ModalGymPlanComponent],
})
export class ModalGymPlanModule {}
