import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Gym } from '@models';
import { GymService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class GymResolveGuard implements Resolve<Gym> {
  constructor(
    private readonly gymService: GymService,
    private readonly router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): ResolveResult<Gym | null> {
    const id = route.params['gymUnitId'];

    if (id) {
      return this.gymService.getGymById(id).pipe(
        tap((gym) => {
          if (!gym) {
            this.router.navigate(['/bt-pass']);
          }
        })
      );
    } else {
      return null;
    }
  }
}
