import { HttpClient } from '@angular/common/http';
import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  HeaderDefaultLinksResponse,
  HeaderLinksResponse,
} from '@models/responses/header-links-response.model';

@Injectable()
export class HeaderService {
  public template = new BehaviorSubject<TemplateRef<any>>(null);
  public showHeader = true;

  constructor(private readonly http: HttpClient) {}

  public getHeaderLinks(): Observable<HeaderDefaultLinksResponse> {
    return this.http.get<HeaderDefaultLinksResponse>(
      'assets/custom_data/header-links.json'
    );
  }

  /** @deprecated use `getHeaderLinks` instead. */
  public getHeaderLinksMobile(): Observable<HeaderLinksResponse> {
    return this.http.get<HeaderLinksResponse>(
      'assets/data/header-links-mobile.json'
    );
  }

  /** @deprecated use `getHeaderLinks` instead. */
  public getHeaderLinksLogged(): Observable<HeaderLinksResponse> {
    return this.http.get<HeaderLinksResponse>(
      'assets/data/header-links-logged.json'
    );
  }
}
