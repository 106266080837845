import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appPasswordValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordValidatorDirective,
      multi: true,
    },
  ],
})
export class PasswordValidatorDirective implements Validator {
  // Regex que validar texto com Números E Letras
  REGEX_VALIDATOR =
    /([a-zA-Zu00C0-u00FF]*([0-9u00C0-u00FF]+[a-zA-Zu00C0-u00FF]+)|([a-zA-Zu00C0-u00FF]+[0-9u00C0-u00FF]+)[0-9u00C0-u00FF]*)+/;

  validate(control: AbstractControl): { [key: string]: any } {
    if (control.value) {
      const match = control.value.match(this.REGEX_VALIDATOR);

      if (!match || control.value.length < 6) {
        return { password: true };
      }
    }

    return null;
  }
}
