import {
  Component,
  AfterViewInit,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '@services/header.service';

@Component({
  selector: 'app-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.scss'],
})
export class SuccessPageComponent implements AfterViewInit {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  @Input() pageTitle = '';
  @Input() title = '';
  @Input() message = '';
  @Input() buttonName = '';
  @Input() redirectTo = '';
  @Input() secundaryButtonName: string = null;
  @Input() secundaryRedirectTo: string = null;

  queryParams: any;

  constructor(
    private readonly headerService: HeaderService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
  }

  ngAfterViewInit() {
    if (this.pageTitle) {
      setTimeout(() => this.headerService.template.next(this.headerComponent));
    }
  }
}
