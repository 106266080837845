import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { mergeMap } from 'rxjs/operators';
import {
  AlertMessage,
  MessageMap,
  ModalType,
  MyContractResponse,
  RegistrationPaymentData,
  SelectItem,
} from '@models';
import { ModalService, MyContractService } from '@services';
import { AlertMessageService } from '@services/alert-message.service';
import { AuthService } from '@services/auth.service';
import { Brand, BrandService } from '@services/brand.service';
import { HeaderService } from '@services/header.service';
import { LoadingService } from '@services/loading.service';
import { AppConstants } from '@utils/app-constants';
import { CreditCardUtil } from '@utils/credit-card-util';
import { DateUtil } from '@utils/date-util';
import { FormUtil } from '@utils/form-util';
import { FormatUtils } from '@utils/format-utils';

@Component({
  selector: 'app-contract-cancel',
  templateUrl: './contract-cancel.component.html',
  styleUrls: ['./contract-cancel.component.scss'],
})
export class ContractCancelComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;
  @ViewChild('formData', { static: false }) formDataComponent: NgForm;
  @ViewChild('errorContainer', { static: false })
  errorContainerComponent: ElementRef;

  readonly locale = AppConstants.LOCALE;
  readonly docMask = AppConstants.Mask.doc;

  reason: string;
  brand: Brand;
  numberDebts = 3; // Default - BT
  info: {
    data: any;
    error: HttpErrorResponse;
    success: any;
    contract: MyContractResponse;
    payment?: RegistrationPaymentData;
    flagOptions?: SelectItem<any>[];
  };
  justClickPayment: RegistrationPaymentData;
  user = {
    login: '',
    password: '',
  };
  stepToShow = '';
  years: SelectItem<any>[];
  months: SelectItem<any>[];

  constructor(
    private readonly authService: AuthService,
    private readonly alertMessageService: AlertMessageService,
    private readonly route: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly loadingService: LoadingService,
    private readonly modalService: ModalService,
    public readonly contractService: MyContractService,
    public readonly brandService: BrandService
  ) {
    this.brand = this.brandService.getBrand();
    if (this.brand === 'fr') {
      this.numberDebts = 4;
    }
  }

  ngOnInit() {
    this.user.login = this.authService.getUser().login;
    this.info = this.route.snapshot.data.info;

    this.justClickPayment = Object.assign({}, this.info.payment);

    if (this.info.error) {
      this.reason = this.alertMessageService.extractMessage(this.info.error);
      this.stepToShow = 'error';
    }

    this.months = DateUtil.getMonthsOfTheYear();
    this.years = DateUtil.getYears();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.headerService.template.next(this.headerComponent);
      if (this.formDataComponent) {
        this.formDataComponent.form.setValidators(CreditCardUtil.isExpired);
      }
    }, 0);
  }

  ngOnDestroy() {
    if (this.formDataComponent) {
      this.formDataComponent.form.setValidators(null);
    }
  }

  confirmCancel() {
    FormUtil.touchForm(this.formDataComponent.control);

    if (this.formDataComponent.invalid) {
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO)
      );
      return;
    }

    if (this.info.data.requestPayment) {
      FormatUtils.formatCardExpirationIfNeeded(
        `${this.info.payment.cardExpiringMonth}/${this.info.payment.cardExpiringYear}`
      );
    }

    this.loadingService.startLoading();

    // é feita a chamada de autenticação para o usuario confirmar a senha dele
    this.authService
      .authenticateUser({
        email: this.user.login,
        password: this.user.password,
      })
      .pipe(
        mergeMap(() =>
          this.contractService.cancelContractFinish(
            this.info.contract.contractCode,
            this.info.data.requestPayment
              ? this.info.payment
              : ({} as RegistrationPaymentData)
          )
        )
      )
      .subscribe(
        () => {
          this.info.success = { message: 'Pedido de cancelamento cadastrado' };
          this.stepToShow = 'success';
          this.loadingService.stopLoading();
        },
        () => this.loadingService.stopLoading()
      );
  }

  prefillJustClick(checked: boolean) {
    const keepPayment = {
      cardCVV: this.info.payment.cardCVV,
    } as RegistrationPaymentData;
    const keepFields = {
      password: this.user.password,
      ...keepPayment,
    };

    this.formDataComponent.reset(keepFields);

    if (checked) {
      this.info.payment = Object.assign(keepPayment, this.justClickPayment);
    } else {
      this.info.payment = keepPayment;
    }
    this.info.payment.justClick = checked;
  }

  openCVVModal() {
    this.modalService.show({
      type: ModalType.creditCardInfo,
      title: null,
      message: null,
      data: {},
    });
  }
}
