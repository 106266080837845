import { Component, OnInit } from '@angular/core';
import { ModalType } from '@models';
import { ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss'],
})
export class ModalConfirmComponent
  extends ModalCommonComponent
  implements OnInit {
  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.confirm;
  }
}
