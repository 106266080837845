import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import {
  AgendaEvent,
  FormattedKidsClassTime,
  KidsClass,
  KidsClassTime,
  KidsTimetable,
  ModalAgendaObject,
  ModalKidsDetailObject,
  ModalKidsScheduleObject,
  ModalType,
} from '@models';
import { AgendaService, LoadingService, ModalService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { ArrayUtil } from '@utils/array-util';
import { FormatUtils } from '@utils/format-utils';

@Component({
  selector: 'app-day-of-week',
  templateUrl: './day-of-week.component.html',
  styleUrls: ['./day-of-week.component.scss'],
})
export class DayOfWeekComponent implements AfterViewInit {
  @Input() cell: any;
  @Input() eventTable?: KidsTimetable[];

  dateFormat = 'dd/MM/yyyy';

  constructor(
    private readonly agendaService: AgendaService,
    private readonly modalService: ModalService,
    private readonly datePipe: DatePipe,
    private readonly loadingService: LoadingService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.cdr.detach();
  }

  showEvent(gridEvent: AgendaEvent) {
    this.loadingService.startLoading();
    this.agendaService
      .getSchedulerDetailByItemGridId(
        gridEvent.itemGridId,
        gridEvent.gridActivityId,
        gridEvent.dayOfTheWeek
      )
      .subscribe((event) => {
        this.loadingService.stopLoading();
        event.audiences.forEach(
          (age) =>
            (age.description = FormatUtils.ageRangeToDescription(
              age.minimumAge,
              age.maximumAge
            ))
        );
        event.audiences.sort((a, b) => a.minimumAge - b.minimumAge);

        const audienceIds = event.audiences.map((o) => o.id);
        const filtered = event.audiences.filter(
          ({ id }, index) => !audienceIds.includes(id, index + 1)
        );

        event.audiences = filtered;

        const modal: ModalAgendaObject = {
          type: ModalType.agenda,
          title: event.itemGridTitle,
          message: '',
          event,
        };
        this.modalService.show(modal);
      });
  }

  showEventKids(gridEvent: FormattedKidsClassTime) {
    const availableClassHour = this.filterAvailableClassesByType(gridEvent);
    const availableClassesDayList = availableClassHour.map((classes: any) => {
      return classes.availableClassDay;
    });

    const event = {
      name: gridEvent.nomeAtividade,
      audiences: gridEvent.nomeFaixaEtaria,
      duration: gridEvent.duracao,
      teacherName: gridEvent.nomeProfessor,
      itemGridPlace: gridEvent.local,
      activityId: gridEvent.idAtividade,
      classCode: gridEvent.codTurma,
      isAvailable: gridEvent.podeAgendar,
      scheduleOnlyInGym: gridEvent.agendaUnidade,
      targetAudienceLevel: gridEvent.nivel ? gridEvent.nivel[0] : null,
      targetAudienceLevelList: gridEvent.nivel,
      availableClassesDayList,
      availableClassHour,
    };

    const modal: ModalKidsDetailObject = {
      type: ModalType.kidsDetails,
      title: gridEvent.nomeAtividade,
      message: '',
      event,
      confirmCallback: (res: {
        hour: string;
        date: string;
        event: KidsClassTime;
      }) => {
        this.startExperimentalClassSchedule(event, res);
      },
    };
    this.modalService.show(modal);
  }

  private filterAvailableClassesByType(classInfo: any) {
    const availableDates: any[] = [];
    this.eventTable.forEach((classHours: KidsTimetable) => {
      classHours?.classes?.forEach((classDate: KidsClass) => {
        classDate.classesHora.forEach((classDetails: KidsClassTime) => {
          if (
            classDate.nomeAtividade === classInfo.nomeAtividade &&
            ArrayUtil.equalLists<string>(
              classDetails.nomeFaixaEtaria,
              classInfo.nomeFaixaEtaria
            ) &&
            classDetails.codTurma === classInfo.codTurma &&
            classDetails.podeAgendar &&
            availableDates.some(
              (elem) =>
                elem.availableClassDay ===
                this.datePipe.transform(classHours.classeData, this.dateFormat)
            )
          ) {
            availableDates.forEach((elem) => {
              if (
                elem.availableClassDay ===
                this.datePipe.transform(classHours.classeData, this.dateFormat)
              ) {
                elem.classHours.push(classDetails);
                elem.classHours = elem.classHours.filter(
                  (item: any, index: any, self: any) =>
                    index === self.findIndex((t: any) => t.hora === item.hora)
                );

                elem.classHours.sort((a: any, b: any) => {
                  return (
                    this.formatGridHourToNumber(a.hora) -
                    this.formatGridHourToNumber(b.hora)
                  );
                });
              }
            });
          }

          if (
            this.isEqualClassInfoAndNotYetAnAvailableDay(
              classDate,
              classInfo,
              classDetails,
              availableDates,
              classHours
            )
          ) {
            availableDates.push({
              availableClassDay: this.datePipe.transform(
                classHours.classeData,
                this.dateFormat
              ),
              classHours: [classDetails],
            });
          }
        });
      });
    });

    return availableDates;
  }

  private isEqualClassInfoAndNotYetAnAvailableDay(
    classDate: KidsClass,
    classInfo: any,
    classDetails: KidsClassTime,
    availableDates: any,
    classHours: KidsTimetable
  ): boolean {
    return (
      classDate.nomeAtividade === classInfo.nomeAtividade &&
      ArrayUtil.equalLists<string>(
        classDetails.nomeFaixaEtaria,
        classInfo.nomeFaixaEtaria
      ) &&
      classDetails.codTurma === classInfo.codTurma &&
      classDetails.podeAgendar &&
      !availableDates.some(
        (elem: any) =>
          elem.availableClassDay ===
          this.datePipe.transform(classHours.classeData, this.dateFormat)
      )
    );
  }

  private formatGridHourToNumber(hour: string): number {
    return parseInt(hour.replace(/[^0-9]/g, ''), 10);
  }

  private startExperimentalClassSchedule(
    classData: any,
    selectedDate: { hour: string; date: string; event: KidsClassTime }
  ) {
    const modal: ModalKidsScheduleObject = {
      type: ModalType.kidsSchedule,
      title: classData.name,
      message: '',
      kidsEvent: classData,
      selectedDate,
      cachedData: JSON.parse(
        localStorage.getItem(AppConstants.STOR_REGISTRATION_LANDING_PAGE_KIDS)
      ),
    };
    this.modalService.show(modal);
  }
}
