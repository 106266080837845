<div class="sticky-top background d-print-none"></div>
<div class="sticky-top legend-container today-index-{{ today }}">
  <div *ngIf="days?.length">
    <div
      (scroll)="onScroll(scrollContainer.scrollLeft)"
      #scrollContainer
      [scrollLeft]="left"
      class="light-shadow table-container hide-scrollbar"
    >
      <div class="time-entry row true-columns">
        <div class="col time-col title-md text-light p-absolute-md-down">
          <div class="time header">
            <img
              alt="relógio"
              class="clock-icon"
              src="/assets/icons/ic-time.svg"
            />
          </div>
        </div>
        <div class="col time-col d-lg-none"></div>
        <div
          class="col day-of-week title header"
          [class.highlight]="cellIndex === highlight"
          *ngFor="let header of days; let cellIndex = index"
        >
          <div class="cell-day">{{ header }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
