import { Component, Input } from '@angular/core';
import { ProfileData } from '@models';
import { AppConstants } from '@utils/app-constants';
import { BrandService } from '@services/brand.service';

@Component({
  selector: 'app-profile-data-main',
  templateUrl: './profile-data-main.component.html',
  styleUrls: [
    './profile-data-main.component.scss',
    '../../profile.shared.scss',
  ],
})
export class ProfileDataMainComponent {
  @Input() info: ProfileData;

  readonly locale = AppConstants.LOCALE;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService
  ) {}
}
