import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { from, Subscription } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import {
  BtPassPlanInfo,
  FormatedBtPassValuesGrid,
  FormatedIncomeList,
  Income,
  IncomeWithGymUnitAndQuantity,
} from '@models/bt-pass.model';
import {
  BreakpointsService,
  BTPassService,
  EvoMembershipService,
  GymService,
  ScreenSize,
} from '@services';
import { AppConstants } from '@utils/app-constants';

@Component({
  selector: 'app-bt-pass-grid',
  templateUrl: './bt-pass-grid.component.html',
  styleUrls: ['./bt-pass-grid.component.scss'],
})
export class BtPassGridComponent implements OnInit, OnDestroy {
  @Input() btpassPlanList?: BtPassPlanInfo[];
  @Input() btPassPlanLoading?: boolean;

  @Output() selectedPassEmitter = new EventEmitter<
    Omit<IncomeWithGymUnitAndQuantity, 'quantity'>
  >();

  public btPassLoading = false;
  private breakpointServiceSubscribe: Subscription;
  isBreakpointUpMd: boolean;
  btPassData: FormatedBtPassValuesGrid;
  filteredbtPassData: FormatedBtPassValuesGrid;
  locale = AppConstants.LOCALE;

  constructor(
    private readonly breakpointService: BreakpointsService,
    private readonly btPassService: BTPassService,
    private readonly evoMembershipService: EvoMembershipService,
    private readonly gymService: GymService
  ) {}

  ngOnInit(): void {
    this.getGrid();
    this.breakpointListener();
  }

  ngOnDestroy(): void {
    this.breakpointServiceSubscribe?.unsubscribe();
  }

  private breakpointListener(): void {
    this.breakpointServiceSubscribe = this.breakpointService.emitter
      .pipe(
        tap(
          (breakpoint) => (this.isBreakpointUpMd = breakpoint > ScreenSize.MD)
        )
      )
      .subscribe();
  }

  private getGrid(): void {
    this.btPassLoading = true;

    this.btPassService.getBtPassGrid().subscribe((grid) => {
      this.btPassData = grid;
      this.filteredbtPassData = {
        ...grid,
        incomesList: [],
      };
      this.btPassLoading = false;
    });
  }

  openPassInfoModal(
    income: Income,
    gymUnitId: number,
    gymUnitName: string
  ): void {
    this.selectedPassEmitter.emit({ income, gymUnitId, gymUnitName });
  }

  setFilteredBtPassData(data: FormatedIncomeList[]): void {
    this.btPassLoading = true;
    this.filteredbtPassData.incomesList = data;

    const migratedGyms = data.filter((item) => item.isMigrated);
    if (migratedGyms.length) {
      let isLastRequest = false;

      from(migratedGyms)
        .pipe(
          concatMap((migratedGym, index) => {
            const { gymUnitId } = migratedGym.gym;
            isLastRequest = index === migratedGyms.length - 1;

            return this.evoMembershipService.getBTPass({
              gymUnitId: gymUnitId.toString(),
            });
          })
        )
        .subscribe((response) => {
          this.filteredbtPassData.incomesList.forEach((gym) => {
            if (response[0]?.idBranch === gym.gymCode) {
              gym.values =
                this.evoMembershipService.mapMembershipsToBTpassGrid(response);
            }
          });

          this.btPassLoading = !isLastRequest;
        });
    } else {
      this.btPassLoading = false;
    }
  }

  openGymPage(id: number): void {
    this.gymService
      .getGymById(id)
      .subscribe((gym) => window.open(`/academia/${gym.slug}`));
  }
}
