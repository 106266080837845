import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from '@services/brand.service';
import { HeaderService } from '@services/header.service';

@Component({
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss'],
})
export class WorkWithUsComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  imageHeader: string;
  headerTitle: string;
  iframeUrl: SafeResourceUrl;

  public isBT: boolean;

  constructor(
    private readonly brandService: BrandService,
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
    protected readonly sanitazer: DomSanitizer
  ) {}

  ngOnInit() {
    this.isBT = this.brandService.getBrand() === 'bt';
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
