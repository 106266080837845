import { AnalyticsService } from '@services';
import { Component, Input, OnInit } from '@angular/core';
import { GymUnitPeriodicity, Promotion } from '@models';
import { AppConstants } from '@utils/app-constants';
import { Brand, BrandService } from '@services/brand.service';

@Component({
  selector: 'app-gym-detail-pay-option',
  templateUrl: './gym-detail-pay-option.component.html',
  styleUrls: ['./gym-detail-pay-option.component.scss'],
})
export class GymDetailPayOptionComponent implements OnInit {
  @Input() preSale: boolean;
  @Input() gymName: string;
  @Input() planTitle: string;
  @Input() planSlug: string;
  @Input() gymUnitPeriodicity: GymUnitPeriodicity;
  @Input() isLandingPageKids = false;
  @Input() promotionActived: Promotion;
  @Input() showPromotionTag: boolean;

  locale = AppConstants.LOCALE;
  isPromotionActived = false;
  amount: number;
  feeValue = 0;
  feeText: string;
  brand: Brand;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly brandService: BrandService
  ) {
    this.brand = this.brandService.getBrand();
  }

  ngOnInit() {
    if (this.gymUnitPeriodicity.periodicity.months) {
      this.amount =
        this.gymUnitPeriodicity.planValue /
        this.gymUnitPeriodicity.periodicity.months;
    } else {
      this.amount = this.gymUnitPeriodicity.planValue;
    }

    this.chargeFee();

    const current_date = new Date();
    const initial_date = new Date(2020, 10, 1, 1, 0);
    const final_date = new Date(2020, 11, 1, 23, 59, 59);

    this.isPromotionActived =
      current_date.getTime() >= initial_date.getTime() &&
      current_date.getTime() <= final_date.getTime() &&
      !!this.promotionActived &&
      this.showPromotionTag &&
      this.gymUnitPeriodicity.status === 1;

    if (this.brand === 'fr' && !/.*fitness.*/.test(this.planSlug)) {
      this.isPromotionActived = false;
    }
  }

  chargeFee() {
    if (this.gymUnitPeriodicity.showChannelSubscriptionFee) {
      this.feeValue = this.gymUnitPeriodicity.subscriptionFee;
      this.feeText = this.gymUnitPeriodicity.subscriptionFeeText;
    } else if (this.gymUnitPeriodicity.showChannelRegistration) {
      this.feeValue = this.gymUnitPeriodicity.registration;
      this.feeText = this.gymUnitPeriodicity.registrationText;
    }
  }

  clickOnEnroll() {
    this.analyticsService.trackEvent(
      `Clique em "Matricule-se" - ${this.planTitle} - ${this.gymUnitPeriodicity.periodicity.name}`,
      'Vendas',
      `Formulário de vendas - ${this.gymName}`
    );
  }
}
