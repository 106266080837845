import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsOverflownPipe } from './is-overflown.pipe';

@NgModule({
  declarations: [IsOverflownPipe],
  imports: [CommonModule],
  exports: [IsOverflownPipe],
})
export class IsOverflownModule {}
