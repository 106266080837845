import { Directive, HostListener, Input } from '@angular/core';

/**
 * This directive will apply classes in an indicated element when hovering over the directive element.
 * This directive was created because, until today, parent selector isn't possible in CSS.
 *
 * **Be careful when using**: selection is made by a `document.querySelector()`,
 * which means the selection will be made in the whole DOM.
 *
 * ### Example
 * #### Not Hovering
 * ```html
 *  <h1>Title</h1>
 *
 *  <button appTemplateHightlight="h1" classes="text-green">Ok</button>
 * ```
 *
 * #### Hovering
 * ```html
 *  <h1 class="text-green">Title</h1>
 *
 *  <button appTemplateHightlight="h1" classes="text-green" :hover>Ok</button>
 * ```
 */

@Directive({
  selector: '[appTemplateHightlight]',
})
export class TemplateHightlightDirective {
  /** Element selector that will be use in a `document.querySelector()`. */
  @Input() appTemplateHightlight: string;
  /** Classes that you wish to apply when hover the directive element's. */
  @Input() classes: string;

  @HostListener('mouseenter')
  mouseEnter() {
    document
      .querySelector(`${this.appTemplateHightlight}`)
      ?.classList.add(this.classes);
  }

  @HostListener('mouseleave')
  mouseLeave() {
    document
      .querySelector(`${this.appTemplateHightlight}`)
      ?.classList.remove(this.classes);
  }
}
