import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GymPlanResolveGuard } from '@guards/gym-plan-resolve.guard';
import { RegistrationLockingComponent } from './registration-locking.component';
import { AuthGuard } from '@guards/auth.guard';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { RegistrationLockingMainComponent } from './registration-locking-main/registration-locking-main.component';
import { RegistrationLockingRequestComponent } from './registration-locking-request/registration-locking-request.component';
import { RegistrationLockingRequestConfirmComponent } from './registration-locking-request-confirm/registration-locking-request-confirm.component';

const registrationLockingRoute: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: RegistrationLockingMainComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
      },
      {
        path: 'agendar',
        component: RegistrationLockingRequestComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
      },
      {
        path: 'confirma-agendamento',
        component: RegistrationLockingRequestConfirmComponent,
        canActivate: [AuthGuard],
        canLoad: [AuthGuard],
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
    component: RegistrationLockingComponent,
    resolve: {
      registration: GymPlanResolveGuard,
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.imageHeader.title}',
      windowContext: 'locking-registration',
      slug: 'locking-registration',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(registrationLockingRoute)],
  exports: [RouterModule],
})
export class RegistrationLockingRoutingModule {}
