import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import {
  Address,
  AlertMessage,
  ContactInfoModel,
  Gym,
  GymActivity,
  GymBasicInfoModel,
  GymConvenience,
  GymImagesModel,
  GymModel,
  GymResponseModel,
  GymSearchLocation,
  GymSearchMessage,
  GymSearchQueryModel,
  GymSearchResponse,
  MessageMap,
  PhotoTypeEnum,
  SelectItem,
} from '@models';
import { AppConstants } from '@utils/app-constants';
import { sur, SurEndpoints } from '@utils/app-endpoints';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { AlertMessageService } from './alert-message.service';
import { AuthService } from './auth.service';
import { BrandService } from './brand.service';
import { HttpCacheService } from './http-cache.service';
import { PlanService } from './plan.service';

@Injectable()
export class GymService {
  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly apollo: Apollo,
    private readonly authService: AuthService,
    private readonly brandService: BrandService,
    private readonly http: HttpClient,
    private readonly httpCacheService: HttpCacheService,
    private readonly planService: PlanService
  ) {}

  private getGoogleLinkFormatted = (address: Address): string => {
    return address.latitude || address.longitude
      ? `${AppConstants.googleMapUrl}${address.latitude}, ${address.longitude}`
      : '';
  }

  private mapContactInfoData = (gym: Gym): ContactInfoModel => {
    const { address } = gym;

    return {
      idBranch: gym.code,
      address: `${address.publicPlace.name} ${address.street}`,
      city: address.city.name,
      name: gym.portalName,
      neighborhood: address.neighborhood,
      number: address.number,
      idState: address.city.state.id,
      stateShort: address.city.state.initials,
      telephone: `${gym.phones[0]?.ddd}${gym.phones[0]?.number}`,
      zipCode: address.zipCode.replace('-', ''),
    };
  }

  public mapGymToGymModel(gym: Gym): GymBasicInfoModel {
    return {
      ...gym,
      evoMigratedUnit: !!gym.evoMigratedUnit?.id,
      googleLink: this.getGoogleLinkFormatted(gym.address),
      contactInfo: this.mapContactInfoData(gym),
    };
  }

  public searchByQuery(
    filterParam: GymSearchQueryModel,
    max: number,
    page: number
  ): Observable<GymResponseModel> {
    const offset = page * max;
    const filter = {
      ...filterParam,
      max,
      offset,
    };

    /** Transform all values of the object into strings. */
    const params = JSON.parse(JSON.stringify(filter));

    return this.httpCacheService
      .get<GymSearchResponse>(sur(SurEndpoints.UrlGymUnit), { params })
      .pipe(
        map((response) => {
          const results = response.results.map((result) =>
            this.mapGymToGymModel(result)
          );
          const more = response.totalCount > offset + max;

          return {
            ...response,
            results,
            more,
          } as GymResponseModel;
        })
      );
  }

  public searchGymsAutocomplete(text: string): Observable<Gym[]> {
    const params = {
      search: text,
      max: '8',
      simple: 'true',
    };

    return this.http
      .get<GymSearchResponse>(sur(SurEndpoints.UrlGymUnit), { params })
      .pipe(map((value) => value.results));
  }

  getGymNameBySlug(slug: string, withActivities = false) {
    return this.getGymNames(withActivities).pipe(
      map((gymNames) => gymNames.find((gymName) => gymName.slug === slug))
    );
  }

  getGymNames(withActivities = false): Observable<any[]> {
    const query = withActivities
      ? AppGraphql.queryListGymUnitsThatAreNotHoldingWithActivities
      : AppGraphql.queryListGymUnitsThatAreNotHolding;

    return this.apollo
      .query({ query })
      .pipe(map(JSONUtil.turnApolloMutable('listGymUnitsThatAreNotHolding')));
  }

  getGymNamesWithState(): Observable<Gym[]> {
    return this.apollo
      .query({
        query: AppGraphql.queryListGymUnitsNotHoldingWithState,
      })
      .pipe(map(JSONUtil.turnApolloMutable('listGymUnitsThatAreNotHolding')));
  }

  getCommotidies(): Observable<GymConvenience[]> {
    return this.http.get<GymConvenience[]>(
      'assets/mockup/gym-commotidies.json'
    );
  }

  getGymBySlug(slug: string): Observable<GymModel> {
    return this.apollo
      .query({
        query: AppGraphql.queryGetGymUnitBySlug,
        variables: { slug },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<{
            getGymUnitBySlug: Gym;
            listActiveActivitiesByGymUnit: GymActivity[];
          }>()
        ),
        map((response) => {
          const gym: Gym = response.getGymUnitBySlug;

          if (gym) {
            return {
              ...this.mapGymToGymModel(gym),
              activities: response.listActiveActivitiesByGymUnit,
              coordinates: {
                latitude: +gym.address.latitude,
                longitude: +gym.address.longitude,
              },
            } as GymModel;
          }
        })
      );
  }

  getLegacyGymBySlug(slug: string): Observable<Gym> {
    return this.apollo
      .query({
        query: AppGraphql.queryGetGymUnitBySlug,
        variables: { slug },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<{
            getGymUnitBySlug: Gym;
            listActiveActivitiesByGymUnit: GymActivity[];
          }>()
        ),
        map((data) => {
          return data.getGymUnitBySlug
            ? {
                ...data.getGymUnitBySlug,
                activities: data.listActiveActivitiesByGymUnit,
              }
            : null;
        })
      );
  }

  getGymById(id: number): Observable<Gym> {
    return this.apollo
      .query({
        query: AppGraphql.queryGetGymUnitById,
        variables: { id },
      })
      .pipe(map(JSONUtil.turnApolloMutable<Gym>('getGymUnitById')));
  }

  getCombinatedGymUnits(gymUnitId: number) {
    return this.apollo
      .query({
        query: AppGraphql.queryListCombinatedGymUnits,
        variables: { gymUnitId },
      })
      .pipe(map(JSONUtil.turnApolloMutable<Gym[]>('listCombinatedGymUnits')));
  }

  getBannerImage(photos: GymImagesModel[]): GymImagesModel {
    return photos.find((photo) => photo.objectType === PhotoTypeEnum.BANNER);
  }

  getCarouselImages(photos: GymImagesModel[]): GymImagesModel[] {
    return photos.filter(
      (photo) => photo.objectType === PhotoTypeEnum.CARROSSEL
    );
  }

  getThumbnailImage(gym: Gym) {
    return this.filterPhotos(gym, PhotoTypeEnum.THUMBNAIL)[0];
  }

  filterPhotos(gym: Gym, type: PhotoTypeEnum) {
    return gym.photos.filter((photo) => photo.objectType === type);
  }

  getGymSearchMessage(result: string, order: string): Observable<string> {
    return this.httpCacheService
      .get<GymSearchMessage[]>('assets/data/msgsSearch.json')
      .pipe(
        map((messages) => {
          const messagesGroup = messages.find(
            (message) =>
              message.resultType === result && message.orderType === order
          );

          return messagesGroup.message;
        })
      );
  }

  getParamNameBySlug(
    state: string,
    city: string,
    neighborhood: string
  ): Observable<GymSearchLocation> {
    return this.apollo
      .query({
        query: AppGraphql.queryGetGymUnitStateCityAndNeighborhood,
        variables: { state, city, neighborhood },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<GymSearchLocation>(
            'getGymUnitStateCityAndNeighborhood'
          )
        ),
        map((data) => ({
          stateName: data && state ? data.stateName : '',
          cityName: data && city ? data.cityName : '',
          addressNeighborhood:
            data && neighborhood ? data.addressNeighborhood : '',
        }))
      );
  }

  gymToSelect(gym: Gym): SelectItem<Gym> {
    return {
      ...gym,
      text: gym.portalName,
    };
  }

  getUserMainGym(withActivities = false) {
    const user = this.authService.getUser();

    return user
      ? this.getGymNames(withActivities).pipe(
          map((gyms) => gyms.find((name) => name.id === user.maingym))
        )
      : EMPTY;
  }

  queryGetGymUnitSlug(gymUnitId: number) {
    return this.apollo
      .query({
        query: AppGraphql.queryGetGymUnitSlug,
        variables: { gymUnitId },
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<any[]>('listPlansFromView')),
        map((result) => result[0].gymUnitSlug)
      );
  }

  getDeletedGyms(): Observable<string[]> {
    return this.http.get<string[]>('assets/data/deleted-gyms.json');
  }

  getAllGymsOfCustomer() {
    return this.planService.getCustomerPlans().pipe(
      map((result) => {
        const plans = result.customerPlans.filter(
          (plan: any) => plan.itemStatus === 'Ativo' && plan.type === 'Plano'
        );
        if (!plans.length) {
          this.alertMessageService.showToastr(
            AlertMessage.warning(MessageMap.NAO_POSSUI_CONTRATO_ATIVO)
          );
        }
        return plans;
      }),
      mergeMap((result) =>
        this.getGymNamesWithState().pipe(
          switchMap((gyms) =>
            forkJoin(
              result.map((plan: any) =>
                this.getGymBySlug(plan.gymUnitSlug).pipe(
                  map((gym) => {
                    if (!gym) {
                      return [];
                    }
                    const planType = this.getPlanType(plan);
                    switch (planType) {
                      case 'PLATINUM':
                      case 'STATE':
                        return this.gymListWithSpecialGymsFiltered(plan, gyms);
                      case 'EXCLUSIVE':
                        return gyms.filter((_gym) => _gym.id === gym.id);
                      default:
                        return [
                          gym,
                          ...gym.gymUnitDestinations,
                          ...this.gymsOfOutsideState(plan, gyms, gym),
                        ];
                    }
                  })
                )
              )
            )
          ),
          map((_result) =>
            _result.reduce((acc: Gym[], cur: Gym[]) => [
              ...acc,
              ...cur.filter((gym) => !acc.some((_gym) => _gym.id === gym.id)),
            ])
          )
        )
      )
    );
  }

  gymsOfOutsideState(plan: any, gyms: Gym[], gym: GymModel) {
    return this.gymListWithSpecialGymsFiltered(plan, gyms).filter(
      (_gym) => _gym.address.city.state.id !== gym.contactInfo.idState
    );
  }

  getPlanType(plan: any) {
    const planPlatinumIds =
      this.brandService.getBrand() === 'bt'
        ? [6, 12, 75, 76, 92, 93, 94, 95, 96, 97]
        : [33, 34];
    const planFitnessStateIds =
      this.brandService.getBrand() === 'bt' ? [172, 173, 177, 176] : [];

    if (plan.planGymUnitExclusive) {
      return 'EXCLUSIVE';
    }
    if (planPlatinumIds.includes(plan.planSuperiorId)) {
      return 'PLATINUM';
    }
    if (planFitnessStateIds.includes(plan.planSuperiorId)) {
      return 'STATE';
    }

    return 'OTHER';
  }

  gymListWithSpecialGymsFiltered(plan: any, gyms: Gym[]) {
    const FARIA_LIMA = 34;
    const BT_KIDS = 63;
    const isFariaLima = plan.gymUnitId === FARIA_LIMA;
    const isBTKids = plan.gymUnitId === BT_KIDS;
    const isPortalBT = this.brandService.getBrand() === 'bt';

    if (isPortalBT) {
      if (isFariaLima) {
        return gyms.filter((_gym) => _gym.id !== BT_KIDS);
      } else if (isBTKids) {
        return gyms.filter((_gym) => _gym.id !== FARIA_LIMA);
      } else {
        return gyms.filter(
          (_gym) => _gym.id !== FARIA_LIMA && _gym.id !== BT_KIDS
        );
      }
    } else {
      return gyms;
    }
  }

  getGymUnitsThatAreAvailableToScheduling(): Observable<any[]> {
    return this.apollo
      .query({
        query: AppGraphql.queryListGymUnitAvailableToScheduling,
      })
      .pipe(
        map(JSONUtil.turnApolloMutable('listGymUnitAvailableToScheduling'))
      );
  }

  getListAvailableGymUnitByPlan(
    gymUnitId: number,
    planId: number
  ): Observable<any> {
    return this.apollo
      .query({
        query: AppGraphql.queryListAvailableGymUnitByPlan,
        variables: {
          gymUnitId,
          planId,
        },
      })
      .pipe(map(JSONUtil.turnApolloMutable('listAvailableGymUnitByPlan')));
  }
}
