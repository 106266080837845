import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DoubtArea } from '@models';
import { AnalyticsService } from '@services/analytics.service';
import { FaqPageService } from '@services/faq-page.service';
import { HeaderService } from '@services/header.service';

@Component({
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  imageHeader: string;
  headerTitle: string;
  jsonPage: Observable<DoubtArea[]>;
  activeTab: DoubtArea;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly pageService: FaqPageService,
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
    this.jsonPage = this.pageService.getFAQ().pipe(
      map((json) => {
        this.activeTab = json[0];
        return json;
      })
    );
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  expandQuestionAnalytics(sectionTitle: string, questionNumber: number) {
    this.analyticsService.trackEvent(
      `Dúvida: ${sectionTitle} - ${questionNumber + 1}`,
      'Dúvidas Frequentes',
      'Dúvida'
    );
  }
}
