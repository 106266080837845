import {
  Directive,
  ElementRef,
  OnInit,
  OnDestroy,
  Renderer2,
  ViewContainerRef,
  ChangeDetectorRef,
  HostListener,
  Input,
  TemplateRef,
} from '@angular/core';
import { PopoverDirective, PopoverConfig } from 'ngx-bootstrap/popover';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[popoverFade]',
  exportAs: 'app-popoverFade',
  providers: [PopoverConfig, ComponentLoaderFactory],
})
export class PopoverFadeDirective
  extends PopoverDirective
  implements OnInit, OnDestroy {
  @Input() containerClass = 'wait-to-show';
  @Input() container = 'body';
  @Input() popoverDisabled = false;

  private timer: any;

  get popoverFade() {
    return this.popover;
  }

  @Input() set popoverFade(val: string | TemplateRef<any>) {
    this.popover = val;
  }
  constructor(
    _elementRef: ElementRef,
    _positionService: PositioningService,
    _renderer: Renderer2,
    protected readonly _viewContainerRef: ViewContainerRef,
    protected readonly _config: PopoverConfig,
    protected readonly cis: ComponentLoaderFactory,
    protected readonly cdr: ChangeDetectorRef
  ) {
    super(
      _config,
      _elementRef,
      _renderer,
      _viewContainerRef,
      cis,
      _positionService
    );
  }

  @HostListener('mouseenter')
  fadeShow() {
    if (!this.popoverDisabled) {
      clearTimeout(this.timer);
      this.show();
      this.timer = setTimeout(() => {
        // @ts-ignore
        this._popover.instance.containerClass =
          this.containerClass + ' fade force-show';
      }, 0);
    }
  }

  @HostListener('mouseleave')
  fadeHide() {
    clearTimeout(this.timer);
    if (!this.popoverDisabled) {
      // @ts-ignore
      this._popover.instance.containerClass = this.containerClass + ' fade';
    }
    // @ts-ignore
    this.timer = setTimeout(() => this.hide && this._popover.dispose(), 151);
  }
}
