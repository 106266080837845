import { Component, Input, OnInit } from '@angular/core';
import { ContactInfoModel } from '@models';
import { AnalyticsService } from '@services';
import { FormatUtils } from '@utils/format-utils';

@Component({
  selector: 'app-gym-contact-info',
  templateUrl: './gym-contact-info.component.html',
})
export class GymContactInfoComponent implements OnInit {
  @Input() contactInfo: ContactInfoModel;

  constructor(private readonly analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.contactInfo.telephone = FormatUtils.maskPhone(
      this.contactInfo.telephone.replace(/[^\d]/g, '')
    );
  }

  public clickTelephone(): void {
    this.analyticsService.trackEvent(
      'Telefone',
      'Academias',
      `Resultado Academia - ${this.contactInfo.name}`
    );
  }
}
