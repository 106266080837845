<div class="title-section text-black">Criar conta de acesso</div>

<div>Informe seus dados de cadastro na {{ brandService.fullBrandName }}.</div>

<form #form="ngForm" class="form-container" (ngSubmit)="send()">

  <label for="create-new-cpf" class="text-uppercase">CPF</label>
  <input id="create-new-cpf"
    class="form-control"
    name="cpf"
    appCpfValidator
    [imask]="cpfMask"
    [(ngModel)]="userForm.cpf"
    #newCpf="ngModel"
    autocomplete="document-main"
    minlength="14"
    maxlength="14"
    required>
  <app-error-message [control]="newCpf"></app-error-message>
  <div class="button-container row no-gutters">
    <button
      id="create-cpf-cancel"
      class="col btn btn-outline-primary"
      (click)="goBack()"
      type="button">
        Voltar
    </button>

    <button
      id="create-cpf-confirm"
      class="col btn btn-primary"
      type="submit">
        Cadastrar
    </button>
  </div>

</form>

<div class="forgot-container">
  <a id="missing-cpf" href="javascript:void(0)" (click)="noCPF()" class="title link">Não tem CPF?</a>
</div>
