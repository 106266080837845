import { Subscription } from 'rxjs';
import { Component, Input, OnDestroy } from '@angular/core';
import { TeamBlock } from '@models';
import {
  BreakpointsService,
  screenSizeToString,
} from '@services/breakpoints.service';
import { Brand, BrandService } from '@services/brand.service';

@Component({
  selector: 'app-team-block',
  templateUrl: './team-block.component.html',
  styleUrls: ['./team-block.component.scss'],
})
export class TeamBlockComponent implements OnDestroy {
  private subs: Subscription;

  @Input() block: TeamBlock = {
    id: 'unk',
    title: '',
    type: 'team',
    content: { team: [] },
  };
  activeIndex = 0;

  screenSize: string;
  brand: Brand;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly breakpointsService: BreakpointsService
  ) {
    this.subs = this.breakpointsService.emitter.subscribe((size) => {
      this.screenSize = screenSizeToString(size);
    });
    this.brand = this.brandService.getBrand();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  prev() {
    this.activeIndex === 0
      ? (this.activeIndex = this.block.content.team.length - 1)
      : this.activeIndex--;
  }

  next() {
    this.activeIndex === this.block.content.team.length - 1
      ? (this.activeIndex = 0)
      : this.activeIndex++;
  }

  showAll(): boolean {
    return (
      this.screenSize === 'xl' ||
      this.screenSize === 'lg' ||
      (this.brand === 'fr' && this.screenSize === 'md')
    );
  }
}
