import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
})
export class SplitPipe implements PipeTransform {
  transform(value: string, split: any, index = 0): any {
    if (!value || !value.length) {
      return value;
    }
    return value.split(split)[index];
  }
}
