import { Injectable } from '@angular/core';
import {
  Gym,
  GymPeriodicityStatusEnum,
  GymPlan,
  GymUnitGroupPlan,
  GymUnitPeriodicity,
} from '@models';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { Observable } from 'rxjs';

@Injectable()
export class PlanService {
  constructor(
    private readonly apollo: Apollo,
    private readonly http: HttpClient
  ) {}

  getActivePlansWithPeriodicityByGym(gym: number) {
    return this.apollo
      .query<GymPlan[]>({
        query: AppGraphql.queryListActivePlansWithPeriodicityByGymUnit,
        variables: { gym },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<GymPlan[]>(
            'listActivePlansWithPeriodicityByGymUnit'
          )
        )
      );
  }

  getActivePlansByGymUnit(gym: Gym) {
    return this.apollo
      .query<GymUnitGroupPlan[]>({
        query: AppGraphql.queryListPlansFromView,
        variables: { gymUnitId: gym.id },
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<any[]>('listPlansFromView')),
        map((result) => {
          const listGymUnitGroupPlan: GymUnitGroupPlan[] = [];
          let gymUnitGroupPlan: GymUnitGroupPlan;
          let gymUnitPeriodicity: GymUnitPeriodicity;
          let gymUnitPeriodicities: {};

          const saleGroupPlan = result.filter((value) => value.planForSale);

          const periodicity: any[] = saleGroupPlan.filter(
            (value, index, self) =>
              self
                .map((x) => x.periodicityName)
                .indexOf(value.periodicityName) === index
          );

          const distinctGroupPlan = saleGroupPlan.filter(
            (value, index, self) =>
              self
                .map((x) => x.planSuperiorId)
                .indexOf(value.planSuperiorId) === index
          );

          distinctGroupPlan.forEach((groupPlan) => {
            // Criando GymUnitGroupPlan e Plans
            gymUnitGroupPlan = {
              shortDescription: groupPlan.gymUnitGroupPlanShortDescription,
              longDescription: groupPlan.gymUnitGroupPlanLongDescription,
              availableActivities:
                groupPlan.gymUnitGroupPlanAvailableActivities,
              group: null,
              gymUnit: gym,
              portalName: groupPlan.gymUnitGroupPlanPortalName,
              plan: {
                id: groupPlan.planSuperiorId,
                idOriginal: groupPlan.planId,
                slug: groupPlan.planSlug,
                shortName: groupPlan.planShortName,
                longName: groupPlan.planLongName,
                circuit: groupPlan.planCircuit,
                showChannels: groupPlan.planShowChannels,
                debitedMonthly: groupPlan.planDebitedMonthly,
                planExclusive: groupPlan.planGymUnitExclusive,
                gymUnitPeriodicities: [],
              },
            };

            // Criando Periodicities.
            gymUnitPeriodicities = {};

            saleGroupPlan
              .filter(
                (value) => value.planSuperiorId === groupPlan.planSuperiorId
              )
              .forEach(
                (peri) => (gymUnitPeriodicities[peri.periodicityName] = peri)
              );

            periodicity.forEach((_periodicity) => {
              const periodicityLegacy =
                gymUnitPeriodicities[_periodicity.periodicityName];

              if (periodicityLegacy && periodicityLegacy.planForSale) {
                gymUnitPeriodicity = {
                  planValue: periodicityLegacy.gymUnitPeriodicityPlanValue,
                  couponId: periodicityLegacy.gymUnitPeriodicityCouponId,
                  popular: periodicityLegacy.gymUnitPeriodicityPopular,
                  status: GymPeriodicityStatusEnum.DISPONIVEL,
                  planSlug: periodicityLegacy.planSlug,
                  subscriptionFee:
                    periodicityLegacy.gymUnitPeriodicitySubscriptionFee,
                  subscriptionFeeText:
                    periodicityLegacy.gymUnitPeriodicitySubscriptionFeeText,
                  showChannelSubscriptionFee:
                    periodicityLegacy.gymUnitPeriodicityShowChannelSubscriptionFee,
                  registration:
                    periodicityLegacy.gymUnitPeriodicityRegistration,
                  registrationText:
                    periodicityLegacy.gymUnitPeriodicityRegistrationText,
                  showChannelRegistration:
                    periodicityLegacy.gymUnitPeriodicityShowChannelRegistration,
                  periodicity: {
                    id: periodicityLegacy.gymUnitPeriodicityId,
                    name: periodicityLegacy.periodicityName,
                    days: periodicityLegacy.periodicityDays,
                    months: periodicityLegacy.periodicityMonths,
                    slug: periodicityLegacy.periodicitySlug,
                    debitedMonthly: periodicityLegacy.planDebitedMonthly,
                    isAnual: periodicityLegacy.periodicityMonths === 12,
                  },
                };
              } else {
                // Caso nao haja a periodicidade, criar uma fake
                gymUnitPeriodicity = {
                  status: GymPeriodicityStatusEnum.INDISPONIVEL,
                  periodicity: {
                    name: _periodicity.periodicityName,
                    months: _periodicity.periodicityMonths,
                    debitedMonthly: _periodicity.planDebitedMonthly,
                  },
                };
              }

              gymUnitGroupPlan.plan.gymUnitPeriodicities.push(
                gymUnitPeriodicity
              );
            });

            listGymUnitGroupPlan.push(gymUnitGroupPlan);
          });

          return listGymUnitGroupPlan;
        })
      );
  }

  getRegistrationIdsFromSlug(
    gymUnitSlug: string,
    planSlug: string,
    periodicitySlug: string
  ) {
    return this.apollo
      .query({
        query: AppGraphql.queryGetPlanFromView,
        variables: { gymUnitSlug, planSlug, periodicitySlug },
      })
      .pipe(map(JSONUtil.turnApolloMutable<any>()));
  }

  getCustomerPlans(): Observable<any> {
    return this.http.get(back(BackEndpoints.CustomerPlans));
  }

  getCustomerActivePlans(): Observable<any> {
    return this.http
      .get(back(BackEndpoints.CustomerPlans))
      .pipe(
        map((result: any) =>
          result.customerPlans.filter(
            (plan: any) => plan.itemStatus === 'Ativo' && plan.type === 'Plano'
          )
        )
      );
  }
}
