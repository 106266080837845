import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AgeRangeResolveGuard } from '@guards/age-range-resolve.guard';
import { BrandGuard } from '@guards/brand.guard';
import { GymActivitiesResolveGuard } from '@guards/gym-activities-resolve.guard';
import { GymGroupActivitiesResolveGuard } from '@guards/gym-group-activities-resolve.guard';
import { GymNameWithKidsListResolveGuard } from '@guards/gym-name-with-kids-list-resolve.guard';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';
import { KidsComponent } from './kids.component';

const kidsRoute: Route[] = [
  {
    path: '',
    children: [
      { path: ':id', component: KidsComponent },
      {
        path: '',
        pathMatch: 'full',
        component: KidsComponent,
      },
    ],
    resolve: {
      imageHeader: ImageHeaderResolve,
      config: PortalConfigResolveGuard,
      ages: AgeRangeResolveGuard,
      gyms: GymNameWithKidsListResolveGuard,
      activities: GymActivitiesResolveGuard,
      groups: GymGroupActivitiesResolveGuard,
    },
    canActivate: [BrandGuard],
    data: {
      windowTitle: '${data.imageHeader.title}',
      windowContext: 'kids',
      slug: 'kids',
      brand: 'bt',
      config: {
        activitiesModal: 'modal-activities',
        agenda: 'agenda',
        agendaFilter: 'agenda-filter',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(kidsRoute)],
  exports: [RouterModule],
})
export class KidsRoutingModule {}
