import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  AlertMessage,
  City,
  DataAddress,
  MessageMap,
  SelectItem,
  State,
} from '@models';
import { AlertMessageService } from '@services';
import { AddressService } from '@services/address.service';
import { UserProfileService } from '@services/user-profile.service';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-data-address',
  templateUrl: './data-address.component.html',
  styleUrls: ['./data-address.component.scss'],
})
export class DataAddressComponent implements OnInit {
  @ViewChild('formAddress', { static: true }) form: NgForm;
  @ViewChild('number', { read: ElementRef }) numberInput: ElementRef;

  @Input() name = '';
  @Input() address: DataAddress = {} as DataAddress;
  @Input() showTitle = true;
  @Input() showStateAndCity = true;

  @Output() afterOnInit = new EventEmitter<void>();

  zipMask = AppConstants.Mask.zip;
  cities: SelectItem<City>[] = [];
  states: SelectItem<State>[] = [];
  _loadingCities = false;
  state: number = null;
  city: number = null;

  private _afterLoadCities = () => {};

  constructor(
    private readonly addressService: AddressService,
    private readonly alertMessageService: AlertMessageService,
    private readonly profileService: UserProfileService
  ) {}

  ngOnInit() {
    this.addressService.getStates().subscribe((data) => {
      this.states = data.map((state) =>
        this.addressService.stateToSelect(state)
      );
      this.initAddress(this.address);
      this.afterOnInit.next();
    });
  }

  initAddress(address: DataAddress) {
    // Trata caso que dados de usuário tem uma cidade válida mas estado inválido
    if (address.state) {
      if (!address.state.id && address.city.id) {
        this.addressService
          .getStateByCityId(address.city.id)
          .subscribe((response) => {
            this.updateCityIdAfterLoad(address.city.id);
            this.state = response.state.id;
            this.selectState(response.state.id);
            delete address.state;
          });
      } else {
        this.updateCityIdAfterLoad(address.city.id);
        this.state = address.state.id;
        this.selectState(address.state.id);
        delete address.state;
      }
    }
  }

  selectState(state: number) {
    if (this._loadingCities || !state) {
      return;
    }
    this._loadingCities = true;
    this.cities = [];

    this.addressService.getCitiesInState(state).subscribe((data) => {
      this.cities = data.map(this.addressService.cityToSelect);
      this._afterLoadCities();
      this._loadingCities = false;
    });
  }

  keydownEnter($event: any) {
    $event.stopImmediatePropagation();
    $event.stopPropagation();
    this.searchZip(this.address.zipCode);
  }

  searchZip(zip: string) {
    this.profileService.searchZip(zip).subscribe((data) => {
      if (!data.erro) {
        this.address.street = data.logradouro || '';
        this.address.number = '';
        this.address.adjunct = '';
        this.address.neighborhood = data.bairro || '';
        const state = this.states.find(
          (stateData) => stateData.initials === data.uf
        );
        this.updateCityIdAfterLoad(data.localidade);
        this.state = state ? state.id : undefined;

        this.selectState(this.state);
        setTimeout(() => this.numberInput.nativeElement.focus(), 0);

        this.isValid();
      } else {
        this.alertMessageService.showToastr(
          AlertMessage.warning(MessageMap.CEP_NAO_ENCONTRADO),
          [zip]
        );
      }
    });
  }

  private _afterLoadCitiesFn(cityData: any) {
    return () => {
      setTimeout(() => {
        if (typeof cityData === 'number') {
          const city: SelectItem<City> = this.cities.find(
            (s) => s.id === cityData
          );
          this.city = city ? city.id : undefined;
          this._afterLoadCities = () => {};
        } else {
          const city: SelectItem<City> = this.cities.find(
            (s) => s.name === cityData
          );
          this.city = city ? city.id : undefined;
          this._afterLoadCities = () => {};
        }
      }, 0);
    };
  }

  updateCityIdAfterLoad(city: any) {
    this._afterLoadCities = this._afterLoadCitiesFn(city);
  }

  isValid(): boolean {
    FormUtil.touchForm(this.form.control);
    return this.form.valid;
  }
}
