import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ModalGymPlanModule } from '@sharedcomponents/modal/modal-gym-plan/modal-gym-plan.module';
import { EmptyCardModule } from '../empty-card/empty-card.module';
import { MembershipModule } from './membership/membership.module';
import { MembershipsComponent } from './memberships.component';

@NgModule({
  declarations: [MembershipsComponent],
  imports: [
    CommonModule,
    EmptyCardModule,
    MembershipModule,
    ModalGymPlanModule,
    SwiperModule,
  ],
  exports: [MembershipsComponent],
})
export class MembershipsModule {}
