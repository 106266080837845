import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  AlertMessage,
  CollaboratorsDepartmentsResponse,
  CollaboratorsRange,
  CorporateFormRequest,
  Department,
  Gym,
  MessageMap,
  SelectItem,
  State,
} from '@models';
import { AlertMessageService, AnalyticsService } from '@services';
import { AddressService } from '@services/address.service';
import { CorporateService } from '@services/corporate.service';
import { GymService } from '@services/gym.service';
import { LoadingService } from '@services/loading.service';
import { RecaptchaComponent } from '@sharedcomponents/recaptcha/recaptcha.component';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-corporate-form',
  templateUrl: './corporate-form.component.html',
  styleUrls: ['./corporate-form.component.scss'],
})
export class CorporateFormComponent implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('recaptcha', { static: false }) recaptcha: RecaptchaComponent;

  @Input() info: CorporateFormRequest = {
    contact: {},
    company: {},
    responsible: {},
  } as CorporateFormRequest;

  @Output() formSent = new EventEmitter<boolean>();

  readonly phoneMask = AppConstants.Mask.phone;
  readonly cnpjMask = AppConstants.Mask.cnpj;
  readonly emailRegexp = AppConstants.EMAIL_REGEXP;

  _loadingDeptsCollabs = true;
  _loadingStates = true;

  departments: SelectItem<Department>[] = [];
  employees: SelectItem<CollaboratorsRange>[] = [];
  states: SelectItem<State>[] = [];
  gyms: SelectItem<Gym>[] = [];
  acceptTerms = false;

  private gymList: { [stateId: number]: Gym[] } = {};

  constructor(
    private readonly addressService: AddressService,
    private readonly corporateService: CorporateService,
    private readonly gymService: GymService,
    private readonly loadingService: LoadingService,
    private readonly analyticsService: AnalyticsService,
    private readonly alertMessageService: AlertMessageService
  ) {}

  ngOnInit() {
    this.gymService.getGymNamesWithState().subscribe((data: Gym[]) => {
      this.initializeStateGyms(data);
    });

    this.corporateService
      .getCollaboratorsAndDepartments()
      .subscribe((data) => this.initializeCollabDept(data));
  }

  initializeStateGyms(data: Gym[]) {
    const stateList: { [stateId: number]: State } = {};

    for (const gym of data) {
      const stateId = gym.address ? gym.address.city.state.id : null;

      if (!stateId) {
        continue;
      }
      if (!(stateId in stateList)) {
        stateList[stateId] = gym.address.city.state;
      }
      if (!(stateId in this.gymList)) {
        this.gymList[stateId] = [];
      }

      this.gymList[stateId].push(gym);
    }

    const statesWithGyms = Object.keys(stateList).map((s) =>
      this.addressService.stateToSelect(stateList[s])
    );
    this.states = statesWithGyms;

    this._loadingStates = false;
  }

  initializeCollabDept(data: CollaboratorsDepartmentsResponse) {
    const collaboratorRanges = Object.keys(
      data.listCollaboratorQuantitiesRedis
    ).map((c) =>
      this.corporateService.collaboratorToSelect(
        data.listCollaboratorQuantitiesRedis[c]
      )
    );

    this.employees = collaboratorRanges;

    const departments = [];
    for (const dept of data.listDepartmentsRedis) {
      departments.push(this.corporateService.departmentToSelect(dept));
    }
    this.departments = departments;

    this._loadingDeptsCollabs = false;
  }

  selectState(state: number) {
    if (!state) {
      return;
    }

    const gymsInState = [];
    for (const gym of this.gymList[state]) {
      gymsInState.push(this.gymService.gymToSelect(gym));
    }
    this.gyms = gymsInState;
  }

  isValid(): boolean {
    FormUtil.touchForm(this.form.control);
    return this.form.valid;
  }

  send() {
    if (this.isValid() && this.recaptcha.isVerified() && this.acceptTerms) {
      this.loadingService.startLoading();
      const data = JSON.parse(JSON.stringify(this.info));
      data.company.companyCnpj = data.company.companyCnpj.replace(/[\D]+/g, '');
      this.corporateService.postCorporateForm(data).subscribe(
        () => {
          this.loadingService.stopLoading();
          this.analyticsService.trackEvent(
            'Envia Formulário',
            'Corporativo',
            'Formulário Corporativo'
          );
          this.formSent.emit(true);
        },
        (error) =>
          this.alertMessageService.showToastr(
            AlertMessage.warning(MessageMap.ERRO_ENVIAR_FORMULARIO),
            [error.error[0].message]
          )
      );
    } else if (!this.isValid()) {
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO)
      );
    } else if (!this.recaptcha.isVerified()) {
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.CAMPO_NAO_SOU_ROBO)
      );
    } else if (!this.acceptTerms) {
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.ACEITAR_TERMO_ADESAO)
      );
    }
  }
}
