export * from './action-bt-item.model';
export * from './address.model';
export * from './alert-message.model';
export * from './auth.model';
export * from './bt-company-header-section.model';
export * from './carousel-frame.model';
export * from './data-address.model';
export * from './docu-sign.model';
export * from './evo.model';
export * from './gym-experience.model';
export * from './gym-search.model';
export * from './gym.model';
export * from './http-cache.model';
export * from './landing-page-kids.model';
export * from './local-storage.model';
export * from './location.model';
export * from './membership.model';
export * from './modal.model';
export * from './old-canais.model';
export * from './press.model';
export * from './price.model';
export * from './registration-locking.model';
export * from './registration.model';
export * from './rxjs-operators.model';
export * from './select-item.model';
export * from './simple-link.model';
export * from './state-gym-filter.model';
export * from './tab.model';
export * from './requests/auth-request.model';
export * from './requests/corporate-request.model';
export * from './requests/evo-auth-request.model';
export * from './requests/franchise-request.model';
export * from './requests/registration-request.model';
export * from './requests/registration-locking-request.model';
export * from './requests/user-request.model';
export * from './responses/address-response.model';
export * from './responses/agenda-response.model';
export * from './responses/auth-response.model';
export * from './responses/blog-media-response.model';
export * from './responses/blog-post-response.model';
export * from './responses/bt-company-response.model';
export * from './responses/corporate-response.model';
export * from './responses/coupon-response.model';
export * from './responses/customer-response.model';
export * from './responses/evo-auth-response.model';
export * from './responses/faq-covid-response.model';
export * from './responses/faq-response.model';
export * from './responses/footer-response.model';
export * from './responses/franchise-response.model';
export * from './responses/gym-detail-text.model';
export * from './responses/gym-registration-response.model';
export * from './responses/gym-response.model';
export * from './responses/home-response.model';
export * from './responses/my-contract-response.model';
export * from './responses/my-online-class-schedules-response.model';
export * from './responses/my-presale-purchases-response.model';
export * from './responses/my-purchases-response.model';
export * from './responses/my-schedules-response.model';
export * from './responses/press-response.model';
export * from './responses/profile-response.model';
export * from './responses/trainings-response.model';
export * from './responses/whatsapp-response.model';
