import {
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
  Directive,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tab } from '@models';
import { HeaderService } from '@services/header.service';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class TabControllerComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  protected abstract readonly tabs: Tab[];
  public activeTab: Tab;

  constructor(
    protected readonly route: ActivatedRoute,
    protected readonly headerService: HeaderService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap) => {
      const param = paramMap.get('tab');
      this.activeTab = this.tabs.find((tab) => tab.url.endsWith(param));
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
