import { Component, OnInit } from '@angular/core';
import { ModalType } from '@models';
import { ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-registration-locking',
  templateUrl: './modal-registration-locking.component.html',
  styleUrls: ['./modal-registration-locking.component.scss'],
})
export class ModalRegistrationLockingComponent
  extends ModalCommonComponent
  implements OnInit {
  cancelReason = '';
  error = false;

  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.registrationLocking;
  }

  confirm() {
    if (this.cancelReason.length >= 10 && this.cancelReason.length <= 200) {
      this.error = false;
      this.modalObj.data = {
        status: true,
        message: this.cancelReason,
      };
      super.confirm();
    }

    this.error = true;
  }
}
