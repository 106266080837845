import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-agenda-header',
  templateUrl: './agenda-header.component.html',
  styleUrls: ['./agenda-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgendaHeaderComponent {
  @Input() today = '0';
  @Input() highlight: number;
  @Input() left = 0;
  @Input() weekstart = 1;
  @Input() days: string[];

  @Output() leftChange = new EventEmitter<number>();

  onScroll(value: number) {
    this.left = value;
    this.leftChange.emit(value);
  }
}
