import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalBtpassPassInfoObject, ModalType } from '@models';
import { CapitalizePipe } from '@pipes';
import { LandingKidsService, LoadingService, ModalService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-btpass-pass-info',
  templateUrl: './modal-btpass-pass-info.component.html',
  styleUrls: ['./modal-btpass-pass-info.component.scss'],
  providers: [CapitalizePipe],
})
export class ModalBtpassPassInfoComponent
  extends ModalCommonComponent
  implements OnInit {
  locale = AppConstants.LOCALE;
  modalObj: ModalBtpassPassInfoObject;
  selectedQuantity = 1;

  constructor(
    protected modalService: ModalService,
    protected formBuilder: FormBuilder,
    protected kidsService: LandingKidsService,
    protected loadingService: LoadingService,
    protected capitalizePipe: CapitalizePipe
  ) {
    super();
    this.type = ModalType.btpassPassInfo;
  }

  confirm() {
    this.modalObj.quantity = this.selectedQuantity;
    const data = {
      gymUnitId: this.modalObj.gymUnitId,
      additionalIncomeId: this.modalObj.income.additionalIncomeId,
      quantity: this.selectedQuantity,
    };
    this.modalObj.data = data;
    super.confirm();
  }

  cancel() {
    super.cancel();
  }

  dismiss() {
    super.dismiss();
  }

  changeQuantityOfProducts(quantity: number) {
    this.selectedQuantity = quantity;
  }
}
