import { Component, Input } from '@angular/core';
import { FooterPartner } from '@models';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'app-footer-partners',
  templateUrl: './footer-partners.component.html',
  styleUrls: ['./footer-partners.component.scss'],
})
export class FooterPartnersComponent {
  @Input() data: FooterPartner[];
  @Input() title: string;

  public loadImage = false;

  constructor(private readonly analyticsService: AnalyticsService) {}

  clickPartner(index: number) {
    this.analyticsService.trackEvent(
      `Parceiro - ${this.data[index].name}`,
      'Footer',
      `Parceiro`
    );
  }
}
