import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AgeRangeResolveGuard,
  GymActivitiesResolveGuard,
  GymGroupActivitiesResolveGuard,
  PortalConfigResolveGuard,
} from '@guards';
import { GymComponent } from './gym.component';

const routes: Routes = [
  {
    path: 'agenda',
    redirectTo: '/agenda/:slug',
  },
  {
    path: '',
    component: GymComponent,
    children: [
      {
        path: 'matricule-se',
      },
    ],
    resolve: {
      activities: GymActivitiesResolveGuard,
      ages: AgeRangeResolveGuard,
      config: PortalConfigResolveGuard,
      groups: GymGroupActivitiesResolveGuard,
    },
    data: {
      addSuffix: false,
      config: {
        activitiesModal: 'modal-activities',
        agendaFilter: 'agenda-filter',
        gymDetailText: 'gym-detail',
        gymSearch: 'gym-search',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GymRoutingModule {}
