import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AlertMessage,
  MessageMap,
  ModalType,
  NewAccessForm,
  NewAccessFormControls,
  ValidateCpfResponse,
} from '@models';
import {
  AlertMessageService,
  AuthService,
  LoadingService,
  ModalService,
} from '@services';
import { BrandService } from '@services/brand.service';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-create-account-cpf',
  templateUrl: './create-account-cpf.component.html',
  styleUrls: ['../../../area-user.component.scss'],
})
export class CreateAccountCpfComponent {
  @ViewChild('form', { static: false }) form: NgForm;

  @Input() formControls: NewAccessFormControls;
  @Input() userForm: NewAccessForm;
  @Input() validateCpfResponse: ValidateCpfResponse;

  readonly cpfMask = AppConstants.Mask.doc;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly modalService: ModalService,
    private readonly alertMessageService: AlertMessageService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly router: Router
  ) {}

  noCPF() {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'NÃO TEM CPF?',
      message: `Atualmente a matrícula online no site da ${this.brandService.fullBrandName}
                somente é possível para maiores de 18 anos,
        mediante o uso de seu próprio número de CPF.
        Caso você seja menor de idade ou não possua um número de CPF,
        <strong class="font-weight-medium">pedimos que compareça até a academia em que deseja se matricular.</strong><br><br>
        Registering at ${this.brandService.fullBrandName} requires a brazillian identification number
        called CPF (Cadastro de Pessoa Física or Individual Taxpayers\' Register).
        If you don\'t have this document, you can still register
        but you will have to go to one of our clubs in order to do so.`,
    });
  }

  validateCPF() {
    this.loadingService.startLoading();

    this.authService.validateCpf(this.userForm.cpf).subscribe(
      (response) => {
        this.userForm.oldEmail = response['EmailPrincipal'];
        Object.entries(response).forEach(
          (v) => (this.validateCpfResponse[v[0]] = v[1])
        );

        this.formControls.currentStep++; // Avança à página de email e senha
      },
      (err) => this.alertMessageService.showToastr(err),
      () => this.loadingService.stopLoading()
    );
  }

  goBack() {
    this.router.navigate(['/cliente/login']); // Navega à página de login
  }

  send() {
    FormUtil.touchForm(this.form.control);

    if (this.form.valid) {
      this.validateCPF();
    } else {
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.NECESSARIO_INFORMAR_CPF)
      );
    }
  }
}
