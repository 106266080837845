import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { SimpleLink } from '@models';
import { HeaderLinksResponse } from '@models/responses/header-links-response.model';
import { AuthService } from '@services/auth.service';
import { BodyClassService } from '@services/body-class.service';
import { BrandService } from '@services/brand.service';
import { BreakpointsService, ScreenSize } from '@services/breakpoints.service';
import { OpenModalPaddingService } from 'app/component-modules/open-modal-padding/open-modal-padding.service';

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
})
export class NavbarTopComponent implements OnInit, OnDestroy {
  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  /** Define when to show navbar background. */
  @Input() showBackground = true;
  /** Define links to render in classic horizontal navbar. */
  @Input() navbarItems: SimpleLink[] = [];
  /** Define links to render in lateral collapse navbar. */
  @Input() navbarOpenItems: HeaderLinksResponse;
  /** Define links to render in logged dropdown and lateral collapse navbar. */
  @Input() navbarItemsLogged: SimpleLink[] = [];
  @Input() menuOpenImagePath: string;
  @Input() menuCloseImagePath: string;

  private _navbarExpanded = false;

  get navbarExpanded() {
    return this._navbarExpanded;
  }
  set navbarExpanded(val: boolean) {
    this._navbarExpanded = val;
    this.navbarCollapseHandler();
  }

  private subsBreakpoints: Subscription;
  private subs: Subscription;

  isUserLogged = false;
  userAvatar = '';
  brandNameLowerCase = '';
  expanded = false;
  screenLG = false;
  bodyPaddingRight: string;

  constructor(
    public readonly authService: AuthService,
    public readonly breakpointsService: BreakpointsService,
    private readonly brandService: BrandService,
    private readonly bodyClassService: BodyClassService,
    private readonly bsModalService: BsModalService,
    private readonly openModalPadding: OpenModalPaddingService,
    private toastrService: ToastrService
  ) {
    this.subsBreakpoints = this.breakpointsService.emitter.subscribe((val) => {
      this.screenLG = val >= ScreenSize.lg;
    });
  }

  ngOnInit() {
    this.toastrService.overlayContainer = this.toastContainer;
    this.brandNameLowerCase = this.brandService
      .getFullBrandName()
      .toLowerCase();
    this.isUserLogged = this.authService.checkAuth();
    this.subs = this.authService
      .getListener()
      .subscribe((data) => (this.isUserLogged = data));

    this.openModalPadding.emitter.pipe(first()).subscribe((padding) => {
      this.bodyPaddingRight = padding;
    });

    const user = this.authService.getUser();
    if (user) {
      this.userAvatar = user.avatar;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.subsBreakpoints.unsubscribe();

    this.isUserLogged = false;
  }

  logout() {
    this.authService.logout();
  }

  onExpandedChange(val: boolean) {
    if (!this.screenLG) {
      this.navbarExpanded = val;
    }
  }

  navbarCollapseHandler() {
    if (this.navbarExpanded) {
      this.hideBodyScrollbar();
    } else {
      this.resetBodyScrollbar();
    }
  }

  /**
   * This function change `navbarExpanded` value. This function is a way around to a bug that
   * could happen if you apply a `(click)` in a `a` element and change a value inline instead calling a function, like the example.
   *
   * ```html
   *    <a
   *      href="www.test.com"
   *      target="_blank"
   *      (click)="exampleVariable = 'change your value'"
   *    >
   *      Link
   *    </a>
   * ```
   *
   * When something like the example above is done, the event binding `(click)` will rewrite the `href` property and **won't open a link**.
   */
  setNavbarExpanded(newValue: boolean) {
    this.navbarExpanded = newValue;
  }

  private hideBodyScrollbar() {
    this.bsModalService.checkScrollbar();
    this.bsModalService.setScrollbar();

    this.bodyClassService.setClass('overflow-hidden');
  }

  private resetBodyScrollbar() {
    document.body.style.paddingRight = this.bodyPaddingRight;
    this.bodyClassService.removeClass('overflow-hidden');
  }
}
