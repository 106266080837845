import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessage, UpdatePasswordRequest, MessageMap } from '@models';
import {
  AlertMessageService,
  HeaderService,
  LoadingService,
  UserService,
  AuthService,
} from '@services';
import { BasicHeaderConfig } from '@models/configs';
import { BrandService } from '@services/brand.service';

@Component({
  templateUrl: './new-password.component.html',
  styleUrls: ['../../area-user.component.scss'],
})
export class NewPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  public config: BasicHeaderConfig;

  passwordRequest: UpdatePasswordRequest = {
    password: '',
    confirm: '',
    token: '',
  };
  success = false;

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly route: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly headerService: HeaderService,
    private readonly loadingService: LoadingService,
    private readonly authService: AuthService,
    private readonly alertMessageService: AlertMessageService,
    private readonly userService: UserService
  ) {
    this.config = this.activatedRoute.snapshot.data.config.config;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((param) => {
      this.passwordRequest.token = param['key'];

      if (!this.passwordRequest.token) {
        this.alertMessageService.showToastr(
          AlertMessage.error(MessageMap.TOKEN_NAO_ENCONTRADO)
        );
        this.route.navigate(['/']);
      }

      this.authService.logoutAndStay();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  requestNewPassword() {
    if (this.form.invalid) {
      return;
    }

    this.loadingService.startLoading();

    this.userService.updateUserPassword(this.passwordRequest).subscribe(
      () => (this.success = true),
      (err: any) => this.alertMessageService.showToastr(err),
      () => this.loadingService.stopLoading()
    );
  }
}
