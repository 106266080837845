import { Component } from '@angular/core';

@Component({
  selector: 'app-debug-guide-lines',
  templateUrl: './debug-guide-lines.component.html',
  styleUrls: ['./debug-guide-lines.component.scss'],
})
export class DebugGuideLinesComponent {
  lines = Array(13).fill(0);
}
