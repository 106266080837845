import { filter, map } from 'rxjs/operators';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class WindowContextService {
  previousContext = '';

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  public init() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route.snapshot;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        if (
          route.component &&
          route.data.windowContext !== this.previousContext
        ) {
          window.scrollTo(0, 0);
          this.previousContext = route.data.windowContext;
        }
      });
  }
}
