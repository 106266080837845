import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { OpenModalPaddingService } from './open-modal-padding.service';

@Directive({
  selector: '[appOpenModalPadding]',
})
export class OpenModalPaddingDirective implements OnDestroy {
  @Input() appOpenModalPadding: string;

  private subs: Subscription;
  private initialValue: string;

  constructor(
    private readonly elem: ElementRef,
    private readonly renderer: Renderer2,
    private readonly openModalPaddingService: OpenModalPaddingService
  ) {
    this.initialValue = window.getComputedStyle(
      this.elem.nativeElement
    ).paddingRight;
    this.subs = this.openModalPaddingService.emitter.subscribe((padding) => {
      this.renderer.setStyle(
        this.elem.nativeElement,
        this.appOpenModalPadding || 'padding-right',
        padding
      );
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.renderer.setStyle(
      this.elem.nativeElement,
      this.appOpenModalPadding || 'padding-right',
      this.initialValue
    );
  }
}
