import { Pipe, PipeTransform } from '@angular/core';
import { StringUtil } from '@utils/string-util';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], orderField: string, orderType: boolean): any[] {
    array.sort((a: any, b: any) => {
      const ae = a[orderField];
      const be = b[orderField];

      return StringUtil.strcmp(ae, be);
    });

    if (!orderType) {
      array.reverse();
    }

    return array;
  }
}
