import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@models';

@Injectable()
export class GeolocationService {
  private readonly options: PositionOptions = {
    enableHighAccuracy: true,
    maximumAge: 600000,
  };

  getCurrentGeolocation(): Observable<Location> {
    return new Observable<Location>((observer) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) =>
          observer.next({
            latitude: position.coords.latitude.toString(),
            longitude: position.coords.longitude.toString(),
          }),
        (_) => observer.next(null),
        this.options
      );
    });
  }
}
