import { GymExperienceTabs } from '@utils/gym-util';
import { RequestObject } from '@utils/request-object.class';

export interface EvoActivitiesResponseModel {
  activityGroup: string;
  idActivity: number;
  idActivityGroup: number;
  name: string;
  photo: string;
  color?: string;
  description?: string;
  inactive?: boolean;
  totalRecords?: number;
  showOnWebsite?: boolean;
}

export interface GymExperienceBaseModel {
  description: string;
  name: string;
}

export interface ActivityGroupModel {
  groupName: string;
  activities: ActivityModel[];
}

export interface ActivityModel extends GymExperienceBaseModel {
  inactive?: boolean;
  legacyId?: number;
  showOnWebsite?: boolean;
}

export interface ExperienceContentModel extends GymExperienceBaseModel {
  icon: string;
  isFree?: boolean;
}

export interface ExperienceResquestsObjectsModel {
  [GymExperienceTabs.Activities]: RequestObject<ActivityGroupModel[]>;
  [GymExperienceTabs.Services]: ExperienceContentModel[];
  [GymExperienceTabs.Conveniences]: ExperienceContentModel[];
}
