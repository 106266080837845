import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgendaAgeRange } from '@models';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';

@Injectable()
export class AgeRangeService {
  constructor(private readonly apollo: Apollo) {}

  getAgeRangeList(): Observable<AgendaAgeRange[]> {
    return this.apollo
      .query<any>({
        query: AppGraphql.queryListActiveAgeRanges,
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<AgendaAgeRange[]>('listActiveAgeRanges'))
      );
  }
}
