import { Pipe, PipeTransform } from '@angular/core';
import { GymActivity } from '@models';

@Pipe({
  name: 'filterActivity',
})
export class FilterActivityPipe implements PipeTransform {
  transform(value: GymActivity[], arg: number, popular = false): GymActivity[] {
    if (!value || !value.length) {
      return value;
    }

    return value.filter(
      (act) => act.activityGroup.id === arg && (!popular || act.popular)
    );
  }
}
