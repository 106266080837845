import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FuseModule } from '@pipes/fuse/fuse.module';
import { ModalSingleSelectComponent } from './modal-single-select.component';

@NgModule({
  declarations: [ModalSingleSelectComponent],
  imports: [CommonModule, FormsModule, ModalModule, FuseModule],
  exports: [ModalSingleSelectComponent],
})
export class ModalSingleSelectModule {}
