import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GymActivity, ModalGymActivityObject, ModalType, Tab } from '@models';
import { ActivitiesModalConfig } from '@models/configs';
import { FilterActivityPipe } from '@pipes';
import { AnalyticsService, ModalService } from '@services';
import { StringUtil } from '@utils/string-util';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-gym-activity',
  templateUrl: './modal-gym-activity.component.html',
  styleUrls: ['./modal-gym-activity.component.scss'],
  providers: [FilterActivityPipe],
})
export class ModalGymActivityComponent
  extends ModalCommonComponent<ActivitiesModalConfig>
  implements OnInit {
  @ViewChild('modalBody', { static: false }) modalBody: ElementRef;

  @Input() analyticsCategory: string;
  @Input() analyticsTag: string;

  modalObj: ModalGymActivityObject;
  activities: GymActivity[];
  search = '';
  tabs: Tab[] = [];
  activeTab: Tab;

  public get activeTabAllChecked(): boolean {
    return this.filterActivityPipe
      .transform(this.activities, this.activeTab.id)
      .every((activity) => activity.selected);
  }

  public set activeTabAllChecked(check: boolean) {
    this.filterActivityPipe
      .transform(this.activities, this.activeTab.id)
      .forEach((activity) => (activity.selected = check));
  }

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly filterActivityPipe: FilterActivityPipe,
    protected modalService: ModalService
  ) {
    super();
    this.type = ModalType.gymActivity;
  }

  init() {
    this.tabs = (this.modalObj.groups || []).filter(
      (a) => a.name.toLocaleLowerCase() !== 'atividade base'
    );
    this.activeTab = this.tabs.includes(this.activeTab)
      ? this.activeTab
      : this.tabs[0];
    this.activities = this.modalObj.activities || [];
  }

  getTabFilteredActivities(tabId: number) {
    return this.activities.filter(
      (activity) =>
        StringUtil.compareInsensitive(activity.name, this.search) &&
        activity.activityGroup.id === tabId
    );
  }

  confirm() {
    this.analyticsEvent();

    this.modalObj.data = this.activities.filter(
      (activity) => activity.selected
    );
    super.confirm();
  }

  analyticsEvent() {
    // Filtra somenta as atividades selecionadas
    const selectedActivites = this.activities.filter((value) => value.selected);

    const st = this.tabs
      // Filtra somente as tabs que tem pelo menos uma atividade selecionada
      .filter((tab) =>
        selectedActivites.some(
          (activity) => activity.activityGroup.id === tab.id
        )
      )
      .map((tab) => {
        // Conta quantos atividades do tipo da 'tab' atual foram selecionadas
        const count = selectedActivites.filter(
          (activity) => activity.activityGroup.id === tab.id
        ).length;
        return `${tab.name}: ${count}`;
      })
      .join(', ');

    if (st) {
      this.analyticsService.trackEvent(
        `Atividades (${st})`,
        this.analyticsCategory,
        this.analyticsTag
      );
    }
  }

  scrollListToStart() {
    this.modalBody.nativeElement.scrollTop = 0;
  }
}
