import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appClickable]',
})
export class ClickableDirective implements OnInit {
  @Input() appClickable = true;

  constructor(
    private readonly elem: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit() {
    if (this.appClickable !== false) {
      this.renderer.setStyle(this.elem.nativeElement, 'cursor', 'pointer');
    }
  }
}
