import { Component, Input } from '@angular/core';
import { FooterApp } from '@models';

@Component({
  selector: 'app-footer-get-app',
  templateUrl: './footer-get-app.component.html',
  styleUrls: ['./footer-get-app.component.scss'],
})
export class FooterGetAppComponent {
  @Input() data: FooterApp;
}
