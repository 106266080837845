import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { merge, pipe, Subscription } from 'rxjs';
import { debounceTime, map, mergeMap, tap } from 'rxjs/operators';
import { GymAgendaFilter } from '@models';
import { EvoGeneralService, GymService, HeaderService } from '@services';
import { BrandService } from '@services/brand.service';
import { Notice, NoticeService } from '@services/notice.service';
import { AppConstants } from '@utils/app-constants';
import { FormatUtils } from '@utils/format-utils';
import { AgendaTimelineComponent } from './agenda-timeline/agenda-timeline.component';

@Component({
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;
  @ViewChild('timeline', { static: false })
  timelineComponent: AgendaTimelineComponent;
  @ViewChild('timelineIE', { static: false })
  timelineIEComponent: AgendaTimelineComponent;

  private subs: Subscription;

  filter: GymAgendaFilter = {
    gym: null,
    ages: [],
    activities: [],
  };
  hideAgenda = true;
  imageHeader: string;
  headerTitle: string;

  /* MSIE used to detect old browsers and Trident used to newer ones */
  readonly isIE =
    navigator.userAgent.indexOf('MSIE ') > -1 ||
    navigator.userAgent.indexOf('Trident/') > -1;

  notices: Notice[] = [];
  selectedGymIsMigrated = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly brandService: BrandService,
    private readonly evoGeneralService: EvoGeneralService,
    private readonly gymService: GymService,
    private readonly headerService: HeaderService,
    private readonly noticeService: NoticeService,
    private readonly router: Router
  ) {}

  private getTimeline() {
    return this.isIE ? this.timelineIEComponent : this.timelineComponent;
  }

  ngOnInit() {
    this.imageHeader = this.activatedRoute.snapshot.data.imageHeader.image;
    this.headerTitle = this.activatedRoute.snapshot.data.imageHeader.title;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));

    this.subs = merge(
      this.activatedRoute.paramMap.pipe(this.handleParamMap()),
      this.activatedRoute.queryParamMap
    )
      .pipe(
        tap(() => (this.getTimeline().isLoading = true)),
        debounceTime(50)
      )
      .subscribe(() => {
        this.getTimeline().populateAgenda();

        if (this.filter.gym) {
          this.onFilterChange(false);

          this.noticeService
            .getActiveNoticesByGym(
              this.filter.gym.id,
              this.brandService.getBrand()
            )
            .subscribe((notices) => (this.notices = notices));
        }
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private checkGymMigration(): void {
    this.evoGeneralService
      .checkMigratingGym(this.filter.gym.id)
      .subscribe((response) => {
        this.selectedGymIsMigrated = response.isAlreadyMigrated;
      });
  }

  handleParamMap() {
    return pipe(
      map((params: ParamMap) => params.get('slug')),
      mergeMap((slug) => {
        if (!slug) {
          slug = localStorage.getItem(AppConstants.STOR_AGENDA_GYM);

          if (!slug) {
            return this.gymService.getUserMainGym(true);
          }
        }
        return this.gymService.getGymNameBySlug(slug, true);
      }),
      tap((gym) => {
        if (gym && this.filter.gym !== gym) {
          this.filter.gym = gym;
          localStorage.setItem(AppConstants.STOR_AGENDA_GYM, gym.slug);

          const param = FormatUtils.encodeAgendaFilterToParamObject(
            this.filter
          );
          this.router.navigate(['/agenda', this.filter.gym.slug], {
            queryParams: param,
          });
        }
      })
    );
  }

  onFilterChange(isChanged: boolean) {
    this.hideAgenda = isChanged;

    if (!isChanged) {
      this.checkGymMigration();
    }
  }
}
