import { Injectable } from '@angular/core';
import { AlertMessage, AlertMessageType, ToastType } from '@models';
import { ToastrService } from 'ngx-toastr';
import * as Sentry from '@sentry/browser';

@Injectable()
export class AlertMessageService {
  constructor(private toastr: ToastrService) {}

  static genericError(reason: string) {
    const arr = reason.split(' ');
    return {
      code: arr.splice(0, 1).join(' '),
      message: arr.join(' '),
    };
  }

  public extractMessage(err: any): string {
    const bodyError = err.error
      ? err.error.error || err.error.message || err.error[0]
      : null;

    return bodyError || err;
  }

  public showToastr(
    err: AlertMessage | any,
    params?: any[],
    defaultType = AlertMessageType.ERROR
  ) {
    if (err && err instanceof AlertMessage) {
      const message = this.interpolateString(err.msg, params);
      this.toastr.show(
        message,
        'Atenção',
        {
          progressAnimation: 'decreasing',
        },
        ToastType[err.type]
      );
    } else {
      const mappedError = this.extractMessage(err);
      Sentry.captureMessage(`${mappedError}: ${err.url}`);
      this.toastr.show(
        mappedError,
        'Atenção',
        {
          progressAnimation: 'decreasing',
        },
        ToastType[defaultType]
      );
    }
  }

  private interpolateString(schema: string, params?: any[] | object): string {
    let path = schema;
    if (params) {
      for (const i in params) {
        if (params.hasOwnProperty(i)) {
          const param = params[i];
          let value = '';
          if (param !== null && param !== undefined) {
            value = param.toString();
          }
          path = path.split(`{{${i}}}`).join(value);
        }
      }
    }
    return path;
  }
}
