import { Component, OnInit } from '@angular/core';
import { ModalType } from '@models';
import { ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
})
export class ModalAlertComponent
  extends ModalCommonComponent
  implements OnInit {
  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.alert;
  }
}
