import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModalGymBenefitComponent } from './modal-gym-benefit.component';

@NgModule({
  declarations: [ModalGymBenefitComponent],
  imports: [CommonModule, ModalModule],
  exports: [ModalGymBenefitComponent],
})
export class ModalGymBenefitModule {}
