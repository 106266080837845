<ng-template #header>
  <app-header-static-image
    pageTitle="Login"
    [prefixBucket]="false"
    imageUrl="assets/headers/user_area.jpg"
  ></app-header-static-image>
</ng-template>
<div class="main-wrapper container justify-content-around d-flex text-center">
  <div class="card small-card row-on-xs no-side-border-xs">
    <div class="form-container text-left">
      <div class="title-section text-black">Redirecionando</div>
    </div>
  </div>
</div>
