import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  GymsToFilter,
  ModalGymSelectByCityObject,
  ModalObject,
  ModalSingleSelectObject,
  ModalType,
} from '@models';
import {
  GymsByCitiesOfState,
  GymToSelect,
  StateGymFilter,
} from '@models/bt-pass.model';
import { ModalService } from '@services';
import { StringUtil } from '@utils/string-util';

@Component({
  selector: 'app-state-gym-filter',
  templateUrl: './state-gym-filter.component.html',
  styleUrls: ['./state-gym-filter.component.scss'],
})
export class StateGymFilterComponent implements OnInit {
  @Input() title: string;
  @Input() titleClasses?: string;
  @Input() gymsToFilter: GymsToFilter[];
  /** Define if filter exist. */
  @Input() isGymFilter: boolean;
  @Input() isPlaceFilter: boolean;
  /** List with gyms for gym filter. */
  @Input() analyticsCategory?: string;
  @Input() analyticsTag?: string;

  @Output() filteredGyms: EventEmitter<GymsToFilter[]> = new EventEmitter();

  selectedCity = '';
  selectedState = '';
  selectedGyms: GymToSelect[];

  private allCities: string[];
  private allStates: string[];
  private gymsByState: GymsByCitiesOfState[];
  private filteredCities: string[] = [];
  private gymsList: StateGymFilter[];

  constructor(private readonly modalService: ModalService) {}

  ngOnInit(): void {
    this.getStatesAndCities();
    this.groupGymsByState();
    this.getGymsFilter();

    this.filteredCities = this.allCities;
  }

  getStatesAndCities(): void {
    const uniqueCities = new Set<string>();
    const uniqueStates = new Set<string>();

    this.gymsToFilter.forEach((item) => {
      uniqueCities.add(item.city);
      uniqueStates.add(item.state);
    });

    this.allCities = Array.from(uniqueCities).sort(StringUtil.strcmp);
    this.allStates = Array.from(uniqueStates).sort(StringUtil.strcmp);
  }

  getGymsFilter() {
    this.gymsList = this.gymsToFilter.map((gym) => {
      return {
        id: gym.gym.gymUnitId,
        portalName: gym.gymName,
        city: gym.city,
        state: gym.state,
      };
    });

    this.gymsList.sort((a, b) => StringUtil.strcmp(a.portalName, b.portalName));
  }

  groupGymsByState(): void {
    const groupedByState = Object.values(this.gymsToFilter.groupBy('state'));
    const mapGyms = (gym: GymsToFilter) => ({ ...gym.gym, selected: false });

    this.gymsByState = groupedByState.map((gyms) => ({
      stateName: gyms[0].state,
      cities: Object.keys(gyms.groupBy('city')),
      gyms: gyms.map(mapGyms),
    }));
  }

  // Filters functions
  filterCorporateData(): void {
    if (
      this.isPlaceFilter &&
      !this.selectedState &&
      !this.selectedCity &&
      !this.selectedGyms?.length
    ) {
      this.filteredGyms.emit(this.gymsToFilter);

      return;
    }

    this.filteredGyms.emit(
      this.gymsToFilter.filter((gym) => {
        if (this.selectedGyms?.length) {
          return !!this.selectedGyms.find(
            (item) => item.gymUnitId === gym.gym.gymUnitId
          );
        }

        if (this.selectedCity) {
          return gym.city === this.selectedCity;
        }

        if (this.selectedState) {
          return gym.state === this.selectedState;
        }
      })
    );
  }

  filterCities(): string[] {
    const temp: string[] = [];

    if (this.selectedState.length > 0) {
      this.gymsToFilter.forEach((item) => {
        if (item.state === this.selectedState) {
          temp.push(item.city);
        }
      });
      return temp.filter(
        (value, index, array) => array.indexOf(value) === index
      );
    }

    return this.allCities;
  }

  // Modals functions
  showGymByCitiesOfStateModal(): void {
    const modal: ModalObject<ModalGymSelectByCityObject> = {
      type: ModalType.gymSelectByCity,
      title: 'Selecione a(s) academia(s)',
      message: '',
      confirmCallback: (gyms: GymToSelect[]) => {
        if (gyms !== this.selectedGyms) {
          this.selectedGyms = gyms;
        }

        this.filterCorporateData();
      },
      config: {
        maxNumberOfGymSelections: 3,
        gymsByCitiesOfState: this.gymsByState,
        selectedGyms: this.selectedGyms ?? [],
      },
    };

    this.modalService.show(modal);
  }

  showCitiesModal(): void {
    this.modalService.show({
      type: ModalType.singleSelect,
      title: 'Selecione a cidade',
      message: '',
      confirmCallback: (city: string) => {
        this.selectedCity = city;
        this.selectedGyms = [];
      },
      data: this.filteredCities,
      selected: this.selectedCity,
    } as ModalSingleSelectObject);
  }

  showStatesModal(): void {
    this.modalService.show({
      type: ModalType.singleSelect,
      title: 'Selecione o estado',
      message: '',
      confirmCallback: (state: string) => {
        this.selectedState = state;
        this.selectedCity = '';
        this.selectedGyms = [];
        this.filteredCities = this.filterCities();
      },
      data: this.allStates,
      selected: this.selectedState,
    } as ModalSingleSelectObject);
  }

  // Clear filter functions
  clearFilter() {
    this.selectedCity = '';
    this.selectedState = '';
    this.selectedGyms = [];
    this.filteredCities = this.filterCities();
    this.filterCorporateData();
  }

  removeStateFilter = () => {
    this.selectedState = '';
    this.filteredCities = this.filterCities();
  }

  removeCityFilter = () => {
    this.selectedCity = '';
  }

  removeGymFilter = () => {
    this.selectedGyms = [];
  }
}
