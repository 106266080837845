import { Component, OnInit } from '@angular/core';
import {
  ModalType,
  SelectItem,
  ModalPriceRangeObject,
  PriceRange,
} from '@models';
import { ModalService, AnalyticsService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';
import { CurrencyPipe } from '@angular/common';
import { AppConstants } from '@utils/app-constants';
import { PricesModalConfig } from '@models/configs';

@Component({
  selector: 'app-modal-price-range',
  templateUrl: './modal-price-range.component.html',
  styleUrls: ['./modal-price-range.component.scss'],
})
export class ModalPriceRangeComponent
  extends ModalCommonComponent<PricesModalConfig>
  implements OnInit {
  price = [0, 0];
  listStart = [] as SelectItem[];
  listEnd = [] as SelectItem[];
  priceRange: PriceRange = {
    min: 0,
    max: 0,
  };
  modalObj: ModalPriceRangeObject;

  constructor(
    private readonly analyticsService: AnalyticsService,
    protected modalService: ModalService
  ) {
    super();
    this.type = ModalType.priceRange;
  }

  init() {
    this.priceRange = this.modalObj.priceRange;
    this.loadPrice();
  }

  confirm() {
    this.analyticsEvent();

    if (this.price.every((v) => v <= 0)) {
      this.modalObj.data.prices = [];
    } else {
      this.modalObj.data.prices = this.price;
    }

    super.confirm();
  }

  analyticsEvent() {
    let st = '';

    const priceStart = this.listStart.find((v) => v.id === this.price[0]);
    const priceEnd = this.listEnd.find((v) => v.id === this.price[1]);

    if (this.price[0] > 0 && this.price[1] > 0) {
      st = `De ${priceStart.text} a ${priceEnd.text}`;
    } else if (this.price[0] > 0) {
      st = `A partir de ${priceStart.text}`;
    } else if (this.price[1] > 0) {
      st = `No máximo ${priceEnd.text}`;
    }

    if (st) {
      this.analyticsService.trackEvent(`Preço (${st})`, 'Academias', `Filtros`);
    }
  }

  loadPrice() {
    for (let i = this.priceRange.min; i < this.priceRange.max; i += 100) {
      this.addListStart(i);
    }

    this.addListStart(this.priceRange.max);
    this.listEnd = this.listStart.slice();
    this.listStart.unshift({ id: 0, text: 'Sem valor mínimo' } as SelectItem);
    this.listEnd.unshift({ id: 0, text: 'Sem valor máximo' } as SelectItem);
  }

  addListStart(value: number) {
    this.listStart.push({
      id: value,
      text: new CurrencyPipe(AppConstants.LOCALE).transform(value, 'BRL'),
    });
  }
}
