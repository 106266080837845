<ng-template #header>
  <app-header-static-image [headerObj]="config?.header"></app-header-static-image>
</ng-template>

<div class="main-wrapper container justify-content-around d-md-flex text-center" *ngIf="!hasToken; else resetAccess">

  <div class="card small-card row-on-xs no-side-border-xs">
    <form #form="ngForm" class="form form-validate" (submit)="requestChangeEmailToken()">
      <div class="form-container text-left">
        <div class="title-section text-black">Alterar e-mail de acesso</div>

        <div>
            Se você não tem mais acesso ao seu antigo e-mail é necessário efetuar a
            troca de seu endereço de acesso ao site.
            Para isso, preencha os campos abaixo:
        </div>

        <label for="forgot-cpf" class="text-left text-uppercase">CPF</label>
        <input
          id="forgot-cpf"
          #cpf="ngModel"
          name="cpf"
          type="text"
          class="form-control"
          [imask]="docMask"
          appCpfValidator
          [(ngModel)]="recoveryEmail.cpf"
          required>
        <app-error-message [control]="cpf"></app-error-message>

        <label for="forgot-reg" class="text-left text-uppercase">Matrícula</label>
        <input id="forgot-reg" #reg="ngModel" name="reg" type="text"
          class="form-control"
          [(ngModel)]="recoveryEmail.registration"
          required>
        <app-error-message [control]="reg"></app-error-message>
      </div>

      <div class="button-container row no-gutters">
        <button type="button" id="forgot-cancel" class="col btn btn-outline-primary" routerLink="/cliente/esqueci">
          Voltar
        </button>
        <button type="submit" id="forgot-confirm" class="col btn btn-primary submit-btn">
          Enviar
        </button>
      </div>
    </form>

    <app-registry-hint-modal></app-registry-hint-modal>
  </div>

</div>

<ng-template #resetAccess>
  <app-reset-access></app-reset-access>
</ng-template>
