import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GalleryModule } from '@ngx-gallery/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from 'ngx-swiper-wrapper';
import { ErrorModule } from '@sharedcomponents/error/error.module';
import { GymImagesCarouselComponent } from './gym-images-carousel.component';
import { ModalGymImagesComponent } from './modal-gym-images/modal-gym-images.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  declarations: [GymImagesCarouselComponent, ModalGymImagesComponent],
  imports: [
    CommonModule,
    ErrorModule,
    GalleryModule,
    ModalModule,
    SwiperModule,
  ],
  providers: [{ provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG }],
  exports: [GymImagesCarouselComponent],
})
export class GymImagesCarouselModule {}
