import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss'],
})
export class VideoCardComponent {
  @Input() title: string;
  @Input() firstParagraph: string;
  @Input() secondParagraph?: string;
  @Input() url: string;
}
