import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GymsByCitiesOfState, GymToSelect } from '@models/bt-pass.model';

@Component({
  selector: 'app-select-gym-tab',
  templateUrl: './select-gym-tab.component.html',
  styleUrls: [
    './select-gym-tab.component.scss',
    '../modal-gym-select-by-city.component.scss',
  ],
})
export class SelectGymTabComponent {
  @Input() gymsByState: GymsByCitiesOfState[];
  @Input() selectedCity: string;
  @Input() maxNumberOfGymSelections: number;
  @Input() selectedGyms: GymToSelect[];

  @Output() clearFilterEmitter = new EventEmitter();
  @Output() updateSelectedGymList = new EventEmitter<GymToSelect[]>();

  searchGym: string;

  clearFilter(): void {
    this.searchGym = '';
    this.clearFilterEmitter.next();
  }

  toogleGymSelection(selectedGym: GymToSelect): void {
    if (
      this.selectedGyms?.some((gym) => gym.gymUnitId === selectedGym.gymUnitId)
    ) {
      this.removeSelectedGym(selectedGym);
      this.updateSelectedGymList.next(this.selectedGyms);
      return;
    }

    this.selectGym(selectedGym);
    this.updateSelectedGymList.next(this.selectedGyms);
  }

  private selectGym(gym: GymToSelect): void {
    this.selectedGyms.push(gym);
  }

  private removeSelectedGym(gymToRemove: GymToSelect): void {
    this.selectedGyms = this.selectedGyms.filter(
      (gym) => gymToRemove.gymUnitId !== gym.gymUnitId
    );
  }
}
