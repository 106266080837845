import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDropdownArrows]',
})
export class DropdownArrowsDirective implements OnInit {
  @Input() appDropdownArrows: boolean;

  constructor(
    private readonly elem: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit() {
    const classes = [
      'dropdown-arrow',
      'd-flex',
      'justify-content-between',
      'align-items-center',
    ];

    if (this.appDropdownArrows !== false) {
      classes.push('up-arrow', 'collapsed');
    }

    for (const c of classes) {
      this.renderer.addClass(this.elem.nativeElement, c);
    }
  }
}
