import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormatModule } from '@pipes/format/format.module';
import { GymBusinessHoursComponent } from './gym-business-hours/gym-business-hours.component';
import { GymContactInfoComponent } from './gym-contact-info/gym-contact-info.component';

@NgModule({
  declarations: [GymBusinessHoursComponent, GymContactInfoComponent],
  imports: [CommonModule, FormatModule],
  exports: [GymBusinessHoursComponent, GymContactInfoComponent],
})
export class GymsSharedModule {}
