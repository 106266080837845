<div *ngIf="!showUpdatePaymentForm" class="title-section">
  CONTRATOS E PAGAMENTO
</div>
<div *ngIf="!showUpdatePaymentForm">
  <ng-container *ngIf="contractList | async; let contract; else: loading">
    <div *ngIf="!contract.length" class="error-container">
      <app-empty-card
        icon="/assets/icons_custom/ic-empty-list.svg"
        altIcon="Lista vazia"
        [message]="emptyMessage"
      >
      </app-empty-card>
    </div>
    <div
      class="contract-container mb-3"
      *ngFor="let info of contract; let contractIndex = index"
    >
      <div
        [class.collapsed]="!block.expanded"
        *ngIf="contract.length > 1; else single"
        appDropdownArrows
        appClickable
        (click)="block.toggle()"
        class="list-group list-group-flush title table-header border-bottom flex-row"
        [attr.aria-expanded]="block.expanded"
        attr.aria-controls="block{{ contractIndex }}"
      >
        <div
          class="d-flex flex-column flex-md-row w-100 justify-content-between"
        >
          <span class="plan">{{ info.plan }}</span>
          <span>{{ info.gym?.name }}</span>
        </div>
      </div>
      <ng-template #single>
        <div
          class="list-group list-group-flush title table-header d-flex flex-column flex-md-row justify-content-between"
        >
          <span class="plan">{{ info.plan }}</span>
          <span>{{ info.gym?.name }}</span>
        </div>
      </ng-template>

      <div id="block{{ contractIndex }}" #block app-collapse [expanded]="true">
        <div class="list-group list-group-flush list-striped">
          <div class="list-group-item d-flex flex-row justify-content-between">
            <span>Status</span>
            <span class="value">{{ info.contractStatus }}</span>
          </div>
          <div class="list-group-item d-flex flex-row justify-content-between">
            <span>Contratação</span>
            <span class="value"
              >{{ info.dates.start | date : "shortDate" : "" : locale }} a
              {{ info.dates.end | date : "shortDate" : "" : locale }}</span
            >
          </div>
          <div class="list-group-item d-flex flex-row justify-content-between">
            <span>Periodo Contratado</span>
            <span class="value">{{ info.deadline }}</span>
          </div>
          <div
            *ngIf="
              info.isRecurrent &&
              info.contractStatusCode !== contractStatusEnum.NotApproved
            "
            class="list-group-item d-flex flex-row justify-content-between"
          >
            <span>Dados de pagamento</span>
            <div class="d-flex flex-column align-items-end">
              <span class="value">
                {{
                  info.clientCreditCard.flagId
                    ? flagCardOptions[info.clientCreditCard.flagId - 1].text +
                      " terminado em " +
                      info.clientCreditCard.creditCardNumber.substring(
                        info.clientCreditCard.creditCardNumber.length - 4
                      )
                    : "Pagamento na Unidade"
                }}
              </span>
              <a
                *ngIf="info.hasFinancialPending; else buttonWithoutPending"
                class="change-card-button link"
                (click)="updatePaymentForm(info)"
              >
                REGULARIZAR PLANO
              </a>
              <ng-template #buttonWithoutPending>
                <a
                  *ngIf="info.contractStatusCode === contractStatusEnum.Active"
                  class="change-card-button link"
                  (click)="updatePaymentForm(info)"
                >
                  {{
                    info.clientCreditCard.flagId
                      ? "ATUALIZAR FORMA DE PAGAMENTO"
                      : "ADICIONAR CARTÃO DE CRÉDITO"
                  }}
                </a>
              </ng-template>
            </div>
          </div>
          <div class="list-group-item d-flex flex-row justify-content-between">
            <span>Código de compra</span>
            <span class="value">{{ info.purchaseCode }}</span>
          </div>
          <div class="list-group-item d-flex justify-content-between">
            <span class="w-100"> Contrato </span>
            <span class="file-icon-container w-100">
              <a (click)="getContractUrl(info)">
                <img alt="Arquivo pdf" src="/assets/icons_custom/ic-pdf.svg" />
              </a>
            </span>
          </div>

          <div
            class="total-container list-group-item d-flex flex-column flex-md-row justify-content-between row"
          >
            <div
              class="col-12 col-lg-4 d-flex flex-row flex-lg-column justify-content-between"
            >
              <div class="title">Valor do plano</div>
              <div class="value">
                {{ info.planValue | currency : "BRL" : "symbol" : "" : locale }}
              </div>
            </div>
            <div
              class="col-12 col-lg-4 text-lg-center d-flex flex-row flex-lg-column justify-content-between"
            >
              <div class="title">Serviços adicionais</div>
              <div class="value">
                {{
                  info.servicesValue | currency : "BRL" : "symbol" : "" : locale
                }}
              </div>
            </div>
            <div
              class="col-12 col-lg-4 text-lg-right d-flex flex-row flex-lg-column justify-content-between"
            >
              <div class="title">Total</div>
              <div class="value">
                {{
                  info.planValue + info.servicesValue
                    | currency : "BRL" : "symbol" : "" : locale
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center mt-3 pb-5 border-bottom d-flex flex-row flex-wrap justify-content-center"
        >
          <a
            id="renew-contract"
            [routerLink]="[info.renewContractURL]"
            *ngIf="info.preRenewable && info.isValidPlanToPortal"
            class="btn btn-primary mb-2 col-12 col-md-5 col-lg-4 anticipate-button"
          >
            Antecipar renovação
          </a>
          <a
            id="renew-contract"
            [routerLink]="[info.renewContractURL]"
            *ngIf="info.renewable && info.isValidPlanToPortal"
            class="btn btn-primary mb-2 col-12 col-md-5 col-lg-4 anticipate-button"
          >
            Renovar plano
          </a>
          <a
            id="renew-contract"
            routerLink="/academia/{{ info.gym.slug }}/matricule-se"
            *ngIf="
              (((!info.preRenewable && !info.renewable && !info.cancelable) ||
                !info.isValidPlanToPortal) &&
                info.contractStatus !== 'Ativo') ||
              (info.isRecurrent && info.contractStatus === 'Ativo')
            "
            class="btn btn-primary mb-2 col-12 col-md-5 col-lg-4"
          >
            Novo Plano
          </a>
          <a
            *ngIf="info.hasFinancialPending && !info.renewable"
            (click)="updatePaymentForm(info)"
            class="btn btn-primary mb-2 col-12 col-md-5 col-lg-4"
          >
            Regularizar Plano
          </a>
          <a
            routerLink="/cliente/trancamento"
            class="btn btn-primary mb-2 col-12 col-md-5 col-lg-4"
            [state]="{
              contractCode: info.contractCode,
              gymUnitId: info.gym.id
            }"
          >
            Trancar Plano
          </a>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #loading>
  <div class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>

<app-profile-payment
  [userInfo]="selectedPlanUserInfo"
  *ngIf="showUpdatePaymentForm"
  (goBackContractScreen)="showUpdatePaymentForm = false"
>
</app-profile-payment>
