<h3 class="title section mt-0">Informações pessoais</h3>

<div class="text-help">Este formulário deve ser usado para você nos enviar as informações referentes ao interesse na abertura de uma franquia da academia {{ brandService.fullBrandName }}.</div>
<form #form="ngForm" class="row">
  <div class="col-12 col-md">
    <label for="franchise-personal-name">Nome</label>
    <input id="franchise-personal-name" name="name" class="form-control" autocomplete="name"
      [(ngModel)]="info.name" required minlength="3">

    <label for="franchise-personal-email">E-mail</label>
    <input id="franchise-personal-email" name="email" class="form-control" autocomplete="email"
      [(ngModel)]="info.email" required [pattern]="emailRegexp">

    <label for="franchise-personal-birthdate">Data de nascimento</label>
    <input id="franchise-personal-birthdate" name="birthDate" class="form-control" autocomplete="bday"
      [(ngModel)]="info.birthDate" [imask]="dateMask" required appDate>

    <label for="franchise-personal-tel-0">Telefone fixo</label>
    <input id="franchise-personal-tel-0"
      name="phone-home"
      class="form-control"
      autocomplete="tel tel-national home"
      [imask]="phoneMask"
      [(ngModel)]="info.landlinePhone"
      [required]="!info.cellPhone"
      minlength="14" maxlength="15">

    <label for="franchise-personal-tel-1">Telefone celular</label>
    <input id="franchise-personal-tel-1"
      name="phone-cell"
      class="form-control"
      autocomplete="tel tel-national mobile"
      [imask]="phoneMask"
      [(ngModel)]="info.cellPhone"
      [required]="!info.landlinePhone"
      minlength="14" maxlength="15">
  </div>

  <div class="col-12 col-md col-right">
    <label for="franchise-personal-gender">Sexo</label>
    <div id="franchise-personal-gender" name="gender" class="group-control form-custom"
      [ngModel]="info.gender" required ngDefaultControl>
      <div class="d-inline-block radio-item">
        <app-radiobutton name="franchise-gender-2" [(check)]="info.gender" [value]="'F'" message="Feminino"></app-radiobutton>
      </div>
      <div class="d-inline-block radio-item">
        <app-radiobutton name="franchise-gender-1" [(check)]="info.gender" [value]="'M'" message="Masculino"></app-radiobutton>
      </div>
    </div>

    <label for="franchise-personal-civil">Estado civil</label>
    <app-select id="franchise-personal-civil"
      name="civilstate"
      class="form-custom"
      [(ngModel)]="info.maritalStatus.id"
      [list]="civil"
      ngDefaultControl
      required></app-select>

    <label for="franchise-personal-doc2">Identidade</label>
    <input id="franchise-personal-doc2" type="text" name="rg" class="form-control" autocomplete="document-id"
      [(ngModel)]="info.identityDocument" required>

    <label for="franchise-personal-doc-main">CPF</label>
    <input id="franchise-personal-doc-main"
      name="cpf"
      class="form-control"
      [imask]="docMask"
      autocomplete="document-main"
      [(ngModel)]="info.cpf"
      minlength="14"
      maxlength="14"
      appCpfValidator
      #cpf="ngModel"
      required>
    <app-error-message [control]="cpf"></app-error-message>
  </div>
</form>

<div class="section address-container">
  <app-data-address #formAddress
    name="personal"
    [(address)]="info.address"
    (afterOnInit)="initFormIfLoggedIn()"
    [showTitle]="false">
  </app-data-address>
</div>
