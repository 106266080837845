import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { FranchiseRequest } from '@models';
import { UserProfileService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class FranchiseResolveGuard implements Resolve<FranchiseRequest> {
  constructor(private readonly profileService: UserProfileService) {}

  resolve(): ResolveResult<FranchiseRequest> {
    return this.profileService.getFranchisePersonalInfo();
  }
}
