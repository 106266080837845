import { Component, Input, OnInit } from '@angular/core';
import { NgModel, FormControl } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';

/**
 * Criar erro custom:
 *  - Criar a mensagem do erro na variavel 'config'
 *  - Importar o ErroUtil:
 *      import { ErrorUtil } from '@utils/error-util'
 *  - Criar o erro a partir do metodo 'insertOrUpdate', passando o control do input e o objeto com o erro,
 *    seguindo o exemplo:
 *    'ErrorUtil.insertOrUpdate(this.form.controls['password'], { unique: true });'
 *  - Setar o erro como false, quando não precisar mais valida-lo(utilizar o mesmo metodo)
 */
@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit {
  @Input() control: NgModel | FormControl;
  @Input() set messages(newConfig: any) {
    Object.assign(this.config, newConfig);
  }

  fieldName: string;
  listError: any = [];
  controlValue$: Observable<any>;
  controlSubscription: Subscription;
  hasSubmitted: boolean;
  config = {
    pattern: `Formato incorreto`,
    max: `O valor ultrapassa o máximo permitido`,
    min: `O valor é menor que o mínimo permitido`,
    maxLength: `O campo ultrapassa o número máximo de caracteres`,
    minLength: `O campo não possui o valor mínimo de caracteres`,
    email: `E-mail inválido`,
    required: `Campo obrigatório`,
    cpfNotValid: `CPF informado inválido`,
    expiredCreditCard: `Data de validade inválida`,
    password: `A senha deve ser pelo menos 6 caracteres contendo letras e números`,
    matchEmail: `E-mails não conferem`,
    matchPassword: `Senhas não conferem`,
  };

  ngOnInit() {
    // Intercepta o submit
    let formSubmit$;
    if (this.control instanceof NgModel) {
      this.fieldName = this.control.name;
      formSubmit$ = this.control.formDirective.ngSubmit.pipe(
        map(() => {
          this.hasSubmitted = true;
          return false;
        })
      );
    } else if (this.control instanceof FormControl) {
      this.fieldName = 'default';
      formSubmit$ = this.control.statusChanges.pipe(
        map(() => {
          this.hasSubmitted = true;
          return false;
        })
      );
    }

    this.controlValue$ = of(
      this.control.valueChanges,
      of(''),
      formSubmit$
    ).pipe(mergeAll());
    this.controlValue$.subscribe(() => this.setVisible());
  }

  private setVisible() {
    if (!this.control) {
      return;
    }
    if (this.control.invalid && (this.control.dirty || this.hasSubmitted)) {
      this.load();
    } else {
      this.listError = [];
    }
  }

  load() {
    this.listError = Object.keys(this.control.errors).map(
      (keyError) => this.config[keyError]
    );
  }
}
