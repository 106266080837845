import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Gym,
  MyContractResponse,
  RegistrationPaymentData,
  SelectItem,
  User,
} from '@models';
import { AuthService } from './auth.service';
import { GymService } from './gym.service';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { JSONUtil } from '@utils/json-util';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { GymUnitPeriodicityService } from './gym-unit-periodicity.service';

@Injectable()
export class MyContractService {
  cache: MyContractResponse[];
  user: User;

  constructor(
    private readonly authService: AuthService,
    private readonly http: HttpClient,
    private readonly gymService: GymService,
    private readonly gymUnitPeriodicityService: GymUnitPeriodicityService
  ) {}

  getInfo(): Observable<MyContractResponse[]> {
    this.user = null;

    return this.http.get<any>(back(BackEndpoints.Contract)).pipe(
      map((json) => (json || []).map(this.mapContratoFromOldCanais)),
      catchError((res) => {
        return throwError(res);
      }),
      mergeMap((json: MyContractResponse[]) =>
        this.gymService.getGymNamesWithState().pipe(
          catchError(() =>
            json.map((contract) => ({ id: contract.gym, name: '?' }))
          ),
          map((gymNames: any[]) => {
            return this.convertGymIdsToName(json, gymNames);
          }),
          catchError(() => [json])
        )
      ),
      map((json: MyContractResponse[]) => {
        return this.chargeRenewURL(json);
      }),
      tap((result) => {
        this.user = this.authService.getUser();
        this.cache = result;
      })
    );
  }

  mapContratoFromOldCanais(contract: any): MyContractResponse {
    const gym = JSONUtil.get(contract, 'Unidade');
    return {
      gym,
      contractCode: JSONUtil.get(contract, 'Codigo'),
      contractStatus: JSONUtil.get(contract, 'SituacaoContrato'),
      contractStatusCode: JSONUtil.get(contract, 'CodigoSituacaoContrato'),
      planName: JSONUtil.get(
        contract,
        'ListaItens.0.PlanoUnidade.Plano.NomePlano'
      ),
      plan: [
        JSONUtil.get(contract, 'ListaItens.0.PlanoUnidade.Plano.NomePlano'),
        JSONUtil.get(contract, 'SituacaoContrato'),
      ].join(' - '),
      dates: {
        start: JSONUtil.getDate(contract, 'DataInicio').toISOString(),
        end: JSONUtil.getDate(contract, 'DataFim').toISOString(),
      },
      clientCreditCard: {
        flagId: JSONUtil.get(contract, 'DadosCartaoCliente.IdBandeira'),
        creditCardNumber: JSONUtil.get(
          contract,
          'DadosCartaoCliente.NumeroCartaoMascarado'
        ),
      },
      interval: JSONUtil.get(contract, 'ListaItens.0.Horario'),
      deadline: JSONUtil.get(
        contract,
        'ListaItens.0.PlanoUnidade.Preco.Periodicidade'
      ),
      frequency: JSONUtil.get(
        contract,
        'ListaItens.0.PlanoUnidade.Preco.Frequencia'
      ),
      purchaseCode: JSONUtil.get(contract, 'ListaItens.0.CodigoCompra'),
      hasFinancialPending: JSONUtil.get(contract, 'TemPendenciaFinanceira'),
      isRecurrent: JSONUtil.get(contract, 'IsCobrancaRecorrente'),
      termsUrl: '',
      planValue: JSONUtil.get(contract, 'ValorPlanos'),
      servicesValue: JSONUtil.get(contract, 'ValorReceitas'),
      renewable: JSONUtil.get(contract, 'PodeRenovar'),
      preRenewable: JSONUtil.get(contract, 'PodeRenovarAntecipado'),
      cancelable: JSONUtil.get(contract, 'PodeCancelar'),
    };
  }

  convertGymIdsToName(
    contracts: MyContractResponse[],
    gymNames: any[]
  ): MyContractResponse[] {
    contracts.forEach((contract) => {
      const contractGym = gymNames.find((gym) => gym.id === contract.gym) || {};
      contract.gym = {
        id: contractGym.id,
        name: contractGym.portalName,
        slug: contractGym.slug,
        address: contractGym.address,
      } as Gym;
    });
    return contracts;
  }

  cancelContract(codigoContrato: string): Observable<any> {
    return this.http
      .get<any>(back(BackEndpoints.ContractStart, codigoContrato))
      .pipe(
        map(this.mapCancelamentoInfoFromOldCanais),
        mergeMap((result) =>
          result.data.withTax
            ? this.http
                .get<any>(back(BackEndpoints.ContractPayment, codigoContrato))
                .pipe(
                  map(this.mapCancelamentoFormasPagamento),
                  map((payment) => Object.assign(result, payment))
                )
            : of(result)
        ),
        catchError((error) => [{ error }]),
        mergeMap((result) =>
          this.getCachedInfo().pipe(
            map((contracts) =>
              contracts.find((c) => c.contractCode === codigoContrato)
            ),
            map((contract) => Object.assign(result, { contract }))
          )
        ),
        mergeMap((result) =>
          (result['data'] &&
            result['data'].userId.toUpperCase() !==
              this.user.id.toUpperCase()) ||
          !result['contract']
            ? of({ error: { error: { message: 'Contrato não encontrado' } } })
            : of(result)
        )
      );
  }

  mapCancelamentoInfoFromOldCanais(data: any): any {
    const withTax = JSONUtil.get(data, 'ComMulta');
    const isRecurrent = JSONUtil.get(data, 'IsCobrancaRecorrente');
    const commonData = {
      withTax,
      isRecurrent,
      requestPayment: withTax && isRecurrent,
      userId: JSONUtil.get(data, 'Cliente'),
      dateStart: JSONUtil.getDate(data, 'DataInicio'),
      dateCancel: JSONUtil.getDate(data, 'DataCancelamento'),
    };
    const taxData = withTax
      ? {
          code: JSONUtil.get(data, 'ListaPlanos.0.NumeroCarrinhoCompraUnidade'),
          plan: JSONUtil.get(data, 'ListaPlanos.0.NomePlano'),
          valuePayed: JSONUtil.get(data, 'ListaPlanos.0.ValorPlano'),
          valueRemaining: JSONUtil.get(
            data,
            'ListaPlanos.0.ValorRestantePlano'
          ),
          dateMin: JSONUtil.getDate(
            data,
            'ListaPlanos.0.DataCancelamentoMinimaAcordada'
          ),
          cancelTax: JSONUtil.get(data, 'ValorMulta'),
        }
      : null;
    return { data: Object.assign(commonData, taxData) };
  }

  mapCancelamentoFormasPagamento(data: any) {
    const expirationDateClientCreditCard = 'DadosCartaoCliente.DataValidade';
    const flagOptions: any[] = JSONUtil.get(data, 'FormasDePagamento');
    return {
      payment: {
        cardNumber: JSONUtil.get(
          data,
          'DadosCartaoCliente.NumeroCartaoMascarado'
        ),
        cardFlag: JSONUtil.get(data, 'DadosCartaoCliente.IdBandeira'),
        cardExpiringMonth: (JSONUtil.get(
          data,
          expirationDateClientCreditCard
        ) as string)
          ? (
              JSONUtil.get(data, expirationDateClientCreditCard) as string
            ).substring(0, 2)
          : null,
        cardExpiringYear: JSONUtil.get(data, expirationDateClientCreditCard)
          ? JSONUtil.get(data, expirationDateClientCreditCard).substring(3)
          : null,
        cardOwnerName: JSONUtil.get(data, 'DadosCartaoCliente.NomeCliente'),
        justClick: JSONUtil.get(data, 'JustClick'),
      } as RegistrationPaymentData,
      flagOptions: flagOptions.map((p) => ({
        id: JSONUtil.get(p, 'IdBandeira'),
        text: JSONUtil.get(p, 'NomeBandeira'),
      })) as SelectItem<any>[],
    };
  }

  getCachedInfo() {
    if (this.user === this.authService.getUser()) {
      return of(this.cache);
    } else {
      return this.getInfo();
    }
  }

  cancelContractFinish(
    codigoContrato: string,
    data = {} as RegistrationPaymentData
  ) {
    return this.http.post(
      back(BackEndpoints.ContractFinish, codigoContrato),
      this.mapPaymentToOldCanais(data)
    );
  }

  mapPaymentToOldCanais(data: RegistrationPaymentData): any {
    return {
      DadosCartaoCliente: {
        CodigoSeguranca: data.cardCVV,
        DataValidade: `${data.cardExpiringMonth}/${data.cardExpiringYear}`,
        NomeCliente: data.cardOwnerName,
        NumeroCartao: data.cardNumber,
        NumeroCartaoMascarado: data.cardNumber,
      },
      IdFormaDePagamento: data.cardFlag,
      JustClick: data.justClick,
    };
  }

  chargeRenewURL(contracts: MyContractResponse[]): MyContractResponse[] {
    contracts.forEach((contract) => {
      if (contract.preRenewable || contract.renewable || !contract.cancelable) {
        const periodicity =
          contract.deadline === 'Prazo Indeterminado'
            ? 'Mensal'
            : contract.deadline;
        this.gymUnitPeriodicityService
          .getSuccessorPlanBySGAPlan(
            contract.planName,
            periodicity,
            contract.gym.id
          )
          .subscribe((result) => {
            contract.renewContractURL = `/academia/${contract.gym.slug}`;

            if (result) {
              contract.renewContractURL += `/compra/${result.plan.slug}/${result.periodicity.slug}/renovacao`;
              contract.isValidPlanToPortal = true;
            } else {
              contract.renewContractURL += `/matricule-se`;
              contract.isValidPlanToPortal = false;
            }
          });
      }
    });

    return contracts;
  }
}
