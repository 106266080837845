import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss'],
})
export class RadiobuttonComponent {
  @Input() name = '';
  @Input() check: any = '';
  @Output() checkChange = new EventEmitter<any>();
  @Input() value: any = 0;
  @Input() message = '';

  toggle() {
    this.check = this.value;
    this.checkChange.emit(this.value);
  }
}
