import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmergencyContact, SelectItem } from '@models';
import { AppConstants } from '@utils/app-constants';
import { ArrayUtil } from '@utils/array-util';
import { FormUtil } from '@utils/form-util';

@Component({
  selector: 'app-profile-data-emergency',
  templateUrl: './profile-data-emergency.component.html',
  styleUrls: [
    './profile-data-emergency.component.scss',
    '../../profile.shared.scss',
  ],
})
export class ProfileDataEmergencyComponent implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;

  @Input() emergency: EmergencyContact[];

  readonly phoneMask = AppConstants.Mask.phone;
  subsResolve: Subscription;
  relationships: SelectItem[];

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit() {
    this.subsResolve = this.route.data.subscribe((data) => {
      this.relationships = data.relationships;
    });
  }

  newEmergencyContact() {
    this.emergency.push({} as EmergencyContact);
  }

  removeEmergencyContact(contact: EmergencyContact) {
    ArrayUtil.removeItem(this.emergency, contact);
  }

  isValid() {
    FormUtil.touchForm(this.form.control);
    return this.form.valid;
  }
}
