import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ResolveResult } from './type-resolve-bundle';
import { GymService } from '@services';
import { map } from 'rxjs/operators';

@Injectable()
export class GymSearchParamString implements Resolve<string> {
  constructor(private readonly gymService: GymService) {}

  resolve(route: ActivatedRouteSnapshot): ResolveResult<string | null> {
    const state = route.params['state'];
    const city = route.params['city'];
    const neighborhood = route.params['neighborhood'];

    if (!state) {
      return '';
    }

    return this.gymService.getParamNameBySlug(state, city, neighborhood).pipe(
      map((data) => {
        let message = `Academias em ${
          data.addressNeighborhood || data.cityName || data.stateName
        }`;
        if (data.addressNeighborhood) {
          message += ` em ${data.cityName}`;
        }
        return message;
      })
    );
  }
}
