import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { PrivacyPolicyRoutingModule } from './privacy-policy.routing';

@NgModule({
  imports: [CommonModule, SharedModule, PrivacyPolicyRoutingModule],
  declarations: [PrivacyPolicyComponent],
})
export class PrivacyPolicyModule {}
