<ng-template #loader>
  <div class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</ng-template>
<ng-container *ngIf="!loading; else loader">
  <form
    #formAccount="ngForm"
    *ngIf="info; else error"
    class="form-validate"
    (submit)="save()"
  >
    <div class="title-section">E-mail</div>
    <div class="text-help">
      Para alterar somente seu e-mail de acesso, digite o novo e-mail abaixo,
      confirme e clique em SALVAR.
    </div>
    <div class="row">
      <div class="col-12">
        <label for="email" class="label-input first-of-section">E-mail</label>
        <label id="email" class="form-control text-muted">{{
          info.login
        }}</label>
      </div>

      <div class="col-12 col-md">
        <label for="new-email" class="label-input">Novo e-mail</label>
        <input
          id="new-email"
          name="newEmail"
          class="form-control"
          autocomplete="email"
          #login1="ngModel"
          [(ngModel)]="info.login1"
          [required]="!info.password1"
          (keyup)="login2.control.updateValueAndValidity()"
          [pattern]="emailRegexp"
        />
        <app-error-message
          [control]="login1"
          [messages]="{
            required: 'Preencha esse campo caso queira mudar de e-mail',
            pattern: 'E-mail inválido'
          }"
        ></app-error-message>
      </div>

      <div class="col-12 col-md col-right">
        <label for="confirm-email" class="label-input"
          >Repetir novo e-mail</label
        >
        <input
          id="confirm-email"
          name="confirmEmail"
          class="form-control"
          autocomplete="email"
          #login2="ngModel"
          [(ngModel)]="info.login2"
          [appMatch]="info.login1"
          [pattern]="emailRegexp"
        />
        <app-error-message
          [control]="login2"
          [messages]="{
            match: 'Confirmação de e-mail falhou',
            pattern: 'E-mail inválido'
          }"
        ></app-error-message>
      </div>
    </div>

    <div class="section">
      <div class="title-section">Senha</div>
      <div class="text-help">
        Para alterar somente sua senha de acesso, digite a nova senha no campo
        abaixo, confirme e clique em SALVAR. A senha deve conter ao menos 6
        caracteres e possuir letras e números.
      </div>
      <div class="row">
        <div class="col-12 col-md">
          <label for="new-password" class="label-input">Nova senha</label>
          <input
            id="new-password"
            name="newPassword"
            type="password"
            autocomplete="new-password"
            class="form-control"
            #password1="ngModel"
            [(ngModel)]="info.password1"
            [required]="!info.login1"
            (keyup)="password2.control.updateValueAndValidity()"
            pattern="(\S*\d\S*[a-zA-Z]\S*)|(\S*[a-zA-Z]\S*\d\S*)"
            minlength="6"
          />
          <app-error-message
            [control]="password1"
            [messages]="{
              pattern: 'Senha inválida'
            }"
          ></app-error-message>
        </div>

        <div class="col-12 col-md col-right">
          <label for="confirm-password" class="label-input"
            >Repetir nova senha</label
          >
          <input
            id="confirm-password"
            name="confirmPassword"
            type="password"
            autocomplete="new-password"
            class="form-control"
            #password2="ngModel"
            [(ngModel)]="info.password2"
            [appMatch]="info.password1"
            pattern="(\S*\d\S*[a-zA-Z]\S*)|(\S*[a-zA-Z]\S*\d\S*)"
            minlength="6"
          />
          <app-error-message
            [control]="password2"
            [messages]="{
              pattern: 'Senha inválida',
              match: 'Confirmação de senha falhou'
            }"
          ></app-error-message>
        </div>
      </div>
    </div>

    <div class="text-center button-container">
      <button class="btn btn-primary btn-confirm submit-btn">Salvar</button>
    </div>
  </form>
</ng-container>

<ng-template #error>
  <app-empty-card
    icon="/assets/icons_custom/ic-empty-list.svg"
    altIcon="Lista vazia"
    [message]="emptyMessage"
  >
  </app-empty-card>
</ng-template>
