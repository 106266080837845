<div class="title-section">HISTÓRICO DE COMPRAS</div>
<div class="table-container">
  <app-purchase-item
    [purchaseItem]="purchase"
    *ngIf="hasPurchase"
  ></app-purchase-item>
  <app-presale-purchase-item
    [item]="preSalePuchase"
    *ngIf="hasPreSalePurchase"
  ></app-presale-purchase-item>
  <ng-container
    *ngIf="
      !hasPurchase && !hasPreSalePurchase && !isLoadingPurchases;
      then empty
    "
  >
  </ng-container>
  <ng-template #empty>
    <div class="error-container">
      <app-empty-card
        icon="/assets/icons_custom/ic-empty-list.svg"
        altIcon="Lista vazia"
        [message]="emptyMessage"
      >
      </app-empty-card>
    </div>
  </ng-template>
  <div *ngIf="isLoadingPurchases" class="title text-center">
    <i class="fas fa-spinner fa-pulse"></i> Carregando
  </div>
</div>
