import { CpfValidatorDirective } from './cpf-validator.directive';
import { DateValidatorDirective } from './date-validator.directive';
import { InputTypeDirective } from './input-type.directive';
import { MatchValidatorDirective } from './match-validator.directive';
import { MaxLinesDirective } from './max-lines.directive';
import { OnlyNumberDirective } from './only-number.directive';
import { PasswordValidatorDirective } from './password-validator.directive';

export * from './accordion/accordion.module';
export * from './input-type.directive';
export * from './clickable/clickable.directive';
export * from './cpf-validator.directive';
export * from './date-validator.directive';
export * from './dropdown-arrows/dropdown-arrows.directive';
export * from './lazy-create/lazy-create.module';
export * from './match-validator.directive';
export * from './max-lines.directive';
export * from './password-validator.directive';
export * from './template-hightlight/template-hightlight.module';

export const directives = [
  InputTypeDirective,
  CpfValidatorDirective,
  DateValidatorDirective,
  MatchValidatorDirective,
  MaxLinesDirective,
  OnlyNumberDirective,
  PasswordValidatorDirective,
];
