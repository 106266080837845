import { Component, OnInit } from '@angular/core';
import { Alert, AlertService } from '@services/alert.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alert-wrapper',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  public messages: Observable<Alert[]>;

  constructor(public readonly alertService: AlertService) {}

  ngOnInit() {
    this.messages = this.alertService.alertsObs;
  }
}
