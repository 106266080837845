import { FormGroup } from '@angular/forms';

export class CreditCardUtil {
  static isExpired(group: FormGroup) {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const inputYear = group?.controls['cardExpiringYear']?.value;
    const inputMonth = group?.controls['cardExpiringMonth']?.value;
    const expired =
      inputYear &&
      inputMonth &&
      (+inputYear < +year || (+inputYear === +year && +inputMonth < +month));

    if (inputYear && inputMonth) {
      group.controls['cardExpiringYear'].setErrors(
        expired ? { expiredCreditCard: true } : null
      );
    }
    return expired ? { expiredCreditCard: true } : null;
  }
}
