import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from '@services/brand.service';

@Component({
  selector: 'app-btfit-landingpage',
  templateUrl: './btfit-landingpage.component.html',
  styleUrls: ['./btfit-landingpage.component.scss'],
})
export class BtfitLandingpageComponent {
  public termsLink: string;

  isBT: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly brandService: BrandService
  ) {
    this.termsLink = this.route.snapshot.data.config.terms.termsLink;
    this.isBT = this.brandService.getBrand() === 'bt';
  }
}
