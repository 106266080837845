import { PortalConfigResolveGuard } from '@guards/portal-config-resolve.guard';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BtCompanyComponent } from './bt-company.component';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';

const btRoute = [
  {
    path: '',
    component: BtCompanyComponent,
    resolve: {
      config: PortalConfigResolveGuard,
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.config.company.header.title}',
      windowContext: 'company',
      config: { company: 'bt-company' },
      slug: 'company',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(btRoute)],
  exports: [RouterModule],
})
export class BtCompanyRoutingModule {}
