import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from 'ngx-swiper-wrapper';
import {
  EvoRedirectInterceptor,
  XAuthorizationInterceptor,
} from '@interceptors';
import { EvoMembershipService } from '@services/evo';
import { LoadingContentModule } from '@shared/components/loading-content/loading-content.module';
import { MembershipsModule } from '@shared/components/memberships/memberships.module';
import { SharedModule } from 'app/shared/shared.module';
import { ActivitiesListComponent } from './activities-list/activities-list.component';
import { ActivityCardComponent } from './activities-list/activity-card/activity-card.component';
import { KidsComponent } from './kids.component';
import { KidsRoutingModule } from './kids.routing';
import { TimelineComponent } from './timeline/timeline.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  declarations: [
    KidsComponent,
    ActivitiesListComponent,
    ActivityCardComponent,
    TimelineComponent,
  ],
  imports: [
    CommonModule,
    KidsRoutingModule,
    LoadingContentModule,
    MembershipsModule,
    SharedModule,
    SwiperModule,
  ],
  providers: [
    EvoMembershipService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EvoRedirectInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XAuthorizationInterceptor,
      multi: true,
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
})
export class KidsModule {}
