import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BucketPipe } from './bucket.pipe';

@NgModule({
  declarations: [BucketPipe],
  imports: [CommonModule],
  exports: [BucketPipe],
})
export class BucketModule {}
