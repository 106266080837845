import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccordionModule } from '@directives';
import { SafeHtmlModule } from '@pipes/safe-html/safe-html.module';
import { MembershipOfferPriceComponent } from './membership-offer-price/membership-offer-price.component';
import { MembershipComponent } from './membership.component';

@NgModule({
  declarations: [MembershipComponent, MembershipOfferPriceComponent],
  imports: [CommonModule, RouterModule, AccordionModule, SafeHtmlModule],
  exports: [MembershipComponent],
})
export class MembershipModule {}
