import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidthFromWindowDirective } from './width-from-window.directive';

@NgModule({
  declarations: [WidthFromWindowDirective],
  imports: [CommonModule],
  exports: [WidthFromWindowDirective],
})
export class WidthFromWindowModule {}
