import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';

const policyRoute = [
  {
    path: '',
    component: PrivacyPolicyComponent,
    resolve: {
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.imageHeader.title}',
      windowContext: 'privacy',
      slug: 'privacy-policy',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(policyRoute)],
  exports: [RouterModule],
})
export class PrivacyPolicyRoutingModule {}
