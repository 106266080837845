import { Injectable } from '@angular/core';
import {
  GymFilter,
  GymActivity,
  FullGymActivity,
  GymActivityGroup,
} from '@models';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { AppGraphql } from '@utils/app-graphql';

@Injectable()
export class ActivityService {
  constructor(private readonly apollo: Apollo) {}

  getActiveActivities() {
    return this.apollo
      .query<GymActivity[]>({
        query: AppGraphql.queryListActiveActivities,
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<GymActivity[]>('listActiveActivities'))
      );
  }

  getActiveActivityGroups() {
    return this.apollo
      .query<GymFilter[]>({
        query: AppGraphql.queryListActiveActivityGroups,
      })
      .pipe(
        map(JSONUtil.turnApolloMutable<GymFilter[]>('listActiveActivityGroups'))
      );
  }

  getListActivitiesByGymUnitAndPlan(gymUnitId: number, planId: number) {
    return this.apollo
      .query<GymActivityGroup[]>({
        query: AppGraphql.queryListActivitiesByGymUnitAndPlan,
        variables: { gymUnitId, planId },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<GymActivity[]>(
            'listActivitiesByGymUnitAndPlan'
          )
        ),
        map((activities) => {
          let gymActivityGroup: GymActivityGroup[];
          gymActivityGroup = activities
            .filter(
              (value, index, self) =>
                self
                  .map((x) => x.activityGroup.id)
                  .indexOf(value.activityGroup.id) === index
            )
            .map((value) => value.activityGroup);

          gymActivityGroup.forEach((group) => {
            group.activities = activities.filter(
              (activity) => activity.activityGroup.id === group.id
            );
          });

          return gymActivityGroup;
        })
      );
  }

  getActivity(id: number) {
    return this.apollo
      .query<FullGymActivity>({
        query: AppGraphql.queryGetActivity,
        variables: { id },
      })
      .pipe(map(JSONUtil.turnApolloMutable<FullGymActivity>('activity')));
  }
}
