import { Pipe, PipeTransform } from '@angular/core';

export interface FilesizeArgs {
  unit?: string;
  precision?: number;
}

@Pipe({
  name: 'filesize',
})
export class FilesizePipe implements PipeTransform {
  readonly CONVERSION_RATE = 1024;
  readonly UNITS = ['b', 'kb', 'mb', 'gb'];

  transform(value: number, args: FilesizeArgs = {}): string {
    let unitIndex = this.getStart(args);
    const target = this.UNITS.length - 1;

    while (value > this.CONVERSION_RATE && unitIndex < target) {
      value = value / this.CONVERSION_RATE;
      unitIndex++;
    }

    return `${value.toFixed(args.precision)} ${this.UNITS[unitIndex]}`;
  }

  getStart(args: FilesizeArgs): number {
    const arg = args.unit || 'b';
    return Math.max(this.getIndexOf(arg), 0);
  }

  getIndexOf(arg: string): number {
    return this.UNITS.indexOf(arg.toLowerCase());
  }
}
