<ng-template #header>
  <app-header-static-image [headerObj]="config?.header"></app-header-static-image>
</ng-template>

<div class="main-wrapper container justify-content-around d-flex text-center" *ngIf="!email; else successPage">

  <div class="card small-card row-on-xs no-side-border-xs">
    <form #form="ngForm" class="form form-validate" (submit)="searchEmail()">
      <div class="form-container text-left">
        <div class="title-section text-black">Recuperar e-mail de acesso</div>

        <div>
            Se você não lembra seu e-mail de acesso ao site da {{ brandService.fullBrandName }}, insira seu CPF ou 
            matrícula para recuperá-lo.
        </div>

        <div class="flex sm-column">
          <div class="w-100">
            <label class="text-left text-uppercase">TIPO DE DOCUMENTO</label>
            <app-select
              #docType="ngModel"
              name="documentType"
              [list]="listOptions"
              [(ngModel)]="documentType"
              (ngModelChange)="document = ''"
              [searchable]="false"
              required></app-select>
            <app-error-message [control]="docType"></app-error-message>
          </div>
          <div class="w-100 ml-md-24">
            <label for="document" class="text-left text-uppercase">DOCUMENTO</label>
            <div *ngIf="documentType === 0; else registry">
              <input
                #cpfDoc="ngModel"
                id="cpfDocument"
                name="cpfDocument"
                type="text"
                class="form-control"
                [(ngModel)]="document"
                [imask]="docMask"
                appCpfValidator
                required>
              <app-error-message [control]="cpfDoc"></app-error-message>
            </div>
            <ng-template #registry>
              <input
                #registryDoc="ngModel"
                id="registyDocument"
                name="registryDocument"
                type="number"
                class="form-control"
                [(ngModel)]="document"
                required>
              <app-error-message [control]="registryDoc"></app-error-message>
            </ng-template>
          </div>
        </div>

      </div>

      <div class="button-container row no-gutters">
        <button type="button" id="forgot-cancel" class="col btn btn-outline-primary" routerLink="/cliente/esqueci">
          Voltar
        </button>
        <button type="submit" id="forgot-confirm" class="col btn btn-primary submit-btn">
          Enviar
        </button>
      </div>
    </form>

    <app-registry-hint-modal></app-registry-hint-modal>
  </div>

</div>

<ng-template #successPage>
  <app-success-page
    buttonName="Login"
    pageTitle="Login"
    title="Sucesso"
    message="Seu email: {{ email }}."
    redirectTo="/cliente/login">
  </app-success-page>
</ng-template>
