import { Component, OnInit } from '@angular/core';
import { ModalTabSelectObject, ModalType, Tab } from '@models';
import { ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-tab-select',
  templateUrl: './modal-tab-select.component.html',
  styleUrls: ['./modal-tab-select.component.scss'],
})
export class ModalTabSelectComponent
  extends ModalCommonComponent
  implements OnInit {
  tabs: Tab[];
  modalObj: ModalTabSelectObject;

  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.tabSelect;
  }

  init() {
    this.tabs = this.modalObj.tabs || [];
  }

  select(tab: Tab) {
    this.modalObj.data = tab;
    super.confirm();
  }
}
