import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { EvoRedirectBodyModel } from '@models';
import { AppConstants } from '@utils/app-constants';
import {
  back,
  BackEndpoints,
  evoBack,
  EvoBackEndpoints,
} from '@utils/app-endpoints';

/**
 *  To this interceptor work you must add the XAuthorizationInterceptor at the same module of this interceptor is being add.
 *  The EvoRedirectInterceptor must be added before the XAuthorizationInterceptor at the module.
 */
@Injectable()
export class EvoRedirectInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.isEvoService(request.url)) {
      let gymUnitId = null;

      if (request.params.has(AppConstants.GYM_UNIT_ID)) {
        gymUnitId = request.params.get(AppConstants.GYM_UNIT_ID);

        request = request.clone({
          params: request.params.delete(AppConstants.GYM_UNIT_ID),
        });
      }

      const newBody: EvoRedirectBodyModel = {
        requestUrl: request.urlWithParams,
        requestMethod: request.method as EvoRedirectBodyModel['requestMethod'],
        requestBody: request.body ?? {},
        gymUnitId,
      };

      const backendRequest = request.clone({
        url: back(BackEndpoints.EvoSendRequest),
        params: new HttpParams(),
        body: newBody,
        method: 'POST',
      });

      return next.handle(backendRequest).pipe(retry(2));
    }

    return next.handle(request);
  }

  private isEvoService = (url: string) =>
    url.startsWith(evoBack(EvoBackEndpoints.Root))
}
