import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExperienceContentModel } from '@models';

@Component({
  selector: 'app-gym-experience-details',
  templateUrl: './gym-experience-details.component.html',
  styleUrls: [
    '../gym-experience.component.scss',
    './gym-experience-details.component.scss',
  ],
})
export class GymExperienceDetailsComponent {
  @Input() experienceContent: ExperienceContentModel;
  /**
   * Indicate when this component is visible so the `ngIf` of read more button can make a precise calculation.
   *
   * @default true
   */
  @Input() visible = true;

  @Output() showDescriptionModal = new EventEmitter<ExperienceContentModel>();
}
