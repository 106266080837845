import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModalBtpassPassInfoObject, ModalType } from '@models';
import {
  BtPassPlanInfo,
  IncomeWithGymUnitAndQuantity,
} from '@models/bt-pass.model';
import {
  AuthService,
  BTPassService,
  EvoMembershipService,
  HeaderService,
  ModalService,
} from '@services';
import { HeaderStaticImageComponent } from '@sharedcomponents/header-static-image/header-static-image.component';
import { RequestObject } from '@utils/request-object.class';

@Component({
  selector: 'app-bt-pass',
  templateUrl: './bt-pass.component.html',
  styleUrls: ['./bt-pass.component.scss'],
})
export class BtPassComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('header', { static: false })
  private selectedQuantity: number;
  private buyRoute = '';
  private queryParamsSubscription: Subscription;
  public imageHeader: string;
  public headerTitle: string;

  headerComponent: TemplateRef<HeaderStaticImageComponent>;
  btPassListRO: RequestObject<BtPassPlanInfo[]>;

  constructor(
    private readonly activeRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly btPassService: BTPassService,
    private readonly evoMembershipService: EvoMembershipService,
    private readonly headerService: HeaderService,
    private readonly modalService: ModalService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.imageHeader = this.activeRoute.snapshot.data.imageHeader.image;
    this.headerTitle = this.activeRoute.snapshot.data.imageHeader.title;

    /** This scroll only happens when Bodytech sends an email to customers with the 'anchor'
     *  parameter in the btpass page url. */
    this.queryParamsSubscription = this.activeRoute.queryParams.subscribe(
      (params) => {
        const anchorId = params['anchor'];

        if (anchorId) {
          setTimeout(() => {
            const element = document.getElementById(anchorId);
            const yOffset = -150;
            const y =
              element?.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }, 500);
        }
      }
    );

    this.getBTPass();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  ngOnDestroy(): void {
    this.queryParamsSubscription?.unsubscribe();
  }

  openPassInfoModal(
    passInfoWithGym: Omit<IncomeWithGymUnitAndQuantity, 'quantity'>
  ): void {
    const passInfoWithGymAndQuantity: IncomeWithGymUnitAndQuantity = {
      ...passInfoWithGym,
      quantity: 1,
    };

    this.btPassService
      .getBTPassIncome(
        passInfoWithGym.gymUnitId,
        passInfoWithGym.income.additionalIncomeId
      )
      .subscribe((incomeDetails) => {
        const modal: ModalBtpassPassInfoObject = {
          type: ModalType.btpassPassInfo,
          title: passInfoWithGym.income.planName,
          message: '',
          incomeDetails,
          ...passInfoWithGymAndQuantity,
          confirmCallback: (selectedPass: any) => {
            const selectedGymUnitId = selectedPass.gymUnitId;
            const selectedAdditionalIncomeId = selectedPass.additionalIncomeId;

            this.selectedQuantity = selectedPass.quantity;

            this.buyRoute = `bt-pass/compra/${selectedGymUnitId}/${selectedAdditionalIncomeId}/dados-pessoais`;

            if (this.verifyIfUserIsLogged()) {
              this.router.navigate([this.buyRoute], {
                queryParams: this.getQueryParams(this.router.url),
              });
            } else {
              this.askIfAlreadyBTClient();
            }
          },
        };

        this.modalService.show(modal);
      });
  }

  askIfAlreadyBTClient(): void {
    this.modalService.show({
      type: ModalType.confirm,
      title: 'CLIENTE BODYTECH?',
      message: '<div class="text-center">Você já é cliente Bodytech?</div>',
      confirmButton: 'Sim',
      cancelButton: 'Não',
      confirmCallback: () => {
        const paramNextRoute = `${this.buyRoute}?next=${this.router.url}&quantity=${this.selectedQuantity}`;

        this.router.navigate(['/cliente/login'], {
          queryParams: { next: paramNextRoute },
        });
      },
      cancelCallback: () => {
        this.router.navigate([this.buyRoute], {
          queryParams: this.getQueryParams(this.router.url),
        });
      },
    });
  }

  verifyIfUserIsLogged(): boolean {
    return !!this.authService.getUser();
  }

  private getBTPass(): void {
    this.btPassListRO = new RequestObject(
      this.evoMembershipService
        .getBTPass()
        .pipe(this.evoMembershipService.mapMembershipsToBTPassList())
    );

    this.btPassListRO.observable$.subscribe(
      (response) => (this.btPassListRO.response = response)
    );
  }

  private getQueryParams(next: string): Params {
    return { next, quantity: this.selectedQuantity };
  }
}
