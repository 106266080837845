import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tab } from '@models';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() name = 'tab';
  @Input() textKey = 'name';
  @Input() hasIcon = false;
  @Input() tabs: Tab[] = [];
  @Input() activeTab: Tab;

  @Output() activeTabChange = new EventEmitter<Tab>();
}
