import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './redirect-with-parameter.component.html',
  styleUrls: ['./redirect-with-parameter.component.scss'],
})
export class RedirectWithParameterComponent implements OnInit {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit() {
    let queryParam: [any];

    // Pega apenas os parametros da URL
    this.route.queryParams.forEach((element) => {
      if (queryParam) {
        queryParam.push(element);
      } else {
        queryParam = [element];
      }
    });

    // Transforma o array em um objeto para passar via URL
    const query = queryParam.reduce((a, b) => Object.assign(a, b), {});

    // Pega a url que fará redirecionamento
    const redirectTo = this.route.snapshot.data['redirectTo'];

    this.router.navigate([redirectTo], { queryParams: query });
  }
}
