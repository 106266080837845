import { Component, Input, OnInit } from '@angular/core';
import { KidActivity } from '@models/landing-page-kids.model';

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss'],
})
export class ActivityCardComponent implements OnInit {
  @Input() activityItem: KidActivity;

  shortDescription: string;
  collapsedDescription = false;

  ngOnInit(): void {
    this.shortDescription = `${this.activityItem.description.substring(
      0,
      60
    )}...`;
  }
}
