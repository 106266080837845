import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClickableModule } from '@directives/clickable/clickable.module';
import { GymSearchInputComponent } from './gym-search-input.component';

@NgModule({
  declarations: [GymSearchInputComponent],
  imports: [CommonModule, ClickableModule, FormsModule],
  exports: [GymSearchInputComponent],
})
export class GymSearchInputModule {}
