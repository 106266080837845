import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { DebugBannerComponent } from './debug-banner/debug-banner.component';
import { DebugComponent } from './debug.component';
import { ModalTestComponent } from './modal-test/modal-test.component';
import { RouteTestComponent } from './route-test/route-test.component';
import { ScreenSizeComponent } from './screen-size/screen-size.component';
import { DebugGuideLinesComponent } from './debug-guide-lines/debug-guide-lines.component';
import { DebugViewIdComponent } from './debug-view-id/debug-view-id.component';
import { PopoverFadeModule } from 'app/component-modules/popover-fade/popover-fade.module';

@NgModule({
  imports: [CommonModule, SharedModule, PopoverFadeModule],
  declarations: [
    DebugComponent,
    DebugBannerComponent,
    ScreenSizeComponent,
    ModalTestComponent,
    RouteTestComponent,
    DebugGuideLinesComponent,
    DebugViewIdComponent,
  ],
  exports: [DebugComponent],
})
export class DebugModule {}
