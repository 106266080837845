import { Component, Input } from '@angular/core';
import { FooterHelp } from '@models';

@Component({
  selector: 'app-footer-help',
  templateUrl: './footer-help.component.html',
  styleUrls: ['./footer-help.component.scss'],
})
export class FooterHelpComponent {
  @Input() data: FooterHelp;

  public loadImage = false;
}
