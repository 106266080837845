import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { AlertMessageService } from '@services/alert-message.service';
import { EvoAuthService, EvoGeneralService } from '@services/evo';

export class EvoProviders {
  static AuthServiceProvider(injector: Injector): EvoAuthService {
    return new EvoAuthService(
      injector.get(AlertMessageService),
      injector.get(EvoGeneralService),
      injector.get(HttpClient)
    );
  }
}
