// Imports Angular
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { GalleryModule } from '@ngx-gallery/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  SWIPER_CONFIG,
  SwiperConfigInterface,
  SwiperModule,
} from 'ngx-swiper-wrapper';
import { environment } from '@env/environment';
import { guards } from '@guards';
// Pipes
import { IsOverflownModule, OrderByModule, pipes } from '@pipes';
// Services
import { services } from '@services';
import { ErrorService } from '@services/error.service';
import { AgendaHeaderComponent } from '@sharedcomponents/agenda-header/agenda-header.component';
import { DayOfWeekComponent } from '@sharedcomponents/day-of-week/day-of-week.component';
import { ProductQuantityChoicerModule } from '@sharedcomponents/product-quantity-choicer/product-quantity-choicer.module';
import { StateGymFilterModule } from '@sharedcomponents/state-gym-filter/state-gym-filter.module';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { sur, SurEndpoints } from '@utils/app-endpoints';
import { IMaskModule } from 'angular-imask';
// Imports Libs
import {
  APOLLO_NAMED_OPTIONS,
  APOLLO_OPTIONS,
  ApolloModule,
} from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClientOptions, DefaultOptions } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { OpenModalPaddingModule } from 'app/component-modules/open-modal-padding/open-modal-padding.module';
import { PopoverFadeModule } from 'app/component-modules/popover-fade/popover-fade.module';
// Interceptors
import { AuthInterceptor } from './auth.interceptor';
import { AgendaFilterModule } from './components/agenda-filter/agenda-filter.module';
import { AlertComponent } from './components/alert/alert.component';
import { CheckboxModule } from './components/checkbox/checkbox.module';
import { CollapseComponent } from './components/collapse/collapse.component';
import { DataAddressComponent } from './components/data-address/data-address.component';
import { EmptyCardModule } from './components/empty-card/empty-card.module';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { FaqbotComponent } from './components/faqbot/faqbot.component';
import { GetAppsModule } from './components/get-apps/get-apps.module';
import { GymDetailPayOptionComponent } from './components/gym-detail-pay-option/gym-detail-pay-option.component';
import { GymDetailPlanComponent } from './components/gym-detail-plan/gym-detail-plan.component';
import { GymDetailPlansComponent } from './components/gym-detail-plans/gym-detail-plans.component';
import { GymSearchInputModule } from './components/gym-search-input/gym-search-input.module';
import { HeaderStaticImageModule } from './components/header-static-image/header-static-image.module';
import { LateralTabsComponent } from './components/lateral-tabs/lateral-tabs.component';
import { LoadingComponent } from './components/loading/loading.component';
import {
  ModalBtpassPassInfoModule,
  modalComponents,
  ModalGymActivityModule,
  ModalGymBenefitModule,
  ModalGymPlanModule,
  ModalGymSelectModule,
  ModalSingleSelectModule,
} from './components/modal';
import { NoticeComponent } from './components/notice/notice.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { RedirectWithParameterComponent } from './components/redirect-with-parameter/redirect-with-parameter.component';
import { RegistryHintModalComponent } from './components/registry-hint-modal/registry-hint-modal.component';
import { SelectComponent } from './components/select/select.component';
import { SliderComponent } from './components/slider/slider.component';
import { SocialLoginButtonsComponent } from './components/social-login-buttons/social-login-buttons.component';
import { SuccessPageComponent } from './components/success-page/success-page.component';
import { TabsModule } from './components/tabs/tabs.module';
import { VideoCardComponent } from './components/video-card/video-card.component';
import { WhatsappButtonComponent } from './components/whatsapp-button/whatsapp-button.component';
// Other Imports and namespaces
import { directives, LazyCreateModule } from './directives';
import { ClickableModule } from './directives/clickable/clickable.module';
import { DropdownArrowsModule } from './directives/dropdown-arrows/dropdown-arrows.module';
import { BucketModule } from './pipe/bucket/bucket.module';
import { CapitalizeModule } from './pipe/capitalize/capitalize.module';
import { FilterActivityModule } from './pipe/filter-activity/filter-activity.module';
import { FormatModule } from './pipe/format/format.module';
import { FuseModule } from './pipe/fuse/fuse.module';

registerLocaleData(localePt, 'pt-BR');

const globalComponents = [
  AgendaHeaderComponent,
  AlertComponent,
  CollapseComponent,
  DayOfWeekComponent,
  FaqbotComponent,
  LateralTabsComponent,
  LoadingComponent,
  PaginationComponent,
  RadiobuttonComponent,
  RecaptchaComponent,
  RedirectWithParameterComponent,
  SelectComponent,
  SliderComponent,
  SuccessPageComponent,
  VideoCardComponent,
  NoticeComponent,
];

const sharedComponents = [
  DataAddressComponent,
  ErrorMessageComponent,
  GymDetailPayOptionComponent,
  GymDetailPlanComponent,
  GymDetailPlansComponent,
  RegistryHintModalComponent,
  SocialLoginButtonsComponent,
  WhatsappButtonComponent,
];

export function createApollo(
  httpLink: HttpLink,
  errorService: ErrorService
): ApolloClientOptions<any> {
  const http = httpLink.create({ uri: sur(SurEndpoints.Url) });
  const logoutLink = errorService.apolloOnError();

  const defaultOptions: DefaultOptions = {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  };

  return {
    link: logoutLink.concat(http as any),
    cache: new InMemoryCache(),
    defaultOptions,
  };
}

export function createNamedApollo(
  httpLink: HttpLink,
  errorService: ErrorService
) {
  const http = httpLink.create({
    uri: environment.apollo.microservicesBaseUrl,
  });
  const logoutLink = errorService.apolloOnError('second');
  const httpDocmod = httpLink.create({
    uri: environment.docmod.microservicesBaseUrl,
  });
  const logoutLinkDocmod = errorService.apolloOnError('docModService');
  const auth = setContext(() => {
    return {
      headers: {
        'x-api-key': environment.docmod.xApiKey,
        Authorization: '',
      },
    };
  });
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  return {
    second: {
      name: 'second',
      link: ApolloLink.from([logoutLink, http as any]),
      cache: new InMemoryCache(),
      defaultOptions,
    },
    docModService: {
      name: 'docModService',
      link: ApolloLink.from([logoutLinkDocmod, auth, httpDocmod as any]),
      cache: new InMemoryCache(),
      defaultOptions,
    },
  };
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
};

@NgModule({
  imports: [
    AgendaFilterModule,
    ApolloModule,
    BucketModule,
    CapitalizeModule,
    CommonModule,
    CheckboxModule,
    ClickableModule,
    DeferLoadModule,
    DropdownArrowsModule,
    EmptyCardModule,
    FilterActivityModule,
    GetAppsModule,
    FormsModule,
    FormatModule,
    FuseModule,
    GalleryModule,
    GymSearchInputModule,
    HeaderStaticImageModule,
    HttpClientModule,
    HttpLinkModule,
    IMaskModule,
    IsOverflownModule,
    LazyCreateModule,
    ModalGymPlanModule,
    ModalModule,
    ModalBtpassPassInfoModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ModalGymActivityModule,
    ModalGymBenefitModule,
    ModalGymSelectModule,
    ModalSingleSelectModule,
    ModalModule,
    NgSelectModule,
    OpenModalPaddingModule,
    PopoverFadeModule,
    ProductQuantityChoicerModule,
    ReactiveFormsModule,
    RouterModule,
    StateGymFilterModule,
    SwiperModule,
    OrderByModule,
    TabsModule,
  ],
  declarations: [
    ...directives,
    ...globalComponents,
    ...modalComponents,
    ...pipes,
    ...sharedComponents,
  ],
  exports: [
    ...directives,
    ...globalComponents,
    ...modalComponents,
    ...pipes,
    ...sharedComponents,
    AgendaFilterModule,
    BucketModule,
    CapitalizeModule,
    CheckboxModule,
    ClickableModule,
    DeferLoadModule,
    DropdownArrowsModule,
    EmptyCardModule,
    FilterActivityModule,
    GetAppsModule,
    FormatModule,
    FuseModule,
    GymSearchInputModule,
    HeaderStaticImageModule,
    IMaskModule,
    LazyCreateModule,
    ModalBtpassPassInfoModule,
    MatDatepickerModule,
    ModalGymActivityModule,
    ModalGymBenefitModule,
    ModalGymSelectModule,
    ModalSingleSelectModule,
    StateGymFilterModule,
    ProductQuantityChoicerModule,
    NgSelectModule,
    TabsModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
    DatePipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: APOLLO_OPTIONS,
          useFactory: createApollo,
          deps: [HttpLink, ErrorService],
        },
        {
          provide: APOLLO_NAMED_OPTIONS,
          useFactory: createNamedApollo,
          deps: [HttpLink, ErrorService],
        },
        ...services,
        ...guards,
        ...pipes,
      ],
    };
  }
}
