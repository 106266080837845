<div class="section background-image-section">
  <div class="background-image"></div>

  <div class="container">
    <h2 class="row title-footer justify-content-center">PREENCHA OS DADOS PARA CONVENIAR SUA EMPRESA</h2>

    <div class="card row">
      <div class="form-validate">
        <form #form="ngForm">
          <!-- INFORMAÇÕES PESSOAIS -->
          <div class="text-help">
              Preencha os dados abaixo para que façamos a análise da sua empresa,
              e em até 3 dias úteis nosso consultor corporativo entrará em contato com uma resposta.
              Importante: não esqueça de preencher corretamente os dados da pessoa responsável por
              benefícios e parcerias na sua empresa.
          </div>
          <h3 class="title section mt-0">Informações pessoais</h3>

          <div class="row form-row">
            <div class="col-12 col-md-6">
                <label for="corporate-contact-name">Nome</label>
                <input id="corporate-contact-name"
                  name="name"
                  class="form-control"
                  autocomplete="name"
                  [(ngModel)]="info.contact.personalInformationName"
                  #contactName="ngModel"
                  required minlength="3">
                  <app-error-message [control]="contactName" [messages]="{ contactName: 'É necessário inserir um nome' }"></app-error-message>
            </div>

            <div class="col-12 col-md-6">
              <label for="corporate-contact-phone">Telefone</label>
              <input id="corporate-contact-phone"
                name="phone"
                class="form-control"
                autocomplete="tel tel-national home mobile"
                [(ngModel)]="info.contact.personalInformationPhone"
                [imask]="phoneMask"
                minlength="14" maxlength="15"
                #contactPhone="ngModel"
                required>
                <app-error-message [control]="contactPhone" [messages]="{ contactPhone: 'É necessário inserir um telefone com DDD' }"></app-error-message>
            </div>
          </div>

          <div class="row form-row">
            <div class="col-12 col-md-6">
              <label for="corporate-contact-email">E-mail</label>
              <input id="corporate-contact-email"
                name="email"
                class="form-control"
                autocomplete="email"
                [(ngModel)]="info.contact.personalInformationEmail"
                #contactEmail="ngModel"
                required
                [pattern]="emailRegexp"
              >
              <app-error-message [control]="contactEmail"
                [messages]="{
                  contactEmail: 'É necessário inserir um e-mail',
                  pattern: 'E-mail inválido'
                }"
              ></app-error-message>
            </div>
          </div>

          <!-- SOBRE A EMPRESA -->
          <h3 class="title section">Sobre a empresa</h3>

          <div class="row form-row">
            <div class="col-12 col-md-6">
                <label class="mt-md-0" for="corporate-company-name">Nome Fantasia</label>
                <input id="corporate-company-name"
                  name="company"
                  class="form-control"
                  autocomplete="company"
                  [(ngModel)]="info.company.companyName"
                  #companyName="ngModel"
                  minlength="3"
                  required>
                  <app-error-message [control]="companyName" [messages]="{ companyName: 'O nome válido a partir de 3 caracteres' }"></app-error-message>
            </div>
            <div class="col-12 col-md-6">
                <label class="mt-md-0" for="corporate-company-cnpj">CNPJ</label>
                <input id="corporate-company-cnpj"
                  name="cnpj"
                  class="form-control"
                  autocomplete="company"
                  [imask]="cnpjMask"
                  [(ngModel)]="info.company.companyCnpj"
                  #companyCnpj="ngModel"
                  minlength="18"
                  maxlength="18"
                  required>
                  <app-error-message [control]="companyCnpj" [messages]="{ companyCnpj: 'Deve inserir um CNPJ válido' }"></app-error-message>
            </div>
          </div>

          <div class="row form-row">
            <div class="col-12 col-md-6">
              <label class="mt-md-0" for="corporate-company-state">Estado</label>
              <app-select id="corporate-company-state"
                name="corporate-company-state"
                class="form-custom"
                [(ngModel)]="info.company.companyState"
                (ngModelChange)="selectState($event)"
                #companyState="ngModel"
                [list]="states"
                [disabled]="_loadingStates"
                textForUndefined="Selecione o estado"
                textForDisabledUndefined="carregando..."
                ngDefaultControl
                required>
              </app-select>
              <app-error-message [control]="companyState" [messages]="{ companyState: 'Campo obrigatório!' }"></app-error-message>
            </div>
            <div class="col-12 col-md-6">
                <label class="mt-md-0" for="corporate-company-site">Site</label>
                <input id="corporate-company-site"
                  name="corporate-company-site"
                  class="form-control"
                  autocomplete="company"
                  [(ngModel)]="info.company.companySite"
                  #companySite="ngModel"
                  minlength="3"
                  required>
                  <app-error-message [control]="companySite" [messages]="{ companySite: 'Válido a partir de 3 caracteres' }"></app-error-message>
            </div>
          </div>

          <!-- SOBRE A ACADEMIA -->
          <h3 class="title section">Responsável da empresa por parcerias e benefícios</h3>
          <div class="row form-row">
              <div class="col-12 col-md-6">
                  <label class="mt-md-0" for="corporate-responsible-name">Nome</label>
                  <input id="corporate-responsible-name"
                    name="responsible-name"
                    class="form-control"
                    [(ngModel)]="info.responsible.responsibleName"
                    #responsibleName="ngModel"
                    minlength="3"
                    required>
                    <app-error-message [control]="responsibleName" [messages]="{ responsibleName: 'Válido a partir de 3 caracteres' }"></app-error-message>
              </div>
              <div class="col-12 col-md-6">
                  <label class="mt-md-0" for="corporate-responsible-post">Cargo</label>
                  <input id="corporate-responsible-post"
                    name="responsible-post"
                    class="form-control"
                    [(ngModel)]="info.responsible.responsiblePost"
                    #responsiblePost="ngModel"
                    minlength="3"
                    required>
                    <app-error-message [control]="responsiblePost" [messages]="{ responsiblePost: 'Válido a partir de 3 caracteres' }"></app-error-message>
              </div>
            </div>

            <div class="row form-row">
              <div class="col-12 col-md-6">
                <label for="corporate-responsible-phone" class="label-input">Telefone</label>
                <input id="corporate-responsible-phone"
                  [imask]="phoneMask"
                  class="form-control"
                  name="responsible-phone"
                  [(ngModel)]="info.responsible.responsiblePhone"
                  minlength="14" maxlength="15"
                  #responsiblePhone="ngModel"
                  required>
                  <app-error-message [control]="responsiblePhone" [messages]="{ responsiblePhone: 'Insira um telefone com DDD' }"></app-error-message>
              </div>
              <div class="col-12 col-md-6">
                  <label for="corporate-responsible-email">E-mail</label>
                  <input id="corporate-responsible-email"
                    name="responsible-email"
                    class="form-control"
                    autocomplete="email"
                    [(ngModel)]="info.responsible.responsibleEmail"
                    #responsibleEmail="ngModel"
                    email
                    required>
                    <app-error-message [control]="responsibleEmail" [messages]="{ responsibleEmail: 'É necessário inserir um email' }"></app-error-message>
              </div>
            </div>
        </form>
      </div>

      <!-- CHECKBOX DE ACEITAÇÃO DO TERMO DE ADESÃO -->
      <div class="section row terms">
        <div class="col-12">
          <div class="d-flex justify-content-center">
            <a class="title" href="/assets/data/press-files/termo_de_adesao.pdf" target="_blank" rel="noopener">
              <span class="link file-name">CLIQUE AQUI E VEJA O TERMO DE ADESÃO</span>
            </a>
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-center terms-checkbox">
            <app-checkbox
            name="one-click-shop"
            [(check)]="acceptTerms"
            message="Aceito o termo de adesão"
            ></app-checkbox>
          </div>
        </div>
      </div>

      <!-- BOTÕES DE CONFIRMAÇÃO -->
      <div class="section row button-container">
        <div class="col-12 text-center">
          <div class="g-container d-inline-block">
            <app-recaptcha #recaptcha name="corporate-recaptcha"></app-recaptcha>
          </div>
        </div>

        <div class="col-12 text-center">
          <button id="corporate-confirm" class="btn btn-primary btn-confirm"  (click)="send()">Enviar
            <img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=1230521&conversionId=1010844&fmt=gif" />
            <img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=1230521&conversionId=1098465&fmt=gif" />
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
