import { ActivatedRoute } from '@angular/router';
import { BasicHeaderConfig } from '@models/configs';
import {
  Component,
  AfterViewInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { HeaderService } from '@services';

@Component({
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements AfterViewInit {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  public config: BasicHeaderConfig;

  constructor(
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute
  ) {
    this.config = this.route.snapshot.data.config.notFound;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }
}
