import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ResolveResult } from './type-resolve-bundle';
import { GymWithKidsPlans } from '@models';
import { LandingKidsService } from '@services';
import { map } from 'rxjs/operators';

@Injectable()
export class GymNameWithKidsListResolveGuard
  implements Resolve<GymWithKidsPlans[]> {
  constructor(private readonly landingKidsService: LandingKidsService) {}

  resolve(): ResolveResult<GymWithKidsPlans[] | any[]> {
    return this.landingKidsService
      .listOnlyGymUnitsWithBabyKidsPlansFromView()
      .pipe(
        map((gyms) =>
          gyms
            .map((gym) => {
              if (gym.gymUnitPortalName) {
                return {
                  ...gym,
                  portalName: gym.gymUnitPortalName,
                  slug: gym.gymUnitSlug,
                  id: gym.gymUnitId,
                };
              }
            })
            .sort(
              (actualGym: GymWithKidsPlans, gymToCompare: GymWithKidsPlans) => {
                return actualGym.portalName.localeCompare(
                  gymToCompare.portalName
                );
              }
            )
        )
      );
  }
}
