import { Component, Input, OnDestroy } from '@angular/core';
import { GymAgendaFilter, KidsTimetable } from '@models';
import { AgendaService } from '@services/agenda.service';
import { LandingKidsService } from '@services/landing-kids.service';
import { Subscription } from 'rxjs';
import { filterTimetable, formatTimetable } from './timeline-format';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnDestroy {
  @Input() filter: GymAgendaFilter = {} as GymAgendaFilter;

  readonly weekstart = 1;
  readonly today: number = 0;

  _left = 0;

  get left() {
    return this._left;
  }
  set left(val: number) {
    this._left = val;
  }

  daysColHeader = AgendaService.WEEKDAYS;
  isLoading = true;
  eventTable: any[] = [];
  timesRowHeader: string[] = [];
  originalTimetable: KidsTimetable[];
  _hoveringColumn: number;
  subscription: Subscription;

  get hoveringColumn() {
    return this._hoveringColumn;
  }
  set hoveringColumn(val: number) {
    if (this._hoveringColumn !== val) {
      this._hoveringColumn = val;
    }
  }

  constructor(private kidsService: LandingKidsService) {
    this.today = new Date().getDay() - this.weekstart;
    this.subscription = this.kidsService.invalidateTimetable.subscribe(
      (state) => {
        if (state) {
          this.kidsService.triggerTimetableInvalidation(false);
          this.populateAgenda();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public populateAgenda() {
    this.timesRowHeader = [];
    this.isLoading = true;
    this.eventTable = [];

    if (!this.filter.gym) {
      this.isLoading = false;
      return;
    }

    const id = this.filter.gym.id;
    const activities = this.filter.activities;
    const ages = this.filter.ages;

    this.kidsService.getKidsTimetable(id).subscribe(
      (json) => {
        this.originalTimetable = JSON.parse(JSON.stringify(json));
        this.eventTable = json;

        if (typeof Worker !== 'undefined') {
          const worker = new Worker('./timetable.worker', { type: 'module' });
          worker.onmessage = ({ data }) => {
            this.eventTable = data;
          };
          worker.postMessage({ timetable: this.eventTable, activities, ages });
        } else {
          this.eventTable = formatTimetable(this.eventTable);
          if (activities.length > 0 || ages.length > 0) {
            this.eventTable = filterTimetable(
              activities,
              ages,
              this.eventTable
            );
          }
        }

        setTimeout(() => {
          this.isLoading = false;
        }, 0);
      },
      () => {
        this.isLoading = false;
      }
    );
  }
}
