import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { SelectItem } from '@models';
import { UserProfileService } from '@services';
import { ResolveResult } from './type-resolve-bundle';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ProfileCivilResolveGuard implements Resolve<SelectItem[]> {
  constructor(private readonly profileService: UserProfileService) {}

  resolve(): ResolveResult<SelectItem[]> {
    return this.profileService.getCivilState().pipe(
      catchError(() => {
        return of(null);
      })
    );
  }
}
