import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RecoveryEmailRequest } from '@models';
import { BasicHeaderConfig } from '@models/configs';
import {
  AlertMessageService,
  AuthService,
  HeaderService,
  LoadingService,
} from '@services';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';
import { FormatUtils } from '@utils/format-utils';

@Component({
  templateUrl: './change-email.component.html',
  styleUrls: ['../../area-user.component.scss'],
})
export class ChangeEmailComponent implements AfterViewInit {
  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  public config: BasicHeaderConfig;

  readonly docMask = AppConstants.Mask.doc;

  recoveryEmail: RecoveryEmailRequest = {
    cpf: null,
    registration: null,
  };
  hasToken = false;

  constructor(
    private readonly authService: AuthService,
    private readonly headerService: HeaderService,
    private readonly loadingService: LoadingService,
    private readonly alertMessageService: AlertMessageService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    this.config = this.activatedRoute.snapshot.data.config.config;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  requestChangeEmailToken() {
    FormUtil.touchForm(this.form.control);

    if (this.form.invalid) {
      return;
    }

    this.loadingService.startLoading();

    const _recoveryEmail = {
      ...this.recoveryEmail,
      cpf: FormatUtils.getCPFWithoutSpecialCharacters(this.recoveryEmail.cpf),
    };

    this.authService.getResetUserAccessToken(_recoveryEmail).subscribe(
      (value) => {
        localStorage.setItem(
          AppConstants.STOR_RESET_USER_ACESS_TOKEN,
          value.TokenDadosAcesso
        );
        this.hasToken = true;
      },
      (err: any) => this.alertMessageService.showToastr(err),
      () => this.loadingService.stopLoading()
    );
  }
}
