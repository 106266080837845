import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImageHeaderResolve } from '@guards/image-header-resolve.guard';
import { PrivacyCenterComponent } from './privacy-center.component';

const privacyCenterRoute = [
  {
    path: '',
    component: PrivacyCenterComponent,
    resolve: {
      imageHeader: ImageHeaderResolve,
    },
    data: {
      windowTitle: '${data.imageHeader.title}',
      windowContext: 'privacy-center',
      slug: 'privacy-center',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(privacyCenterRoute)],
  exports: [RouterModule],
})
export class PrivacyCenterRoutingModule {}
