import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  SwiperComponent,
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from 'ngx-swiper-wrapper';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import {
  ActivityGroupModel,
  ActivityModel,
  ExperienceContentModel,
  ExperienceResquestsObjectsModel,
  GymModel,
  ModalType,
  Tab,
} from '@models';
import { ActivityService } from '@services/activity.service';
import { BreakpointsService } from '@services/breakpoints.service';
import { EvoGymExperienceService } from '@services/evo';
import { LoadingService } from '@services/loading.service';
import { ModalService } from '@services/modal.service';
import { GymExperienceTabs } from '@utils/gym-util';
import { RequestObject } from '@utils/request-object.class';

@Component({
  selector: 'app-gym-experience',
  templateUrl: './gym-experience.component.html',
  styleUrls: ['./gym-experience.component.scss'],
})
export class GymExperienceComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('swiper') swiperElement: QueryList<SwiperComponent>;

  @Input() loadingLabel: string;
  @Input() legacyGym?: GymModel;
  @Input() migratedGym?: GymModel;

  private breakpointServiceSubscription: Subscription;
  readonly gymExperienceTabs = GymExperienceTabs;
  readonly isBT = environment.brand === 'bt';

  tabs: Tab[] = [
    { id: GymExperienceTabs.Activities, name: 'Atividades' },
    { id: GymExperienceTabs.Services, name: 'Serviços' },
    { id: GymExperienceTabs.Conveniences, name: 'Conveniências' },
  ];

  private _activeTab: Tab = this.tabs[GymExperienceTabs.Activities];

  set activeTab(newValue: Tab) {
    this._activeTab = newValue;

    this.changeTab();
  }
  get activeTab(): Tab {
    return this._activeTab;
  }

  swiperConfig: SwiperConfigInterface = {
    allowSlideNext: true,
    allowSlidePrev: true,
    direction: 'horizontal',
    loop: false,
    navigation: true,
    pagination: false,
    slidesPerGroup: 4,
    slidesPerView: 4,
    threshold: 10,
  };

  tabContent: ExperienceResquestsObjectsModel = {
    [GymExperienceTabs.Activities]: new RequestObject<ActivityGroupModel[]>(),
    [GymExperienceTabs.Services]: [],
    [GymExperienceTabs.Conveniences]: [],
  };

  constructor(
    private readonly breakpointsService: BreakpointsService,
    private readonly evoGymExperienceService: EvoGymExperienceService,
    private readonly legacyActivityService: ActivityService,
    private readonly loadingService: LoadingService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.requestActivities();
    this.setServices();

    if (this.legacyGym.conveniences.length) {
      this.setConveniences();

      return;
    }

    this.tabs.pop();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.subscribeToActivities());
  }

  ngOnDestroy(): void {
    this.breakpointServiceSubscription?.unsubscribe();
  }

  changeTab(): void {
    setTimeout(() => this.updateSwiperPagination());
  }

  showDescriptionModal(
    contentToShow: ActivityModel | ExperienceContentModel
  ): void {
    if (
      this.activeTab.id === GymExperienceTabs.Activities &&
      !this.legacyGym.evoMigratedUnit
    ) {
      this.loadingService.startLoading();

      this.legacyActivityService
        .getActivity((contentToShow as ActivityModel).legacyId)
        .pipe(finalize(() => this.loadingService.stopLoading()))
        .subscribe((activity) => {
          this.modalService.show({
            title: activity.name,
            type: ModalType.confirm,
            message: activity.description,
          });
        });

      return;
    }

    this.modalService.show({
      title: contentToShow.name,
      type: ModalType.confirm,
      message: contentToShow.description,
    });
  }

  private requestActivities(): void {
    this.tabContent[GymExperienceTabs.Activities] = new RequestObject(
      this.legacyGym.evoMigratedUnit
        ? this.evoGymExperienceService.getActivities({
            gymUnitId: this.migratedGym.id.toString(),
          })
        : this.evoGymExperienceService.convertLegacyToEvo(
            this.legacyGym.activities
          )
    );
  }

  private setConveniences(): void {
    this.tabContent[GymExperienceTabs.Conveniences] =
      this.legacyGym.conveniences.map((convenience) => ({
        ...convenience,
      }));
  }

  private setServices(): void {
    this.tabContent[GymExperienceTabs.Services] =
      this.legacyGym.gymUnitServices.map((service) => ({
        ...service.service,
        isFree: service.freeCost,
      }));
  }

  private subscribeToActivities(): void {
    this.tabContent[GymExperienceTabs.Activities].observable$.subscribe(
      (response) => {
        this.tabContent[GymExperienceTabs.Activities].response = response;

        this.setSlidesPerView();
      }
    );
  }

  private updateSwiperPagination(): void {
    const paginationElementSelector = '.swiper-pagination';
    const pagination: SwiperPaginationInterface = {
      bulletActiveClass: 'swiper-pagination-bullet-dark',
      clickable: true,
      el: paginationElementSelector,
      type: 'bullets',
    };

    this.swiperConfig.pagination = pagination;

    this.swiperElement.toArray()[this.activeTab.id]?.directiveRef.update();
  }

  private setSlidesPerView(): void {
    this.breakpointServiceSubscription =
      this.breakpointsService.emitter.subscribe((breakpoint) => {
        this.swiperConfig.slidesPerView = breakpoint - 1 || 1;
        this.swiperConfig.slidesPerGroup = this.swiperConfig.slidesPerView;

        this.updateSwiperPagination();
      });
  }
}
