import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MyPurchasesResponse, PreSalePurchase, PreSaleDetail } from '@models';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { JSONUtil } from '@utils/json-util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class MyPurchasesService {
  constructor(private readonly http: HttpClient) {}

  getInfo(): Observable<MyPurchasesResponse> {
    return this.http.get<any[]>(back(BackEndpoints.Purchase)).pipe(
      map((json) => {
        const result: MyPurchasesResponse = { purchases: [] };
        if (!json || !json.length) {
          return result;
        }

        json.forEach(
          (item) => (item.date = JSONUtil.getDate(item, 'DataCompra'))
        );
        json.sort((a, b) => b.date.getTime() - a.date.getTime());

        json.forEach((compra) => {
          const itens: any[] = JSONUtil.get(
            compra,
            'ListaItemHistoricoFinanceiro'
          );
          itens.forEach((item) =>
            result.purchases.push({
              date: compra.date,
              code: JSONUtil.get(compra, 'CodigoCompra'),
              gym: JSONUtil.get(compra, 'NomeUnidade'),
              item: JSONUtil.get(item, 'DescricaoItem'),
              value: JSONUtil.get(item, 'ValorItem'),
              status: JSONUtil.get(item, 'StatusHistorico'),
              shoppingCartId: JSONUtil.get(compra, 'IdCarrinhoCompra'),
              hasDocuSign: JSONUtil.get(compra, 'InCompraBTPassDocusign'),
              gymId: JSONUtil.get(compra, 'IdUnidade'),
            })
          );
        });
        return result;
      })
    );
  }

  getPreSaleInfo(): Observable<PreSalePurchase[]> {
    return this.http.get<any[]>(back(BackEndpoints.PreSalePurchase)).pipe(
      map((json) => {
        const result: PreSalePurchase[] = [];
        if (!json || !json.length) {
          return result;
        }

        json.forEach(
          (item) => (item.date = JSONUtil.getDate(item, 'DataInclusao'))
        );
        json.sort((a, b) => b.date.getTime() - a.date.getTime());

        json.forEach((preSale) => {
          const detail: PreSaleDetail[] = [];
          let itens: any[] = JSONUtil.get(preSale, 'Planos');

          itens.forEach((item) =>
            detail.push({
              type: JSONUtil.get(item, 'Plano'),
              periodicity: JSONUtil.get(item, 'Periodicidade'),
              schedule: JSONUtil.get(item, 'Horario'),
              value: JSONUtil.get(item, 'Valor'),
              isPlan: true,
            })
          );

          itens = JSONUtil.get(preSale, 'Receitas') || [];
          itens.forEach((item) =>
            detail.push({
              type: JSONUtil.get(item, 'Receita'),
              periodicity: '--',
              schedule: '--',
              value: JSONUtil.get(item, 'Valor'),
              isPlan: false,
            })
          );

          result.push({
            date: preSale.date,
            code: JSONUtil.get(preSale, 'Id'),
            idGymUnit: JSONUtil.get(preSale, 'IdUnidade'),
            gymUnit: JSONUtil.get(preSale, 'Unidade'),
            value: JSONUtil.get(preSale, 'Valor'),
            stats: JSONUtil.get(preSale, 'Status'),
            details: detail,
          });
        });

        return result;
      })
    );
  }

  cancelPresalePurchase(preSalePurchase: PreSalePurchase) {
    return this.http.get<any>(
      back(BackEndpoints.PreSaleCancel, preSalePurchase.idGymUnit)
    );
  }
}
