import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateHightlightDirective } from './template-hightlight.directive';

@NgModule({
  declarations: [TemplateHightlightDirective],
  imports: [CommonModule],
  exports: [TemplateHightlightDirective],
})
export class TemplateHightlightModule {}
