import { FormGroup } from '@angular/forms';

export class FormUtil {
  static touchForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((campo) => {
      const controle = formGroup.get(campo);
      controle.markAsTouched();
      controle.markAsDirty();
      controle.updateValueAndValidity();
      if (controle instanceof FormGroup) {
        this.touchForm(controle);
      }
    });
  }

  static resetForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((campo) => {
      const controle = formGroup.get(campo);
      controle.markAsUntouched();
      controle.markAsPristine();
      controle.updateValueAndValidity();
      if (controle instanceof FormGroup) {
        this.resetForm(controle);
      }
    });
  }
}
