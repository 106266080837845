import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ResolveResult } from './type-resolve-bundle';
import { Gym } from '@models';
import { GymService } from '@services';

@Injectable()
export class GymNameListResolveGuard implements Resolve<Gym[]> {
  constructor(private readonly gymService: GymService) {}

  resolve(): ResolveResult<Gym[]> {
    return this.gymService.getGymNames(true);
  }
}
