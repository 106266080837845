import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { JSONUtil } from '@utils/json-util';
import { map } from 'rxjs/operators';
import { AppGraphql } from '@utils/app-graphql';

@Injectable()
export class BannerService {
  constructor(private readonly apollo: Apollo) {}

  public listCurrentPortalHomeBannerHeaders() {
    return this.apollo
      .query<any>({
        query: AppGraphql.queryListPortalHomeBannerHeaders,
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<any>('listCurrentPortalHomeBannerHeaders')
        )
      );
  }
}
