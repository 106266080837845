import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrandService } from '@services/brand.service';
import { NewRecoveryAccountStages } from '../new-recovery-account.component';

@Component({
  selector: 'app-remember-email',
  templateUrl: './remember-email.component.html',
  styleUrls: ['../../area-user.component.scss'],
})
export class RememberEmailComponent {
  newRecoveryAccountStages = NewRecoveryAccountStages;

  @Input() user: any;
  @Input() currentStage: NewRecoveryAccountStages;

  queryParams: any;

  @Output() currentStageChange = new EventEmitter<NewRecoveryAccountStages>();

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    public readonly activatedRoute: ActivatedRoute
  ) {
    this.queryParams = this.activatedRoute.snapshot.queryParams;
  }

  goToUpdateExistingAccountStage() {
    this.currentStage = this.newRecoveryAccountStages.UpdateExistingAccount;
    this.currentStageChange.emit(this.currentStage);
  }
}
