<ng-select
  class="border-validate"
  id="sel-{{ name }}"
  [disabled]="_disabled"
  (blur)="onBlur()"
  appendTo="body"
  placeholder="{{ _disabled ? textForDisabledUndefined : textForUndefined }}"
  [items]="list"
  bindLabel="text"
  [bindValue]="key"
  [(ngModel)]="value"
  (change)="selectObj($event)"
  [clearable]="selectedClearable"
  loadingText="carregando..."
  notFoundText="Item não encontrado"
  [searchable]="searchable"
  [isOpen]="isOpen"
  (focus)="focus.emit($event)"
></ng-select>
