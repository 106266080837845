import { Component, Input } from '@angular/core';
import {
  AgendaEvent,
  AgendaResponse,
  GymAgendaFilter,
  ModalAgendaObject,
  ModalType,
} from '@models';
import { AgendaService, LoadingService, ModalService } from '@services';
import { ArrayUtil } from '@utils/array-util';
import { FormatUtils } from '@utils/format-utils';

@Component({
  selector: 'app-agenda-timeline-ie',
  templateUrl: './agenda-timeline-ie.component.html',
  styleUrls: ['./agenda-timeline-ie.component.scss'],
})
export class AgendaTimelineIEComponent {
  @Input() filter: GymAgendaFilter = {} as GymAgendaFilter;

  daysColHeader = AgendaService.WEEKDAYS;
  isLoading = false;
  eventTable: AgendaEvent[][][] = [];
  timesRowHeader: string[] = [];

  constructor(
    private readonly agendaService: AgendaService,
    private readonly modalService: ModalService,
    private readonly loadingService: LoadingService
  ) {}

  public populateAgenda() {
    this.eventTable = [];
    this.timesRowHeader = [];
    this.isLoading = true;

    if (!this.filter.gym) {
      return;
    }

    const id = this.filter.gym.id;
    const activities = this.filter.activities.map((i) => i.id);
    const ages = this.filter.ages.map((i) => i.id);

    this.agendaService.getEvents(id, activities, ages).subscribe((json) => {
      const eventsByHour = this.getEventsByHourFromJson(json);
      const eventsByHourByDay = this.groupEventsByHourByDay(eventsByHour);

      for (const eventRow of eventsByHourByDay) {
        this.insertEventRowIntoArrays(eventRow);
      }

      setTimeout(() => (this.isLoading = false), 0);
    });
  }

  private getEventsByHourFromJson(json: AgendaResponse): AgendaEvent[][] {
    const allEvents: AgendaEvent[][] = [];
    for (const column of json.days) {
      for (const backendEvent of column.events) {
        // Creating a copy and converting dayOfTheWeek to index
        const frontendEvent = Object.assign({}, backendEvent, {
          dayOfTheWeek: json.days.indexOf(column),
        });
        ArrayUtil.safePushIntoMap(
          allEvents,
          this.getTimeInMinutes(frontendEvent),
          frontendEvent
        );
      }
    }

    return ArrayUtil.mapToArray(allEvents);
  }

  private getTimeInMinutes(event: AgendaEvent) {
    return event.activityInitialTimeHour * 60 + event.activityInitialTimeMinute;
  }

  private groupEventsByHourByDay(
    eventsByHour: AgendaEvent[][]
  ): AgendaEvent[][][] {
    const eventsByHourByDay = [];

    for (const eventsHour of eventsByHour) {
      const eventsCells = this.groupEventsByDay(eventsHour);
      eventsByHourByDay.push(eventsCells);
    }

    return eventsByHourByDay;
  }

  private groupEventsByDay(eventsByHourRow: AgendaEvent[]): AgendaEvent[][] {
    const eventsByDay: AgendaEvent[][] = this.initEventsByDay();

    for (const event of eventsByHourRow) {
      eventsByDay[event.dayOfTheWeek].push(event);
    }
    return ArrayUtil.mapToArray(eventsByDay);
  }

  private initEventsByDay(): AgendaEvent[][] {
    return this.daysColHeader.map((_) => []);
  }

  private insertEventRowIntoArrays(eventRow: AgendaEvent[][]) {
    const firstEvent = ArrayUtil.getFirstNonNullElementFromMap(eventRow);
    const insertTime = firstEvent.activityInitialTimeFormatted;
    let insertIndex = 0;

    while (
      insertIndex < this.timesRowHeader.length &&
      this.timesRowHeader[insertIndex] < insertTime
    ) {
      insertIndex++;
    }

    this.fillWithEmptyEventsToFillTable(eventRow);

    ArrayUtil.insertIntoIndex(this.timesRowHeader, insertIndex, insertTime);
    ArrayUtil.insertIntoIndex(this.eventTable, insertIndex, eventRow);
  }

  fillWithEmptyEventsToFillTable(eventRow: AgendaEvent[][]) {
    const maxLenght = eventRow.reduce(
      (max, value) => (max = Math.max(max, value.length)),
      0
    );
    eventRow.forEach((value) =>
      value.splice(value.length, 0, ...Array(maxLenght - value.length).fill({}))
    );
  }

  showEvent(gridEvent: AgendaEvent) {
    this.loadingService.startLoading();
    this.agendaService
      .getEventById(
        gridEvent.id,
        gridEvent.gridActivityId,
        gridEvent.dayOfTheWeek
      )
      .subscribe((event) => {
        this.loadingService.stopLoading();
        event.audiences.forEach(
          (age) =>
            (age.description = FormatUtils.ageRangeToDescription(
              age.minimumAge,
              age.maximumAge
            ))
        );
        event.audiences.sort((a, b) => a.minimumAge - b.minimumAge);
        const modal: ModalAgendaObject = {
          type: ModalType.agenda,
          title: event.itemGridTitle,
          message: '',
          event,
        };
        this.modalService.show(modal);
      });
  }
}
