import { Component, Input } from '@angular/core';
import { ActionBtItem } from '@models';

@Component({
  selector: 'app-action-bt-item',
  templateUrl: './action-bt-item.component.html',
  styleUrls: ['./action-bt-item.component.scss'],
})
export class ActionBtItemComponent {
  @Input() public action: ActionBtItem;
}
