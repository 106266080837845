<ng-template #header>
  <app-header-static-image
    [headerObj]="config.header"
  ></app-header-static-image>
</ng-template>

<main class="main-wrapper container">
  <app-empty-card
    icon="/assets/icons_custom/ic-empty-cloud.svg"
    altIcon="Página não encontrada"
    message="Esta página não existe ou encontra-se indisponível.<br>
    Verifique sua conexão ou tente novamente mais tarde."
    buttonText="Voltar para home"
    buttonLink="/"
  ></app-empty-card>
</main>
