import { Injectable, isDevMode } from '@angular/core';
import { AlertMessage, MessageMap, ModalType } from '@models';
import {
  MicroserviceErrorEnum,
  MicroserviceErrorHighlight,
  MicroserviceErrorsToHighlight,
} from '@utils/app-errors';
import { ApolloLink } from 'apollo-link';
import { ErrorResponse, onError } from 'apollo-link-error';
import { AlertMessageService } from './alert-message.service';
import { ModalService } from './modal.service';

@Injectable()
export class ErrorService {
  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly modalService: ModalService
  ) {}

  public apolloOnError(graphQLEnv: string = 'main'): ApolloLink {
    return onError((apolloError) => {
      if (isDevMode) {
        console.log('Caught from Apollo', apolloError);
      }

      if (apolloError?.graphQLErrors) {
        for (const err of apolloError.graphQLErrors) {
          if (this.ignoreFollowingErrors(apolloError, err)) {
            this.translateCodesToMessage(graphQLEnv, err);
          }
        }
      }

      if (apolloError?.networkError) {
        this.alertMessageService.showToastr(
          AlertMessage.error(MessageMap.SEM_CONEXAO_INTERNET),
          [apolloError.networkError.message]
        );
      }
    });
  }

  private ignoreFollowingErrors(apolloError: ErrorResponse, err: any): boolean {
    return (
      !this.isFindUserMicrosservice(apolloError, err) &&
      !this.isLambdaError(err) &&
      !this.isAuth(apolloError, err)
    );
  }

  private isAuth(apolloError: ErrorResponse, graphQLError: any): boolean {
    const operationName = apolloError.operation.operationName;
    const errorCode = graphQLError.errorInfo?.code;
    return (
      (operationName === 'getStatusDoc' && errorCode === 6) ||
      operationName === 'auth'
    );
  }

  private isFindUserMicrosservice(
    apolloError: ErrorResponse,
    graphQLError: any
  ): boolean {
    const operationName = apolloError.operation.operationName;
    const errorCode = graphQLError.errorInfo?.code;
    return (
      (operationName === 'findUserByCpf' ||
        operationName === 'findUserByRegistration') &&
      (errorCode === MicroserviceErrorEnum.ApiUserNotRegisteredError ||
        this.isFindUserNotFoundErorInFormula(errorCode))
    );
  }

  private isFindUserNotFoundErorInFormula(errorCode: number): boolean {
    return errorCode === MicroserviceErrorEnum.ApiInvalidDataError;
  }

  private isLambdaError(graphQLError: any): boolean {
    const lambdaError = graphQLError.errorType === 'Lambda:Unhandled';
    if (lambdaError) {
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.SERVIDOR_INDISPONIVEL)
      );
    }
    return lambdaError;
  }

  private translateCodesToMessage(graphQLEnv: string, err: any): void {
    if (graphQLEnv === 'main') {
      if (
        err.message.includes(
          'br.com.bodytech.core.backend.Address.zipcode.invalid'
        )
      ) {
        this.alertMessageService.showToastr(
          AlertMessage.warning(MessageMap.CEP_INVALIDO),
          null
        );
      } else {
        this.alertMessageService.showToastr(
          AlertMessage.warning(MessageMap.ERRO_PROCESSAMENTO),
          [err.message]
        );
      }
    } else {
      const highlightError = MicroserviceErrorsToHighlight.find(
        (error) => error.errorType === err.errorType
      );
      if (highlightError) {
        this.showErrorInModal(err.message, highlightError);
        return;
      }
      this.alertMessageService.showToastr(AlertMessage.error(err.message));
    }
  }

  private showErrorInModal(
    errorMessage: string,
    highlightError: MicroserviceErrorHighlight
  ): void {
    this.modalService.show({
      title: highlightError.title,
      type: ModalType.confirm,
      message: errorMessage,
    });
  }
}
