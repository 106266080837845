import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { FranchiseRequest, ResponseFutureFranchiseeCreate } from '@models';
import { AppGraphql } from '@utils/app-graphql';
import { DateUtil } from '@utils/date-util';
import { JSONUtil } from '@utils/json-util';

@Injectable()
export class FranchiseService {
  constructor(private readonly apollo: Apollo) {}

  registerFranchisee(franchiseeData: FranchiseRequest) {
    const newFranchisee: FranchiseRequest = JSON.parse(
      JSON.stringify(franchiseeData)
    );

    // Remove máscara do CPF
    newFranchisee.cpf = newFranchisee.cpf.replace(/[-.]/g, '');

    // Se usuário não tem imóvel disponível deleta endereço de imóvel da requisição
    newFranchisee.franchiseInformation.desiredCity = {
      id: newFranchisee.franchiseInformation.propertyAddress.city.id,
    };

    if (newFranchisee.franchiseInformation.availableProperty === false) {
      delete newFranchisee.franchiseInformation.propertyAddress;
    }
    // Converte data do formato brasileiro (DD/MM/YYYY) para o formato ISO (YYYY-MM-DDThh:mm:ss.sssZ)
    newFranchisee.birthDate = DateUtil.dateToISO(newFranchisee.birthDate);

    // Remove chave de telefone fixo ou celular, caso um deles não seja fornecido
    if (!newFranchisee.cellPhone) {
      delete newFranchisee.cellPhone;
    }
    if (!newFranchisee.landlinePhone) {
      delete newFranchisee.landlinePhone;
    }

    return this.apollo
      .mutate<ResponseFutureFranchiseeCreate>({
        mutation: AppGraphql.mutationFutureFranchiseeCreate,
        variables: {
          data: newFranchisee,
        },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<ResponseFutureFranchiseeCreate>(
            'futureFranchiseeCreate'
          )
        )
      );
  }
}
