import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appCpfValidator]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CpfValidatorDirective, multi: true },
  ],
})
export class CpfValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } {
    if (control.value) {
      const cpf = control.value.replace(/\D/g, '');
      const numberEquals = cpf.replace(/(\d)\1{10}/, '') === '';

      if (
        !this.validator(cpf, 9, 11) ||
        !this.validator(cpf, 10, 12) ||
        numberEquals
      ) {
        return { cpfNotValid: true };
      }

      return null;
    }

    return { cpfNotValid: true };
  }

  validator(cpf: string, index: number, index2: number) {
    let sum = 0;
    let remainder = 0;

    for (let i = 1; i <= index; i++) {
      sum += Number(cpf.substring(i - 1, i)) * (index2 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== Number(cpf.substring(index, index + 1))) {
      return false;
    }

    return true;
  }
}
