import { Component, OnInit } from '@angular/core';
import { ModalAgendaObject, ModalType } from '@models';
import { ModalService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-agenda',
  templateUrl: './modal-agenda.component.html',
  styleUrls: ['./modal-agenda.component.scss'],
})
export class ModalAgendaComponent
  extends ModalCommonComponent
  implements OnInit {
  modalObj: ModalAgendaObject;

  constructor(protected modalService: ModalService) {
    super();
    this.type = ModalType.agenda;
  }
}
