<div
  class="modal fade"
  bsModal
  #templateModal="bs-modal"
  [config]="{ backdrop: 'static', keyboard: false }"
  (onHidden)="dismiss()"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" *ngIf="modalObj">
    <div class="modal-content">
      <div class="modal-header">
        <div
          class="modal-header-title title-section no-bottom-line w-100 text-center"
        >
          Horários
        </div>
      </div>
      <div class="modal-body">
        <div>
          <div class="title modal-title">Segunda a sexta</div>
          <div class="times">
            das {{ schedule.weekday[0] }}h às {{ schedule.weekday[1] }}h
          </div>
          <div class="slider"></div>
        </div>
        <div>
          <div class="title modal-title">Sábado</div>
          <div class="times">
            das {{ schedule.saturday[0] }}h às {{ schedule.saturday[1] }}h
          </div>
          <div class="slider"></div>
        </div>
        <div>
          <div class="title modal-title">Domingo</div>
          <div class="times">
            das {{ schedule.sunday[0] }}h às {{ schedule.sunday[1] }}h
          </div>
          <div class="slider"></div>
        </div>
        <div>
          <div class="title modal-title">Feriado</div>
          <div class="times">
            das {{ schedule.holiday[0] }}h às {{ schedule.holiday[1] }}h
          </div>
          <div class="slider"></div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="col btn btn-{{ modalObj?.confirmButtonClass || 'primary' }}"
          (click)="confirm()"
        >
          {{ modalObj?.confirmButton || "OK" }}
        </button>
      </div>
    </div>
  </div>
</div>
