import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-content',
  template: `
    <div class="title font-weight-normal" [ngClass]="classes">
      <i class="fas fa-pulse fa-spinner"></i>{{ loadingLabel }}
    </div>
  `,
  styles: ['i { margin-right: 6px; }'],
})
export class LoadingContentComponent {
  @Input() loadingLabel = 'Carregando';
  @Input() classes?: string;
}
