import { Component, OnInit } from '@angular/core';
import { Gym, GymFilter } from '@models';
import { AppConstants } from '@utils/app-constants';
import { CorporateService } from '@services';
import { StringUtil } from '@utils/string-util';
import { CorporatePrices } from '@models/corporate-prices';

@Component({
  selector: 'app-corporate-gym-filter',
  templateUrl: './corporate-gym-filter.component.html',
  styleUrls: ['./corporate-gym-filter.component.scss'],
})
export class CorporateGymFilterComponent implements OnInit {
  locale = AppConstants.LOCALE;
  activityGroups: GymFilter[] = [];
  activityTags: GymFilter[] = [];
  corporateData: CorporatePrices[];
  filteredCorporateData: CorporatePrices[];
  gymsList: Gym[];
  selectedCity = '';
  selectedState = '';

  constructor(private readonly corporateService: CorporateService) {}

  ngOnInit() {
    this.corporateService.getCorporatePrices().subscribe((data) => {
      data.forEach((d) => (d.gymExhibition = `${d.city} - ${d.gym}`));
      data.sort((a, b) => StringUtil.strcmp(a.gymExhibition, b.gymExhibition));

      this.corporateData = data;
      this.filteredCorporateData = data;
    });
  }

  setFilteredCoporateData(data: CorporatePrices[]) {
    this.filteredCorporateData = data;
  }
}
