import { Injectable } from '@angular/core';
import {
  OnlineClassSchedule,
  OnlineClassDateAvailableToSchedule,
  OnlineClassTeacher,
  OnlineClassHasAccess,
} from '@models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { HttpClient } from '@angular/common/http';
import { back, BackEndpoints } from '@utils/app-endpoints';
import { OnlineClassRequest } from '@models/requests/online-class-request.model';
import { UserAvailabilityToSchedule } from '@models/bt-home.model';

@Injectable()
export class MyOnlineClassSchedulesService {
  private isAvailiableHourToScheduleList = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apollo: Apollo,
    private readonly http: HttpClient
  ) {}

  setIsvailiableHourToScheduleList(isAvailable: boolean) {
    this.isAvailiableHourToScheduleList.next(isAvailable);
  }

  listenIsvailiableHourToScheduleList() {
    return this.isAvailiableHourToScheduleList;
  }

  userHasAccessToOnlineClass(): Observable<boolean> {
    return this.http
      .get<OnlineClassHasAccess>(back(BackEndpoints.HasAccessToOnlineClass))
      .pipe(map((result) => result.hasAccessToAppointmentLiveClasses));
  }

  getAllOnlineClassSchedulingByClient(
    registration: number
  ): Observable<OnlineClassSchedule[]> {
    return this.apollo
      .query({
        query: AppGraphql.queryListAllAppointmentLiveClassByClient,
        variables: {
          registration,
        },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<OnlineClassSchedule[]>(
            'listAllAppointmentLiveClassByClient'
          )
        )
      );
  }

  getAvailiableHourToScheduleList(
    registration: number
  ): Observable<OnlineClassDateAvailableToSchedule[]> {
    return this.apollo
      .query({
        query: AppGraphql.queryListAvailableHourToScheduleLiveClass,
        variables: {
          registration,
        },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<OnlineClassDateAvailableToSchedule[]>(
            'listAvailableHourToScheduleLiveClass'
          )
        )
      );
  }

  listTeachersAvaliable(
    date: Date,
    hourId: number
  ): Observable<OnlineClassTeacher[]> {
    return this.apollo
      .query({
        query: AppGraphql.queryListAvailableTeacherToLiveClass,
        variables: {
          date,
          hourId,
        },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable<OnlineClassTeacher[]>(
            'listAllTeacherAvailableToScheduleLiveClass'
          )
        )
      );
  }

  createScheduling(scheduleRequest: OnlineClassRequest) {
    return this.apollo
      .mutate({
        mutation: AppGraphql.mutationCreatelLiveClass,
        variables: {
          teacherId: scheduleRequest.teacherId,
          hourId: scheduleRequest.availableHourId,
          date: scheduleRequest.scheduledDate,
          userRegistration: scheduleRequest.userRegistration,
        },
      })
      .pipe(map(JSONUtil.turnApolloMutable<any>('createAppointmentLiveClass')));
  }

  cancelSchedule(id: number, registration: number): Observable<any> {
    return this.apollo
      .mutate({
        mutation: AppGraphql.mutationCancelLiveClass,
        variables: {
          id,
          registration,
        },
      })
      .pipe(map(JSONUtil.turnApolloMutable<any>('cancelAppointmentLiveClass')));
  }

  getUserAvailabilityToSchedule(registration: number) {
    return this.apollo
      .query({
        query: AppGraphql.queryGetUserAvailabilityToSchedule,
        variables: {
          registration,
        },
      })
      .pipe(
        map(JSONUtil.turnApolloMutable('getUserAvailabilityToSchedule')),
        map((res: UserAvailabilityToSchedule) => {
          return {
            canSchedule: res.canSchedule,
            userAvailabilityToSchedule: {
              totalOnlineSchedules: {
                currentMonth: res.maxCapacity,
                nextMonth: res.nextMonth ? res.maxCapacity : null,
              },
              numberOfSchedulesAlreadyMade: {
                currentMonth:
                  res.maxCapacity - res.currentMonth.numberOfAvailableClasses,
                nextMonth: res.nextMonth
                  ? res.maxCapacity - res.nextMonth.numberOfAvailableClasses
                  : null,
              },
              month: {
                currentMonth: res.currentMonth.monthText,
                nextMonth: res.nextMonth
                  ? res.nextMonth.monthText
                  : res.nextMonth,
              },
            },
          };
        })
      );
  }

  listAllWeekDaysWithGroupClassesByClient(registration: number) {
    return this.apollo
      .query({
        query: AppGraphql.queryListAllWeekDaysWithGroupClassesByClient,
        variables: {
          registration,
        },
      })
      .pipe(
        map(
          JSONUtil.turnApolloMutable('listAllWeekDaysWithGroupClassesByClient')
        )
      );
  }

  createFavoriteGroupClass(groupClassId: number, registration: number) {
    return this.apollo
      .mutate({
        mutation: AppGraphql.mutationCreateFavoriteGroupClass,
        variables: {
          groupClassId,
          registration,
        },
      })
      .pipe(map(JSONUtil.turnApolloMutable('createFavoriteGroupClass')));
  }

  deleteFavoriteGroupClass(favoriteGroupClassId: number) {
    return this.apollo
      .mutate({
        mutation: AppGraphql.mutationDeleteFavoriteGroupClass,
        variables: {
          favoriteGroupClassId,
        },
      })
      .pipe(map(JSONUtil.turnApolloMutable('deleteFavoriteGroupClass')));
  }

  listAllFavoriteGroupClassByClient(registration: number) {
    return this.apollo
      .mutate({
        mutation: AppGraphql.queryListAllFavoriteGroupClassByClient,
        variables: {
          registration,
        },
      })
      .pipe(
        map(JSONUtil.turnApolloMutable('listAllFavoriteGroupClassByClient'))
      );
  }
}
