import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MembershipOfferModel } from '@models';
import { AppConstants } from '@utils/app-constants';
import { MembershipStatus } from '@utils/gym-util';

@Component({
  selector: 'app-membership-offer-price',
  templateUrl: './membership-offer-price.component.html',
  styleUrls: ['./membership-offer-price.component.scss'],
})
export class MembershipOfferPriceComponent implements OnInit {
  @Input() membershipOffer: MembershipOfferModel;
  @Input() membershipTitle: string;
  @Input() checkGymFee?: boolean;
  @Input() hasSubscribeButton?: boolean;
  /**
   * This value is control with `evo-membership.service`.
   *
   * @todo Remove when EVO migration finish.
   */
  @Input() legacy?: boolean;
  @Input() specialOfferLabel?: string;
  /** Define the offer with a highlight style. This change is only apply once when the component's render. */
  @Input() highlightOffer?: boolean;

  /** It's triggered when click on a join button. Most use for analytcs. */
  @Output() clickJoinButton = new EventEmitter<void>();

  readonly membershipStatus = MembershipStatus;
  readonly locale = AppConstants.LOCALE;

  @HostBinding('class') cssClass = 'flex-fill';
  @HostBinding('class.offer-highlight') hasHighlightOffer: boolean;

  ngOnInit(): void {
    this.hasHighlightOffer = this.highlightOffer;
  }
}
