import { Injectable } from '@angular/core';
import { AgendaEvent, AgendaEventDetails, AgendaResponse } from '@models';
import { AppGraphql } from '@utils/app-graphql';
import { JSONUtil } from '@utils/json-util';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AgendaService {
  static readonly startIntensityService = 1;
  static readonly WEEKDAYS = [
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
    'Domingo',
  ];

  constructor(private readonly apollo: Apollo) {}

  getEvents(
    gym: number,
    activities: number[],
    ages: number[]
  ): Observable<AgendaResponse> {
    return this.apollo
      .query<any>({
        query: AppGraphql.queryListSchedules,
        variables: {
          gym,
          activities,
          ages,
        },
      })
      .pipe(
        map((data) => {
          const schedule =
            JSONUtil.turnApolloMutable<AgendaEvent[]>('listSchedules')(data);

          const result = {
            days: [],
          } as AgendaResponse;

          for (const weekDay of AgendaService.WEEKDAYS) {
            result.days.push({
              day: weekDay,
              events: schedule
                .filter((i) => (i.dayOfTheWeek || 'Never').includes(weekDay))
                .map((event) => ({
                  ...event,
                })),
            });
          }
          return result;
        })
      );
  }

  mapIntToWeekday(weekDayId: string) {
    try {
      const weekday = +weekDayId;
      if (!weekday) {
        throw new Error('Not expected');
      }
      return AgendaService.WEEKDAYS[
        (7 - AgendaService.startIntensityService + weekday) % 7
      ];
    } catch {
      return 'Never';
    }
  }

  indexName(activityId: number, weekDay: string) {
    return `${activityId}#${weekDay || 'Never'}`;
  }

  getEventById(
    id: number,
    activityId: number,
    weekDay: string
  ): Observable<AgendaEventDetails> {
    return this.apollo
      .query<any>({
        query: AppGraphql.querySchedule,
        variables: {
          id,
          activityId,
          weekDay:
            AgendaService.WEEKDAYS.indexOf(weekDay) +
            AgendaService.startIntensityService,
        },
      })
      .pipe(
        map((response) => ({
          ...JSONUtil.turnApolloMutable<any>('schedule')(response),
        })),
        tap(
          (schedule) =>
            (schedule.targetAudienceLevelList =
              schedule.targetAudienceLevel.split(';#'))
        )
      );
  }

  getSchedulerDetailByItemGridId(
    itemGridId: number,
    activityId: number,
    weekDay: string
  ): Observable<AgendaEventDetails> {
    return this.apollo
      .query<any>({
        query: AppGraphql.queryListSchedulesByItemGridId,
        variables: {
          itemGridId,
          activityId,
          weekDay,
        },
      })
      .pipe(
        map((response) => {
          const scheduler: AgendaEventDetails = { audiences: [] };
          const schedules = JSONUtil.turnApolloMutable<any[]>(
            'listSchedulesByItemGridId'
          )(response);
          schedules.forEach((schedule) => {
            scheduler.id = schedule.id;
            scheduler.itemGridTitle = schedule.itemGridTitle;
            scheduler.teacherName = schedule.teacherName;
            scheduler.isOutdoorPlace = schedule.isOutdoorPlace;
            scheduler.itemGridId = schedule.itemGridId;
            scheduler.itemGridPlace = schedule.itemGridPlace;
            scheduler.targetAudienceLevel = schedule.targetAudienceLevel;
            scheduler.duration = schedule.duration;
            scheduler.activityInitialTimeFormatted =
              schedule.activityInitialTimeFormatted;
            scheduler.audiences.push(schedule.audiences[0]);
          });
          scheduler.intensity = {
            ...JSONUtil.turnApolloMutable<any>(
              'getIntensityByActivityIdAndWeekDay'
            )(response),
          };

          return scheduler;
        }),
        tap(
          (schedule) =>
            (schedule.targetAudienceLevelList =
              schedule.targetAudienceLevel.split(';#'))
        )
      );
  }
}
