import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  DoubtArea,
  City,
  CovidFaq,
  ModalType,
  ModalSingleSelectObject,
} from '@models';
import {
  FaqPageService,
  HeaderService,
  AddressService,
  ModalService,
} from '@services';
import { StringUtil } from '@utils/string-util';
import { BrandService } from '@services/brand.service';

@Component({
  selector: 'app-faq-city',
  templateUrl: './faq-city.component.html',
  styleUrls: ['./faq-city.component.scss'],
})
export class FaqCityComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;

  imageHeader: string;
  headerTitle: string;
  activeTab: DoubtArea;
  selectedCity: City;
  cities: City[];
  doubts: Observable<CovidFaq[]>;
  companyName = '';
  brand = '';

  constructor(
    private readonly headerService: HeaderService,
    private readonly route: ActivatedRoute,
    private readonly addressService: AddressService,
    private readonly faqService: FaqPageService,
    private readonly modalService: ModalService,
    private readonly brandService: BrandService
  ) {}

  ngOnInit() {
    this.imageHeader = this.route.snapshot.data.imageHeader.image;
    this.headerTitle = this.route.snapshot.data.imageHeader.title;
    this.addressService.listCitiesWithGymUnits().subscribe((data) => {
      this.cities = data;
      this.cities.sort((a: City, b: City) =>
        StringUtil.strcmp(a.state.initials + a.name, b.state.initials + b.name)
      );
    });
    this.companyName =
      this.brandService.getBrand() === 'bt'
        ? 'Bodytech Company'
        : 'Fórmula Academia';
    this.brand = this.brandService.getBrand() === 'bt' ? 'Bodytech' : 'Fórmula';
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  showCitySelectModal() {
    this.modalService.show({
      type: ModalType.singleSelect,
      title: 'Selecione a cidade',
      message: '',
      confirmCallback: (city: any) => {
        this.selectedCity = this.cities.find(
          (c) => this.displayCityAndState(c) === city
        );
        this.doubts = this.faqService.getFAQByCity(this.selectedCity.id);
      },
      data: this.cities.map((c) => this.displayCityAndState(c)),
      selected: this.selectedCity,
    } as ModalSingleSelectObject);
  }

  displayCityAndState(city: City): string {
    if (!city) {
      return null;
    }
    return `${city.state.initials} - ${city.name}`;
  }
}
