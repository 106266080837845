import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GetAppsModule } from '@sharedcomponents/get-apps/get-apps.module';
import { LoadingContentModule } from '@sharedcomponents/loading-content/loading-content.module';
import { ModalGymsAppComponent } from './modal-gyms-app.component';

@NgModule({
  declarations: [ModalGymsAppComponent],
  imports: [CommonModule, GetAppsModule, LoadingContentModule, ModalModule],
  exports: [ModalGymsAppComponent],
})
export class ModalGymsAppModule {}
