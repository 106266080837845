import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { SchemaOrg } from '@utils/json-ld-functions';
import { Brand, BrandService } from './brand.service';
import { GymService } from './gym.service';
import { PortalConfigService } from './portal-config.service';

const DEFAULT_META_ROBOTS = 'index,follow';
const DEFAULT_SCRIPT_JSONLD = {};

@Injectable()
export class SEOService {
  private brand: Brand;
  private readonly ELEMENT_META_DESCRIPTION: HTMLMetaElement;
  private readonly ELEMENT_META_ROBOTS: HTMLMetaElement;
  private readonly ELEMENT_SCRIPT_JSONLD: HTMLScriptElement;
  private static interpolate(str: string, data: Data): string {
    try {
      // tslint:disable-next-line:no-eval
      return templateString ? templateString(str, { data }) : eval(`\`${str}\``);
    } catch {
      console.warn('Interpolate error on SEOService.\nEval failed for: ' + str);
      return '';
    }
  }

  constructor(
    private readonly titleService: Title,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly gymService: GymService,
    private readonly portalConfigService: PortalConfigService,
    private readonly brandService: BrandService
  ) {
    this.ELEMENT_META_DESCRIPTION = document.querySelector(
      'head meta[name="description"]'
    );
    this.ELEMENT_META_ROBOTS = document.querySelector(
      'head meta[name="robots"]'
    );
    this.ELEMENT_SCRIPT_JSONLD = document.querySelector(
      'head script[type="application/ld+json"]'
    );
    this.brand = this.brandService.getBrand();
  }

  public init() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        switchMap((route) => route.data)
      )
      .subscribe((data: Data) => {
        this.setGeneralSEO(data);
      });
  }

  public setGeneralSEO(data: Data, addSuffix = true): void {
    this.setTitle(data, addSuffix);
    this.setDescription(data);
    this.setRobots(data);
    this.setJSONLDfn(data.jsonldfn, data);
  }

  public setTitle(data: Data, addSuffix = true) {
    let title = '';
    title = data.windowTitle || '';
    addSuffix =
      typeof data.addSuffix === 'boolean' ? data.addSuffix : addSuffix;

    title = SEOService.interpolate(title, data);
    this.setTitleStr(title, addSuffix);
  }

  public setTitleStr(str: string, addSuffix = true) {
    if (addSuffix && str) {
      str += ' | ' + environment.seo.DEFAULT_TITLE;
    }
    this.titleService.setTitle(str || environment.seo.DEFAULT_TITLE);
  }

  public setDescription(data: Data) {
    let description = data.metaDescription || '';
    description = SEOService.interpolate(description, data);
    this.setDescriptionStr(description);
  }

  public setDescriptionStr(str: string) {
    this.ELEMENT_META_DESCRIPTION.content =
      str || environment.seo.DEFAULT_META_DESCRIPTION;
  }

  public setRobots(data: Data) {
    let robots;

    if (data.slug && this.brand === 'fr') {
      this.portalConfigService
        .getPortalConfiguration<string[]>('deleted-gyms')
        .pipe(catchError(() => this.gymService.getDeletedGyms()))
        .subscribe((response: string[]) => {
          robots = SEOService.interpolate(data.slug, data);
          robots = response ? response.indexOf(robots) !== -1 ? 'noindex,nofollow' : 'index,follow' : '';

          this.setRobotsStr(robots);
        });
    } else {
      robots = SEOService.interpolate(data.metaRobots || '', data);
      this.setRobotsStr(robots);
    }
  }

  public setRobotsStr(str: string) {
    this.ELEMENT_META_ROBOTS.content = str || DEFAULT_META_ROBOTS;
  }

  public setJSONLDfn(fn: (data: Data) => SchemaOrg, data: Data) {
    this.setJSONLD(fn ? fn(data) : null);
  }

  public setJSONLD(obj: SchemaOrg) {
    try {
      this.ELEMENT_SCRIPT_JSONLD.innerHTML = JSON.stringify(
        obj ? obj : DEFAULT_SCRIPT_JSONLD,
        null,
        2
      );
    } catch (e) {
      if (e instanceof SyntaxError) {
        this.ELEMENT_SCRIPT_JSONLD.innerHTML = JSON.stringify(
          DEFAULT_SCRIPT_JSONLD,
          null,
          2
        );
      } else {
        throw e;
      }
    }
  }
}
