import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isOverflown',
})
export class IsOverflownPipe implements PipeTransform {
  transform(value: HTMLDivElement, isReady: boolean): any {
    if (!isReady) {
      return false;
    }

    return value.offsetHeight < value.scrollHeight;
  }
}
