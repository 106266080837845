import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  EvoRedirectInterceptor,
  XAuthorizationInterceptor,
} from '@interceptors';
import { RemoveBrandModule } from '@pipes/remove-brand/remove-brand.module';
import { SafeHtmlModule } from '@pipes/safe-html/safe-html.module';
import {
  EvoGeneralService,
  EvoGymService,
  EvoMembershipService,
} from '@services/evo';
import { AgendaFilterModule } from '@shared/components/agenda-filter/agenda-filter.module';
import { HeaderStaticImageModule } from '@shared/components/header-static-image/header-static-image.module';
import { ErrorModule } from '@sharedcomponents/error/error.module';
import { LoadingContentModule } from '@sharedcomponents/loading-content/loading-content.module';
import { MembershipsModule } from '@sharedcomponents/memberships/memberships.module';
import { GymsSharedModule } from '../gyms-shared/gyms-shared.module';
import { GymExperienceModule } from './gym-experience/gym-experience.module';
import { GymImagesCarouselModule } from './gym-images-carousel/gym-images-carousel.module';
import { GymComponent } from './gym.component';
import { GymRoutingModule } from './gym.routing';

@NgModule({
  declarations: [GymComponent],
  imports: [
    CommonModule,
    AgendaFilterModule,
    ErrorModule,
    GymExperienceModule,
    GymImagesCarouselModule,
    GymsSharedModule,
    HeaderStaticImageModule,
    LoadingContentModule,
    MembershipsModule,
    RemoveBrandModule,
    SafeHtmlModule,
    GymRoutingModule,
  ],
  providers: [
    EvoGeneralService,
    EvoGymService,
    EvoMembershipService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EvoRedirectInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XAuthorizationInterceptor,
      multi: true,
    },
  ],
})
export class GymModule {}
