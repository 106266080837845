import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { TicketComponent } from './ticket.component';
import { TicketRoutingModule } from './ticket.routing';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, TicketRoutingModule],
  declarations: [TicketComponent],
})
export class TicketModule {}
