import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionDirective } from './accordion.directive';

@NgModule({
  declarations: [AccordionDirective],
  imports: [CommonModule],
  exports: [AccordionDirective],
})
export class AccordionModule {}
