import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { GymFilter } from '@models';
import { ActivityService } from '@services/activity.service';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class GymGroupActivitiesResolveGuard implements Resolve<GymFilter[]> {
  constructor(private readonly activityService: ActivityService) {}

  resolve(): ResolveResult<GymFilter[]> {
    return this.activityService.getActiveActivityGroups();
  }
}
