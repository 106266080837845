import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { maximumAgeValidator } from '@directives/maximum-age.directive';
import { minimumAgeValidator } from '@directives/minimum-age.directive';
import {
  KidsResponsible,
  KidsSchedule,
  KidsWaitingList,
  ModalKidsScheduleObject,
  ModalType,
  ScheduleClassResponse,
} from '@models';
import { CapitalizePipe } from '@pipes';
import { LandingKidsService, LoadingService, ModalService } from '@services';
import { AppConstants } from '@utils/app-constants';
import { DateUtil } from '@utils/date-util';
import { FormUtil } from '@utils/form-util';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-kids-schedule',
  templateUrl: './modal-kids-schedule.component.html',
  styleUrls: ['./modal-kids-schedule.component.scss'],
  providers: [CapitalizePipe],
})
export class ModalKidsScheduleComponent
  extends ModalCommonComponent
  implements OnInit {
  readonly GENERIC_ERROR =
    'Não foi possível completar sua solicitação no momento';
  readonly successSchedule = {
    title: 'AULA EXPERIMENTAL AGENDADA!',
  };
  readonly successWaitingList = {
    title: 'VOCÊ ENTROU NA LISTA DE ESPERA',
  };
  readonly SCHEDULE_RESPONSES = {
    error: 'ERROR',
    ok: 'OK',
  };
  readonly cpfMask = AppConstants.Mask.doc;
  readonly phoneMask = AppConstants.Mask.phone;
  readonly dateMask = AppConstants.Mask.date;
  readonly emailRegexp = AppConstants.EMAIL_REGEXP;

  modalObj: ModalKidsScheduleObject;
  alreadyBodytechClient = false;
  kidDoesNotHasCpf = false;
  childrenCpf: FormControl;
  scheduleForm: FormGroup;
  validCpf = false;
  showScheduleForm = false;
  cachedData: any;

  constructor(
    protected modalService: ModalService,
    protected formBuilder: FormBuilder,
    protected kidsService: LandingKidsService,
    protected loadingService: LoadingService,
    protected capitalizePipe: CapitalizePipe
  ) {
    super();
    this.type = ModalType.kidsSchedule;
    this.subsModal = this.modalService.modalEmitter.subscribe(
      (modalObj: ModalKidsScheduleObject) => {
        if (modalObj && modalObj.type === this.type) {
          this.modalObj = modalObj;
        } else {
          this.modal.hide();
        }
      }
    );
  }

  init() {
    this.validateInitModal();
  }

  validateInitModal(): void {
    this.scheduleForm = this.formBuilder.group({
      fatherCpf: new FormControl(null, Validators.required),
      fatherName: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          '^[a-zA-Z\u00C0-\u017F-]{3,}(?: [a-zA-Z\u00C0-\u017F-]+){1,9}$'
        ),
      ]),
      fatherEmail: new FormControl(null, [
        Validators.required,
        Validators.pattern(this.emailRegexp),
      ]),
      fatherPhoneNumber: new FormControl(null, Validators.required),
      alreadyBodytechClient: new FormControl(false),
      childrenName: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          '^[a-zA-Z\u00C0-\u017F-]{3,}(?: [a-zA-Z\u00C0-\u017F-]+){1,9}$'
        ),
      ]),
      childrenBirthday: new FormControl(null, [
        Validators.required,
        maximumAgeValidator(11),
        minimumAgeValidator(0),
      ]),
    });

    this.childrenCpf = new FormControl('');
    this.showScheduleForm = true;

    this.config = this.modalObj.config || {};
    this.validCpf = !this.modalObj.selectedDate.event?.podeAgendar;

    this.cachedData = this.modalObj.cachedData;
    const fatherCpf = this.getCachedResponsibleCPF();
    if (fatherCpf) {
      this.scheduleForm?.controls?.fatherCpf.setValue(fatherCpf);
    }

    if (
      !this.modalObj.selectedDate.event?.podeAgendar &&
      this.scheduleForm.getRawValue().fatherCpf === fatherCpf
    ) {
      this.setCachedInfoIntoForm();
    }
  }

  confirm() {
    const gymId = localStorage.getItem(AppConstants.STOR_KIDS_GYM_ID);
    if (!this.scheduleForm.valid) {
      if (!this.scheduleForm.get('fatherCpf').errors && !this.validCpf) {
        this.scheduleForm.get('fatherCpf').disable();
        this.validadeResponsibleCpf(gymId);
        if (
          this.scheduleForm.getRawValue().fatherCpf ===
          this.cachedData?.formData?.fatherCpf
        ) {
          this.setCachedInfoIntoForm();
        }
      }

      if (this.validCpf) {
        FormUtil.touchForm(this.scheduleForm);
      }
    } else {
      this.setInfoIntoCache();
      this.loadingService.startLoading();
      this.modalObj.selectedDate.event?.podeAgendar
        ? this.scheduleClass(gymId)
        : this.enterWaitingList(gymId);
      this.cleanScheduleForm();
      super.confirm();
    }
  }

  alreadyBodytechClientCheck(): void {
    this.scheduleForm
      .get('alreadyBodytechClient')
      .setValue(this.alreadyBodytechClient);
  }

  childrenDoesNotHasCpf(): void {
    this.childrenCpf.disable();
    this.kidDoesNotHasCpf
      ? this.childrenCpf.setValue('')
      : this.childrenCpf.enable();
  }

  cancel() {
    this.cleanScheduleForm();
    super.cancel();
  }

  dismiss() {
    this.cleanScheduleForm();
    super.dismiss();
  }

  cleanResponsibleForm() {
    this.scheduleForm.controls.fatherCpf.reset();
    this.scheduleForm.controls.fatherEmail.reset();
    this.scheduleForm.controls.fatherName.reset();
    this.scheduleForm.controls.fatherPhoneNumber.reset();
    this.scheduleForm.controls.alreadyBodytechClient.reset();
    this.scheduleForm.controls.fatherCpf.enable();
    this.alreadyBodytechClient = false;
    this.deleteCachedFormData();

    if (this.modalObj.selectedDate.event?.podeAgendar) {
      this.validCpf = false;
    }
  }

  cleanChildrenForm() {
    this.scheduleForm.controls.childrenName.reset();
    this.scheduleForm.controls.childrenBirthday.reset();
    this.childrenCpf.reset();
    this.childrenCpf.enable();
    this.kidDoesNotHasCpf = false;
  }

  private showErrorModal(message: string) {
    this.cancel();
    const modal = {
      type: ModalType.error,
      title: 'ATENÇÃO!',
      message,
      confirmCallback: () => {
        this.reloadTimetable();
      },
      dismissCallback: () => {
        this.reloadTimetable();
      },
    };
    this.modalService.show(modal);
  }

  private cleanScheduleForm() {
    this.alreadyBodytechClient = false;
    this.validCpf = false;
    this.kidDoesNotHasCpf = false;
    this.childrenCpf.enable();
    this.scheduleForm.get('fatherCpf').enable();
    this.showScheduleForm = false;
  }

  private formatScheduleInfo(gymId: string): KidsSchedule {
    const customerInfo = this.formatCustomerInfo();
    customerInfo.agendas = [
      {
        codTurma: this.modalObj.selectedDate.event.codTurma,
        data: `${DateUtil.dateToISO(this.modalObj.selectedDate.date).substring(
          0,
          10
        )} ${this.modalObj.selectedDate.hour}:00`,
        idAtividade: this.modalObj.kidsEvent.activityId,
      },
    ];

    return {
      idUnidade: +gymId,
      visitante: customerInfo,
    };
  }

  private formatWaitingListInfo(gymId: string): KidsWaitingList {
    const customerInfo = this.formatCustomerInfo();

    return {
      idUnidade: +gymId,
      visitante: customerInfo,
      turma: this.modalObj.kidsEvent.classCode,
    };
  }

  private formatCustomerInfo(): KidsResponsible {
    return {
      cpfResponsavel: this.scheduleForm
        .get('fatherCpf')
        .value.replace(/\D/g, ''),
      responsavel: this.scheduleForm.get('fatherName').value,
      nome: this.scheduleForm.get('childrenName').value,
      email: this.scheduleForm.get('fatherEmail').value,
      cpf: this.kidDoesNotHasCpf
        ? ''
        : this.childrenCpf.value.replace(/\D/g, ''),
      dataNascimento: DateUtil.dateToISO(
        this.scheduleForm.get('childrenBirthday').value
      ).substring(0, 10),
      observacao: '',
      telefone: this.scheduleForm
        .get('fatherPhoneNumber')
        .value.replace(/\D/g, ''),
    };
  }

  private enterWaitingList(gymId: string) {
    const studentInfoToEnterWaitingList = this.formatWaitingListInfo(gymId);
    this.kidsService.enterWaitingList(studentInfoToEnterWaitingList).subscribe(
      (res) => {
        this.createResponseModal(res);
      },
      () => {
        this.loadingService.stopLoading();
        this.showErrorModal(this.GENERIC_ERROR);
      }
    );
  }

  private scheduleClass(gymId: string) {
    const formattedScheduleInfo = this.formatScheduleInfo(gymId);
    this.kidsService.scheduleKidClass(formattedScheduleInfo).subscribe(
      (res) => {
        this.createResponseModal(res);
      },
      () => {
        this.loadingService.stopLoading();
        this.showErrorModal(this.GENERIC_ERROR);
      }
    );
  }

  private createResponseModal(response: ScheduleClassResponse): void {
    this.loadingService.stopLoading();
    this.cancel();

    if (response.result === this.SCHEDULE_RESPONSES.ok) {
      this.createConfirmModal(response.message);
    } else {
      this.showErrorModal(response.message);
    }
  }

  private validadeResponsibleCpf(gymId: string) {
    this.kidsService
      .validateResponsibleCpf(gymId, this.scheduleForm.get('fatherCpf').value)
      .subscribe(
        (res) => {
          this.validCpf = res.result;
          const cpfErrorMessage = res.message;
          if (!this.validCpf) {
            this.showErrorModal(cpfErrorMessage);
          }
        },
        () => {
          this.validCpf = false;
        }
      );
  }

  private createConfirmModal(message: string) {
    const classDetails = this.modalObj.selectedDate.event.podeAgendar
      ? `
    <div class="divisor-line"></div>
    <br></br>
    <div class="text-center">
    <b>Modalidade:</b> ${this.modalObj.title} <br></br>
    <b>Data:</b> ${this.modalObj.selectedDate.date} <br></br>
    <b>Horário:</b> ${this.modalObj.selectedDate.hour} <br></br>
    <b>Professor:</b> ${this.capitalizePipe.transform(
      this.modalObj.kidsEvent.teacherName
    )}
    </div>`
      : '';

    const modal = {
      type: ModalType.confirm,
      title: this.modalObj.selectedDate.event.podeAgendar
        ? this.successSchedule.title
        : this.successWaitingList.title,
      message: `${message} ${classDetails}`,
      confirmCallback: () => {
        this.reloadTimetable();
      },
      dismissCallback: () => {
        this.reloadTimetable();
      },
    };
    this.modalService.show(modal);
  }

  private reloadTimetable() {
    this.kidsService.triggerTimetableInvalidation(true);
    this.scrollSmooth('timeline-title');
  }

  private setCachedInfoIntoForm() {
    if (this.cachedData) {
      const parsedCachedRegistration = this.cachedData;
      this.scheduleForm.setValue(parsedCachedRegistration.formData);
      this.alreadyBodytechClient =
        this.scheduleForm.value.alreadyBodytechClient;
      this.kidDoesNotHasCpf = parsedCachedRegistration.kidDoesNotHasCpf;
      parsedCachedRegistration.kidDoesNotHasCpf
        ? this.childrenCpf.disable()
        : this.childrenCpf.setValue(parsedCachedRegistration.childrenCpf);
    }
  }

  private scrollSmooth(componentName: string): void {
    document
      .getElementById(componentName)
      .scrollIntoView({ behavior: 'smooth' });
  }

  private getCachedResponsibleCPF() {
    return this.cachedData ? this.cachedData.formData.fatherCpf : null;
  }

  private setInfoIntoCache() {
    const registrationData = JSON.stringify({
      formData: this.scheduleForm.getRawValue(),
      childrenCpf: this.kidDoesNotHasCpf ? '' : this.childrenCpf.value,
      kidDoesNotHasCpf: this.kidDoesNotHasCpf,
    });
    localStorage.setItem(
      AppConstants.STOR_REGISTRATION_LANDING_PAGE_KIDS,
      registrationData
    );
  }

  private deleteCachedFormData(): void {
    localStorage.removeItem(AppConstants.STOR_REGISTRATION_LANDING_PAGE_KIDS);
  }
}
