import { defer, Observable } from 'rxjs';

/**
 * This function is use inside a observable pipe and it's responsability
 * is to perform code when a observable started.
 */
export function doOnSubscribe<T>(
  onSubscribe: () => void
): (source: Observable<T>) => Observable<T> {
  return function inner(source: Observable<T>): Observable<T> {
    return defer(() => {
      onSubscribe();

      return source;
    });
  };
}
