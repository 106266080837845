import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AgendaAgeRange,
  EvoGymMigrationResponse,
  Gym,
  GymActivity,
  GymAgendaFilter,
  GymFilter,
  GymWithKidsPlans,
  ModalAgesObject,
  ModalGymActivityObject,
  ModalGymSelectObject,
  ModalType,
} from '@models';
import { FullGymSearchConfig } from '@models/configs';
import { EvoGeneralService, GymService, ModalService } from '@services';
import { Brand, BrandService } from '@services/brand.service';
import { AppConstants } from '@utils/app-constants';
import { ArrayUtil } from '@utils/array-util';
import { FormatUtils } from '@utils/format-utils';
import { RequestObject } from '@utils/request-object.class';

@Component({
  selector: 'app-agenda-filter',
  templateUrl: './agenda-filter.component.html',
  styleUrls: ['./agenda-filter.component.scss'],
})
export class AgendaFilterComponent implements OnInit {
  @Input() showPlace = false;
  @Input() gym: Gym;
  @Input() allActivities: GymActivity[];
  @Input() analyticsCategory: string;
  @Input() analyticsTag: string;
  @Input() isKids?: boolean;
  @Input() hideActivities = false;
  @Input() filter: GymAgendaFilter = {
    gym: null,
    ages: [],
    activities: [],
  };

  @Output() filterChanges = new EventEmitter<boolean>();

  private filterGymActivitiesByGymCache: { [key: number]: GymActivity[] } = {};

  activityGroups: GymFilter[] = [];
  activityTags: GymFilter[] = [];
  ageTags: GymFilter[] = [];
  allGyms: Gym[] | GymWithKidsPlans[];
  allAges: GymFilter[] = [];
  config: FullGymSearchConfig;
  brand: Brand;
  gymMigrationRO = new RequestObject<EvoGymMigrationResponse>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly brandService: BrandService,
    private readonly evoGeneralService: EvoGeneralService,
    private readonly gymService: GymService,
    private readonly modalService: ModalService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.brand = this.brandService.getBrand();
    this.config = this.activatedRoute.snapshot.data.config;
    this.allGyms = this.activatedRoute.snapshot.data.gyms;
    let allGymsList = [];

    if (this.isKids) {
      this.gymService.getGymNames(true).subscribe((allGymsWithActivities) => {
        allGymsList = allGymsWithActivities.filter((gym) =>
          this.allGyms.some(
            (kidsGym: GymWithKidsPlans | Gym) => kidsGym.id === gym.id
          )
        );
        this.allGyms = allGymsList;
      });
    }

    this.activityGroups = this.activatedRoute.snapshot.data.groups || [];
    if (!this.allActivities) {
      this.allActivities = this.activatedRoute.snapshot.data.activities;
    }

    this.activityGroups = this.filterGroupsByActivities(
      this.allActivities,
      this.activityGroups
    );
    this.activityGroups.forEach((v) => (v.activityGroup = { id: -1 }));

    const ageListResource = this.isKids
      ? AppConstants.KIDS_AGE_RANGE
      : this.activatedRoute.snapshot.data.ages;
    this.allAges = ageListResource.map(
      (age: AgendaAgeRange) =>
        ({
          id: age.id,
          name: age.name,
          description: FormatUtils.ageRangeToDescription(
            age.minimumAge,
            age.maximumAge
          ),
        } as GymFilter)
    );

    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;
    const activities =
      atob(queryParamMap.get(AppConstants.PARAM_AGENDA_ACTIVITIES)) || '';
    activities
      .split(',')
      .map((a) => +a)
      .forEach((activityId) => {
        const activity = this.allActivities.find((a) => a.id === activityId);
        if (activity) {
          activity.selected = true;
          this.filter.activities.push(activity as GymFilter);
        }
      });
    this.updateActivityTags();

    const ages =
      atob(queryParamMap.get(AppConstants.PARAM_AGENDA_AGE_RANGES)) || '';
    ages
      .split(',')
      .map((a) => +a)
      .forEach((ageId) => {
        const age = this.allAges.find((a) => a.id === ageId);
        if (age) {
          age.selected = true;
          this.filter.ages.push(age);
        }
      });
  }

  private checkGymMigration(): void {
    this.gymMigrationRO = new RequestObject<EvoGymMigrationResponse>(
      this.evoGeneralService.checkMigratingGym(this.gym.id)
    );

    this.gymMigrationRO.observable$.subscribe(
      (response) => (this.hideActivities = response.isAlreadyMigrated)
    );
  }

  showGymSelectModal() {
    this.modalService.show({
      type: ModalType.gymSelect,
      title: 'Selecione academia',
      message: '',
      confirmCallback: (data: Gym) => {
        if (data.id !== this.gym?.id) {
          this.hideActivities = true;
          this.clearFilter(false);
          this.gym = data;
          this.filter.gym = data;
          this.checkGymMigration();
          this.filterChanged();
        }
      },
      gyms: this.allGyms,
      selected: this.gym ? this.gym.id : null,
    } as ModalGymSelectObject);
  }

  showAgeSelectModal() {
    this.modalService.show({
      type: ModalType.gymAge,
      title: 'Faixa etária',
      message: '',
      confirmCallback: (data: GymFilter[]) => {
        this.hasModification(this.filter.ages, data);
        this.filter.ages = data;
      },
      ages: this.allAges,
    } as ModalAgesObject);
  }

  private filterGymActivitiesByGym(
    gym: Gym,
    allActivities: GymActivity[]
  ): GymActivity[] {
    const gymId = gym.id || gym.gymUnitId;

    if (!this.filterGymActivitiesByGymCache[gymId]) {
      const activities = gym.activeActivities
        ? gym.activeActivities
        : gym.activities;
      if (!activities) {
        return [];
      }
      const ids = activities.map((a) => a.id);
      const result = allActivities.filter((a) => ids.includes(a.id));
      this.filterGymActivitiesByGymCache[gymId] = result;
    }

    return this.filterGymActivitiesByGymCache[gymId];
  }

  private filterGroupsByActivities(
    activities: GymActivity[],
    allGroups: GymFilter[]
  ): GymFilter[] {
    return allGroups.filter((group) =>
      activities.some((act) => act.activityGroup.id === group.id)
    );
  }

  showActivitiesSelectModal() {
    const activities = this.filterGymActivitiesByGym(
      this.gym,
      this.allActivities
    );
    const groups = this.filterGroupsByActivities(
      activities,
      this.activityGroups
    );
    this.modalService.show({
      type: ModalType.gymActivity,
      title: this.config.activitiesModal.title,
      message: '',
      confirmCallback: (data: GymFilter[]) => {
        this.hasModification(this.filter.activities, data);
        this.filter.activities = data;
        this.updateActivityTags();
      },
      activities,
      groups,
      config: this.config.activitiesModal,
    } as ModalGymActivityObject);
  }

  removeFilter(list: GymFilter[], item: GymFilter) {
    item.selected = false;
    ArrayUtil.removeItem(list, item);
    if (list === this.filter.activities) {
      ArrayUtil.removeItem(this.activityTags, item);
      if (item.activityGroup.id < 0) {
        list
          .filter((a) => a.activityGroup.id === item.id)
          .forEach((a) => this.removeFilter(list, a));
      }
    }

    this.filterChanged();
  }

  updateActivityTags() {
    this.activityTags = [];
    for (const group of this.activityGroups) {
      this.activityTags.push(...this.groupTagsWhenAllChecked(group.id));
    }
  }

  groupTagsWhenAllChecked(group: number): GymFilter[] {
    const listActivities = this.filter.activities.filter(
      (a) => a.activityGroup.id === group
    );

    if (
      listActivities.length &&
      listActivities.length ===
        this.allActivities.filter((a) => a.activityGroup.id === group).length
    ) {
      return [this.activityGroups.find((item) => item.id === group)];
    } else {
      return listActivities;
    }
  }

  doSearch() {
    if (this.gym) {
      if (this.hideActivities) {
        this.evoGeneralService.openEvoSchedule(this.gym.code, this.isKids);
        return;
      }

      const routeUrl = this.isKids ? 'kids' : 'agenda';

      this.router
        .navigate([routeUrl, this.gym.slug], {
          queryParams: FormatUtils.encodeAgendaFilterToParamObject(this.filter),
        })
        .then(() => {
          setTimeout(() => this.scrollSmooth('timeline-title'), 1300);
        });
    } else {
      this.showGymSelectModal();
    }
  }

  scrollSmooth(componentName: string): void {
    const element = document.getElementById(componentName);
    const kidsYOffset =
      element?.getBoundingClientRect().top + window.pageYOffset + 250;
    const y = this.isKids ? kidsYOffset : 0;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  clearFilter(clearAges = true) {
    if (this.filter.activities.length > 0 || this.filter.ages.length > 0) {
      this.filter.activities.forEach((activity) => (activity.selected = false));

      this.activityTags = [];
      this.filter.activities = [];

      if (clearAges) {
        this.filter.ages.forEach((age) => (age.selected = false));
        this.filter.ages = [];
      }

      this.filterChanged();
    }
  }

  private hasModification(arr1: any[], arr2: any[]) {
    if (
      arr1.filter((a) => arr2.includes(a)).length !== arr1.length ||
      arr1.length < arr2.length
    ) {
      this.filterChanged();
    }
  }

  filterChanged(isChanged: boolean = true) {
    this.filterChanges.emit(isChanged);
  }
}
