import { AfterViewInit, Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { AppConstants } from '@utils/app-constants';

// @ts-ignore
declare var loadGRecaptcha: any;
declare var grecaptcha: any;

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
})
export class RecaptchaComponent implements AfterViewInit {
  @Input() name = '';

  readonly recaptchaSiteKey = environment.recaptchaKey;

  optWidgetId: number;

  ngAfterViewInit() {
    if (typeof grecaptcha === 'undefined') {
      this.insertScriptInitRecaptcha();
    } else {
      this.renderGRecaptcha();
    }
  }

  insertScriptInitRecaptcha() {
    const doc = document.body as HTMLDivElement;

    const scriptLoad = document.createElement('script');
    scriptLoad.innerHTML = 'var loadGRecaptcha;';
    doc.appendChild(scriptLoad);
    loadGRecaptcha = () => this.renderGRecaptcha();

    const script = document.createElement('script');
    script.innerHTML = '';
    script.src =
      `https://www.google.com/recaptcha/api.js?onload=loadGRecaptcha&render=explicit&hl=` +
      AppConstants.LOCALE;
    script.async = true;
    script.defer = true;
    doc.appendChild(script);
  }

  renderGRecaptcha() {
    this.optWidgetId = grecaptcha.render(this.name + '-captcha', {
      sitekey: this.recaptchaSiteKey,
    });
  }

  reset() {
    if (this.optWidgetId !== undefined) {
      grecaptcha.reset(this.optWidgetId);
    }
  }

  isVerified(): boolean {
    // This may cause a problem in QA environment
    return (
      this.optWidgetId !== undefined &&
      grecaptcha.getResponse(this.optWidgetId).length
    );
  }
}
