import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointsService, screenSizeToString } from '@services';

@Component({
  selector: 'app-fr-timeline',
  templateUrl: './fr-timeline.component.html',
  styleUrls: ['./fr-timeline.component.scss'],
})
export class FrTimelineComponent implements OnDestroy {
  screenSize: string;
  private subs: Subscription;

  constructor(private readonly breakpointsService: BreakpointsService) {
    this.subs = this.breakpointsService.emitter.subscribe(
      (size) => (this.screenSize = screenSizeToString(size))
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
