import { Component, HostListener, OnInit } from '@angular/core';
import { BreakpointsService } from '@services/breakpoints.service';

@Component({
  selector: 'app-debug-screen-size',
  template: `<div class="text-center">
    Current size: {{ screenSize | uppercase }} ({{ screenSizePx }}px)
  </div>`,
  styles: [
    '.size { width: 90%; margin-left: 5%; background-color: rgba(0, 0, 0, .1)}',
  ],
})
export class ScreenSizeComponent implements OnInit {
  screenSize = '?';
  screenSizePx = 0;

  ngOnInit() {
    this.onResize(window.innerWidth);
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.screenSizePx = width;
    this.screenSize = BreakpointsService.screenSize(width, [
      'xs',
      'sm',
      'md',
      'lg',
      'xl',
    ]);
  }
}
