import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { IncomeDetails } from '@models/bt-pass.model';
import { BTPassService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class IncomeResolveGuard implements Resolve<any> {
  constructor(
    private readonly btPassService: BTPassService,
    private readonly router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): ResolveResult<IncomeDetails | null> {
    const gymUnitId = +route.params.gymUnitId;
    const additionalIncomeId = +route.params.additionalIncomeId;

    if (gymUnitId && additionalIncomeId) {
      return this.btPassService
        .getBTPassIncome(gymUnitId, additionalIncomeId)
        .pipe(
          tap((data) => {
            if (!data) {
              this.router.navigate(['/bt-pass']);
            }
          })
        );
    } else {
      this.router.navigate(['/bt-pass']);
    }
  }
}
