import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '@utils/app-constants';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  CustomerService,
  AlertMessageService,
  AuthService,
  ModalService,
  LoadingService,
} from '@services';
import { FormUtil } from '@utils/form-util';
import {
  AlertMessage,
  MessageMap,
  ModalType,
  UpdateCreditCard,
  CustomerCreditCard,
  MyContractResponse,
  UpdateCreditCardWithRenew,
} from '@models';
import { DateUtil } from '@utils/date-util';
import { CreditCardUtil } from '@utils/credit-card-util';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ContractStatusEnum } from '@utils/contract-status-enum';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-profile-payment',
  templateUrl: './profile-payment.component.html',
  styleUrls: ['./profile-payment.component.scss'],
})
export class ProfilePaymentComponent implements OnInit {
  @Output() goBackContractScreen = new EventEmitter<boolean>();
  @Input() userInfo: MyContractResponse;

  readonly locale = AppConstants.LOCALE;
  readonly device = AppConstants.DEVICE;

  showInfo = true;
  creditCardInfo: CustomerCreditCard;
  contractStatusEnum = ContractStatusEnum;
  years: string[];
  months: string[];
  form: FormGroup;
  loading = true;
  errorMessage: string;
  flagCardOptions: any[] = [
    { text: 'Visa', id: 1, value: 1 },
    { text: 'Mastercard', id: 2, value: 2 },
    { text: 'Amex', id: 3, value: 3 },
    { text: 'Diners', id: 4, value: 4 },
    { text: 'Elo', id: 5, value: 5 },
  ];

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly authService: AuthService,
    private readonly customerService: CustomerService,
    private readonly formBuilder: FormBuilder,
    private readonly loadingService: LoadingService,
    private readonly modalService: ModalService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.pageInitialization();
    this.createForm();
    this.months = DateUtil.getMonthsOfTheYear();
    this.years = DateUtil.getYears();
  }

  goBackToContract() {
    this.goBackContractScreen.emit(true);
  }

  openCVVModal() {
    this.modalService.show({
      type: ModalType.creditCardInfo,
      title: null,
      message: null,
      data: {},
    });
  }

  showAndResetUpdatePaymentForm() {
    this.showInfo = false;
    this.form.reset();
  }

  updateCreditCard() {
    FormUtil.touchForm(this.form);
    if (this.form.valid) {
      const formData = this.form.value;

      this.loadingService.startLoading();
      if (this.contractIsExpired()) {
        const creditCardData: UpdateCreditCardWithRenew =
          this.mapCreditCardDataWithRenew(formData);
        this.updateWithRenew(creditCardData);
      } else {
        const creditCardData: UpdateCreditCard =
          this.mapCreditCardDataWithoutRenew(formData);
        this.updateWithoutRenew(creditCardData);
      }
    } else {
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO)
      );
    }
  }

  private updateWithoutRenew(creditCardData: any) {
    this.customerService
      .updateCustomerCreditCard(creditCardData, this.userInfo.gym.id)
      .subscribe(
        () => {
          this.getCreditCardInfo(this.userInfo.gym.id).subscribe(() => {});
          this.form.reset();
          this.showInfo = true;
          this.showChangeCardResultModal(
            'SUCESSO!',
            MessageMap.CARTAO_TROCADO,
            ModalType.confirm
          );
          this.goBackToContract();
        },
        (err) => {
          if (
            err.error?.errorCode ===
            AppConstants.LEGACY_ERROR.UPDATE_CREDIT_CARD_ERROR
          ) {
            this.alertMessageService.showToastr(
              AlertMessage.warning(MessageMap.SESSAO_EXPIRADA)
            );
            this.authService.logout(this.router.url);
          } else {
            this.showChangeCardResultModal(
              'ATENÇÃO!',
              err.error?.message,
              ModalType.error
            );
          }
        },
        () => this.loadingService.stopLoading()
      );
  }

  private updateWithRenew(creditCardData: any) {
    this.customerService
      .updateCustomerCreditCardWithRenew(creditCardData, this.userInfo.gym.id)
      .subscribe(
        (res: any) => {
          this.getCreditCardInfo(this.userInfo.gym.id).subscribe(() => {});
          this.form.reset();
          this.showInfo = true;
          let plans = '';
          res.ListaDetalhesPlano.forEach((plan: any, index: number) => {
            index === res.ListaDetalhesPlano.length - 1
              ? (plans = `${plans}${plan.NmPlanoRenovacao}`)
              : (plans = `${plans}${plan.NmPlanoRenovacao}, `);
          });

          const resultMessage = `<div class="text-center d-flex flex-column align-items-center justify-content-center">
      <div>Recebemos seu pedido. Um e-mail de confirmação será enviado e em breve seus dados serão atualizados.</div>
      <div class="d-flex flex-row p-2 pt-4"><b class="mr-2">
      ${res.ListaDetalhesPlano.length > 1 ? 'Planos' : 'Plano'}:</b> ${plans}
      </div>
      <div class="d-flex flex-row p-2"><b class="mr-2">Unidade: </b>${
        this.userInfo.gym.name
      }</div>
      <div class="d-flex flex-row p-2"><b class="mr-2">Valor: </b>
      ${new CurrencyPipe(AppConstants.LOCALE).transform(
        res.ValorRenovacao,
        'BRL'
      )}</div>
      </div>`;
          this.showChangeCardResultModal(
            'SUCESSO!',
            resultMessage,
            ModalType.confirm
          );

          this.goBackToContract();
        },
        (error) => {
          if (
            error.error?.errorCode ===
            AppConstants.LEGACY_ERROR.UPDATE_CREDIT_CARD_ERROR
          ) {
            this.alertMessageService.showToastr(
              AlertMessage.warning(MessageMap.SESSAO_EXPIRADA)
            );
            this.authService.logout(this.router.url);
          } else {
            this.showChangeCardResultModal(
              'ATENÇÃO!',
              error.error?.message,
              ModalType.error
            );
          }
        },
        () => this.loadingService.stopLoading()
      );
  }

  private contractIsExpired(): boolean {
    return (
      this.userInfo.hasFinancialPending &&
      this.userInfo.contractStatusCode === this.contractStatusEnum.Expired
    );
  }

  private createForm() {
    this.form = this.formBuilder.group(
      {
        cardExpiringMonth: new FormControl(null, [Validators.required]),
        cardExpiringYear: new FormControl(null, [Validators.required]),
        creditCardFlag: new FormControl(null, Validators.required),
        creditCardNumber: new FormControl(null, Validators.required),
        CVV: new FormControl(null, Validators.required),
        namePrintedInCreditCard: new FormControl(null, Validators.required),
      },
      { validator: CreditCardUtil.isExpired }
    );
  }

  private getCreditCardInfo(userGymUnitId: number) {
    return this.customerService.getCustomerCreditCard(userGymUnitId).pipe(
      tap((creditCardInfo) => (this.creditCardInfo = creditCardInfo)),
      catchError((err) => {
        this.errorMessage =
          err.error?.errorCode ===
          AppConstants.LEGACY_ERROR.CREDIT_CARD_NOT_FOUND
            ? MessageMap.CARTAO_CREDITO_NAO_ENCONTRADO
            : MessageMap.USER_LEGACY_TOKEN_INDISPONÍVEL;
        return of([]);
      }),
      tap(() => (this.loading = false))
    );
  }

  private pageInitialization() {
    this.getCreditCardInfo(this.userInfo.gym.id);
  }

  private mapCreditCardDataWithoutRenew(formData: any): UpdateCreditCard {
    return {
      DadosCartaoCliente: {
        CodigoSeguranca: formData.CVV,
        DataValidade: `${formData.cardExpiringMonth}/${formData.cardExpiringYear}`,
        IdBandeira: formData.creditCardFlag.value,
        NomeProprietarioCartao: formData.namePrintedInCreditCard,
        NumeroCartao: formData.creditCardNumber,
      },
      IdFormaDePagamento: AppConstants.PAYMENT_CREDIT_CARD_ID,
      JustClick: true,
    };
  }

  private mapCreditCardDataWithRenew(formData: any): UpdateCreditCardWithRenew {
    return {
      CodigoContrato: this.userInfo.contractCode,
      Dispositivo: this.device,
      DadosCartaoCliente: {
        CodigoSeguranca: formData.CVV,
        DataValidade: `${formData.cardExpiringMonth}/${formData.cardExpiringYear}`,
        IdBandeira: formData.creditCardFlag.value,
        NomeProprietarioCartao: formData.namePrintedInCreditCard,
        NumeroCartao: formData.creditCardNumber,
      },
    };
  }

  private showChangeCardResultModal(
    title: string,
    message: string,
    modalType: number
  ) {
    this.modalService.show({
      type: modalType,
      title,
      message,
      confirmButton: 'OK',
    });
  }
}
