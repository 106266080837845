import {
  AfterViewInit,
  Component,
  Injector,
  OnInit,
  Optional,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import {
  AlertMessage,
  BaseUserCredentials,
  EvoLoginResponse,
  MessageMap,
} from '@models';
import { BasicHeaderConfig } from '@models/configs';
import { EvoPageQueryParams } from '@models/evo.model';
import {
  AlertMessageService,
  AuthService,
  EvoAuthService,
  HeaderService,
  LoadingService,
  UserService,
} from '@services';
import { BrandService } from '@services/brand.service';
import { MyOnlineClassSchedulesService } from '@services/my-online-class-schedules.service';
import { AppConstants } from '@utils/app-constants';
import { LoginErrorValidation } from '@utils/evo';
import { EvoProviders } from '@utils/evo/evo-providers';
import { FormUtil } from '@utils/form-util';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    {
      provide: EvoAuthService,
      useFactory: EvoProviders.AuthServiceProvider,
      deps: [Injector],
    },
  ],
})
export class LoginComponent implements OnInit, AfterViewInit {
  public config: BasicHeaderConfig;

  @ViewChild('form', { static: false }) form: NgForm;
  @ViewChild('header', { static: false }) headerComponent: TemplateRef<any>;
  user = {
    email: '',
    password: '',
  };
  next = '';

  constructor(
    // @ts-ignore
    public readonly brandService: BrandService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly alertMessageService: AlertMessageService,
    private readonly authService: AuthService,
    private readonly headerService: HeaderService,
    private readonly loadingService: LoadingService,
    private readonly myOnlineClassService: MyOnlineClassSchedulesService,
    private readonly router: Router,
    private readonly userService: UserService,

    @Optional() private readonly evoLoginService: EvoAuthService
  ) {
    this.config = this.activatedRoute.snapshot.data.config.config;
    this.setInputEmailValue();
  }

  ngOnInit() {
    const key = this.activatedRoute.snapshot.queryParamMap.get('key');
    const conf =
      this.activatedRoute.snapshot.queryParamMap.get('conf') ||
      this.activatedRoute.snapshot.queryParamMap.get('confirmacao');
    if (key) {
      this.userService.unblockEmail(key).subscribe(
        () =>
          this.alertMessageService.showToastr(
            AlertMessage.success(MessageMap.CONTA_ATIVADA)
          ),
        (err: any) =>
          this.alertMessageService.showToastr(
            AlertMessage.error(err.error.error)
          )
      );
    } else if (conf) {
      this.authService.confirmNewUserLegacy(conf).subscribe(
        () =>
          this.alertMessageService.showToastr(
            AlertMessage.success(MessageMap.CONTA_ATIVADA)
          ),
        (err: any) => this.alertMessageService.showToastr(err)
      );
    }

    if (this.authService.checkAuth()) {
      this.router.navigate(['/cliente']);
    }
    this.next = this.activatedRoute.snapshot.queryParams.next;
  }

  ngAfterViewInit() {
    setTimeout(() => this.headerService.template.next(this.headerComponent));
  }

  login() {
    FormUtil.touchForm(this.form.control);

    if (this.form.invalid) {
      return;
    }
    this.loadingService.startLoading();

    const loginRequestBody: BaseUserCredentials = {
      email: this.user.email,
      password: this.user.password,
    };

    let authService$: Observable<any> =
      this.authService.loginMicrosservice(loginRequestBody);

    if (this.evoLoginService) {
      authService$ = authService$.pipe(
        mergeMap((loginMicroServiceResponse) => {
          return this.evoLoginService.login(
            { ...loginRequestBody, changePassword: 'true' },
            loginMicroServiceResponse
          );
        }),
        mergeMap((microServiceOrEvoResponse) => {
          return microServiceOrEvoResponse.data
            ? this.authService.finalizeAuthentication(
                microServiceOrEvoResponse,
                loginRequestBody,
                this.next
              )
            : of(microServiceOrEvoResponse);
        }),
        catchError((errorResponse) => {
          return errorResponse?.find(
            (error: { errorType?: string }) =>
              error.errorType === LoginErrorValidation.InvalidLogin
          )
            ? this.evoLoginService.evoAuthenticateUser({
                ...loginRequestBody,
                changePassword: 'false',
              })
            : throwError(errorResponse);
        }),
        tap((evoResponse: EvoLoginResponse) => {
          if (evoResponse?.urlMemberArea) {
            this.authService.saveLastLoginEmail(loginRequestBody.email);
            this.authService.saveLastUserMainGymId(
              evoResponse.idBranch.toString()
            );
            this.redirectToEvoRoute(evoResponse);
          }
        })
      );
    } else {
      authService$ = authService$.pipe(
        mergeMap((response) =>
          this.authService.finalizeAuthentication(
            response,
            loginRequestBody,
            this.next
          )
        )
      );
    }

    authService$
      .pipe(finalize(() => this.loadingService.stopLoading()))
      .subscribe(() => {
        this.myOnlineClassService.setIsvailiableHourToScheduleList(false);
      });
  }

  private redirectToEvoRoute(evoResponse: EvoLoginResponse): void {
    const queryParams: EvoPageQueryParams = {
      iframeUrl: window.encodeURIComponent(evoResponse.urlMemberArea),
      isRedirect: this.next ? 'true' : 'false',
    };

    this.router.navigate(['evo'], {
      queryParams,
      relativeTo: this.activatedRoute.parent,
    });
  }

  private setInputEmailValue() {
    setTimeout(() => {
      this.user.email = this.activatedRoute.snapshot.queryParams?.userEmail
        ? this.activatedRoute.snapshot.queryParams.userEmail
        : localStorage.getItem(AppConstants.STOR_LAST_LOGIN);
    });
  }
}
