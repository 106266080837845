import { Component, OnInit, Input } from '@angular/core';
import { GymFilter, ModalAgesObject, ModalType } from '@models';
import { ModalService, AnalyticsService } from '@services';
import { ModalCommonComponent } from '../modal-common.component';

@Component({
  selector: 'app-modal-gym-age',
  templateUrl: './modal-gym-age.component.html',
  styleUrls: ['./modal-gym-age.component.scss'],
})
export class ModalGymAgeComponent
  extends ModalCommonComponent
  implements OnInit {
  @Input() analyticsCategory: string;
  @Input() analyticsTag: string;

  ages: GymFilter[];
  modalObj: ModalAgesObject;

  public get allChecked(): boolean {
    return this.ages.every((age) => age.selected);
  }

  public set allChecked(check: boolean) {
    this.ages.forEach((age) => (age.selected = check));
  }

  constructor(
    private readonly analyticsService: AnalyticsService,
    protected modalService: ModalService
  ) {
    super();
    this.type = ModalType.gymAge;
  }

  init() {
    this.ages = this.modalObj.ages || [];
  }

  confirm() {
    this.analyticsEvent();

    this.modalObj.data = this.ages.filter((age) => age.selected);
    super.confirm();
  }

  analyticsEvent() {
    // Filtra as faixas de idade selecionadas e concatena seus nomes numa string, separando cada nome por ', '
    const st = this.ages
      .filter((age) => age.selected)
      .map((age) => age.name)
      .join(', ');

    if (st) {
      this.analyticsService.trackEvent(
        `Idades (${st})`,
        this.analyticsCategory,
        this.analyticsTag
      );
    }
  }
}
