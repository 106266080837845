import { Component, OnInit } from '@angular/core';
import { MyPurchasesResponse, PreSalePurchase, MessageMap } from '@models';
import { AppConstants } from '@utils/app-constants';
import { HttpErrorResponse } from '@angular/common/http';
import { MyPurchasesService } from '@services/my-purchases.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss'],
})
export class PurchasesComponent implements OnInit {
  locale = AppConstants.LOCALE;
  purchase: MyPurchasesResponse;
  preSalePuchase: PreSalePurchase[];
  hasPurchase: boolean;
  hasPreSalePurchase: boolean;
  emptyMessage = 'Dados indisponíveis';
  isLoadingPurchases = false;

  constructor(private readonly myPurchasesService: MyPurchasesService) {}

  ngOnInit() {
    this.isLoadingPurchases = true;

    this.getInfo().subscribe((info) => {
      this.getPreSaleInfo().subscribe((infoPreSale) => {
        this.purchase = info;
        this.preSalePuchase = infoPreSale;
        if (
          (this.purchase instanceof HttpErrorResponse &&
            (this.purchase as HttpErrorResponse).status ===
              AppConstants.HTTP_FORBIDDEN) ||
          (this.preSalePuchase instanceof HttpErrorResponse &&
            (this.preSalePuchase as HttpErrorResponse).status ===
              AppConstants.HTTP_FORBIDDEN)
        ) {
          this.emptyMessage = MessageMap.INFO_NAO_DISPONIVEIS;
        } else {
          this.emptyMessage = 'Dados indisponíveis';
          this.hasPurchase = this.purchase?.purchases.length > 0;
          this.hasPreSalePurchase = this.preSalePuchase?.length > 0;
        }
        this.isLoadingPurchases = false;
      });
    });
  }

  private getInfo() {
    return this.myPurchasesService.getInfo().pipe(
      catchError((res) => {
        this.isLoadingPurchases = false;
        return of(res);
      })
    );
  }

  private getPreSaleInfo() {
    return this.myPurchasesService.getPreSaleInfo().pipe(
      catchError((res) => {
        this.isLoadingPurchases = false;
        return of(res);
      })
    );
  }
}
