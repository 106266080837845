import {
  FormattedKidsClassDates,
  FormattedKidsClassTime,
  FormattedKidsTimetable,
  KidsClass,
  KidsClassTime,
  KidsTimetable,
} from '@models/landing-page-kids.model';
import { GymFilter } from '@models/responses/gym-response.model';

export const formatTimetable = (eventTable: any): any => {
  const timetable: FormattedKidsTimetable[] = [];
  eventTable.forEach((days: KidsTimetable) => {
    days?.classes?.forEach((classes: KidsClass) => {
      const actualDay = convertUTCDayToGridFormatDay(
        new Date(days.classeData).getUTCDay()
      );

      classes.classesHora.forEach((classHours: KidsClassTime) => {
        if (!timetable.some((elem: any) => elem.hour === classHours.hora)) {
          createTimetableHourArray(
            actualDay,
            classHours,
            timetable,
            days,
            classes
          );
        } else {
          addTimetableHourAlreadyCreated(
            actualDay,
            classHours,
            timetable,
            days,
            classes,
            eventTable
          );
        }
      });
    });
  });

  timetable.sort((a: any, b: any) => {
    return formatGridHourToNumber(a.hour) - formatGridHourToNumber(b.hour);
  });

  timetable.forEach((classHour) => {
    classHour.dates = fillWithEmptyEventsToFillTable(classHour.dates);
  });

  eventTable = timetable;
  return eventTable;
};

export const createTimetableHourArray = (
  actualDay: number,
  classHours: any,
  timetable: any,
  days: any,
  classes: any
) => {
  timetable.push({ hour: classHours.hora, dates: [{ date: '', classes: [] }] });
  timetable.forEach((classHour: any) => {
    if (classHour.hour === classHours.hora) {
      addClassesToNewDay(actualDay, classHour, classHours, classes, days);
    }
    return classHour;
  });
};

export const addClassesToNewDay = (
  actualDay: number,
  classHour: any,
  classHours: any,
  classes: any,
  days: any
) => {
  for (let i = 0; i < 7; i++) {
    if (typeof classHour.dates[i] === 'undefined' || !classHour.dates[i].date) {
      actualDay === i
        ? (classHour.dates[actualDay] = formatClassToNewDay(
            actualDay,
            classHour,
            classHours,
            classes,
            days
          ))
        : (classHour.dates[i] = {
            date: classHour.dates[i]?.date || '',
            classes: classHour.dates[i]?.classes
              ? [...classHour.dates[i]?.classes]
              : [],
          });
    }
  }
};

export const formatClassToNewDay = (
  actualDay: number,
  classHour: any,
  classHours: any,
  classes: any,
  days: any
) => {
  return {
    date: days.classeData,
    classes:
      classHour.dates[actualDay]?.classes.length > 0
        ? [
            ...classHour.dates[actualDay]?.classes,
            {
              ...classHours,
              nomeAtividade: classes.nomeAtividade,
              nomeProfessor: classes.nomeProfessor,
              idAtividade: classes.idAtividade,
            },
          ]
        : [
            {
              ...classHours,
              nomeAtividade: classes.nomeAtividade,
              nomeProfessor: classes.nomeProfessor,
              idAtividade: classes.idAtividade,
            },
          ],
  };
};

export const addTimetableHourAlreadyCreated = (
  actualDay: number,
  classHours: any,
  timetable: any,
  days: any,
  classes: any,
  eventTable: any
) => {
  timetable.forEach((classHour: any) => {
    if (
      classHour.hour === classHours.hora &&
      !compareIfWeekdayAlreadyExist(days.classeData, eventTable)
    ) {
      addClassesToAlreadyCreatedDay(
        actualDay,
        classHour,
        classHours,
        classes,
        days
      );
    }
    return classHour;
  });
};

export const addClassesToAlreadyCreatedDay = (
  actualDay: number,
  classHour: any,
  classHours: any,
  classes: any,
  days: any
) => {
  classHour.dates[actualDay].classes && classHour.dates[actualDay].date
    ? classHour.dates[actualDay].classes.push({
        ...classHours,
        nomeAtividade: classes.nomeAtividade,
        nomeProfessor: classes.nomeProfessor,
        idAtividade: classes.idAtividade,
      })
    : (classHour.dates[actualDay] = {
        date: days.classeData,
        classes: [
          {
            ...classHours,
            nomeAtividade: classes.nomeAtividade,
            nomeProfessor: classes.nomeProfessor,
            idAtividade: classes.idAtividade,
          },
        ],
      });
};

export const formatGridHourToNumber = (hour: string): number => {
  return parseInt(hour.replace(/[^0-9]/g, ''), 10);
};

export const filterTimetable = (
  activities: GymFilter[],
  ages: GymFilter[],
  eventTable: any
): any => {
  eventTable.forEach((classHour: FormattedKidsTimetable) => {
    classHour.dates.forEach((classDate: FormattedKidsClassDates) => {
      applyFiltersInClassRow(activities, ages, classDate);
    });
  });

  eventTable.forEach((classHour: FormattedKidsTimetable) => {
    classHour.dates = fillWithEmptyEventsToFillTable(classHour.dates);
  });

  eventTable = eventTable.filter((classHour: any) => {
    const hourRowClassLength = classHour.dates.reduce(
      (max: any, value: any) => (max = Math.max(max, value?.classes.length)),
      0
    );
    return hourRowClassLength > 0;
  });

  return eventTable;
};

const getClassCategories = (audiences: string[]): string[] => {
  return audiences.map((audience) => {
    const [category, level] = audience.split(' ');

    return `${category} ${level}`;
  });
};

export const applyFiltersInClassRow = (
  activities: GymFilter[],
  ages: GymFilter[],
  classDate: FormattedKidsClassDates
) => {
  // Filtra por "Atividades"
  if (activities.length > 0) {
    classDate.classes = classDate.classes.filter(
      (value: FormattedKidsClassTime) => {
        return activities.some(
          (activity) => value?.nomeAtividade === activity.name
        );
      }
    );
  }

  // Filtra por "Faixa Etária"
  if (ages?.length > 0) {
    classDate.classes = classDate.classes.filter(
      (value: FormattedKidsClassTime) => {
        if (value.nomeFaixaEtaria) {
          const categories = getClassCategories(value.nomeFaixaEtaria);

          // Verifica se naquela aula possui alguma categoria que bate com as categorias
          // selecionadas no filtro de faixa etária
          return ages.some((age) => {
            const [filterCategory, filterLevel] = age.name.split(' ');

            return categories.includes(`${filterCategory} ${filterLevel}`);
          });
        }
      }
    );
  }
};

export const fillWithEmptyEventsToFillTable = (
  eventRow: FormattedKidsClassDates[]
): FormattedKidsClassDates[] => {
  const maxLenght = eventRow.reduce(
    (max, value) => (max = Math.max(max, value?.classes.length)),
    0
  );
  eventRow.forEach((value) =>
    value.classes.splice(
      value?.classes.length,
      0,
      ...Array(maxLenght - value?.classes.length).fill({})
    )
  );
  return eventRow;
};

export const convertUTCDayToGridFormatDay = (dateToConvert: number): number => {
  return dateToConvert > 0 ? dateToConvert - 1 : 6;
};

export const compareIfWeekdayAlreadyExist = (
  dateToCompare: string,
  eventRow: any
): boolean => {
  const initialDate = new Date(eventRow[0].classeData).getTime();
  const dateToCompareTime = new Date(dateToCompare).getTime();
  const dayInMiliseconds = 86400000;
  return (dateToCompareTime - initialDate) / dayInMiliseconds >= 7;
};
