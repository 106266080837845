import { Component, Input } from '@angular/core';
import { Notice } from '@services/notice.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
})
export class NoticeComponent {
  @Input()
  public notice: Notice;
}
