import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
})
export class LoadingComponent implements OnInit {
  @Input() visible = false;

  message: string;

  constructor(private readonly loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.loadingEmitter.subscribe(
      (display: boolean | string) => {
        if (typeof display === 'boolean') {
          this.message = 'Carregando…';
          this.visible = display;
        } else {
          this.message = display;
          this.visible = true;
        }
      }
    );
  }
}
