import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MyContractService } from '@services';
import { ResolveResult } from './type-resolve-bundle';

@Injectable()
export class ContractCancelResolve implements Resolve<any> {
  constructor(private readonly contractService: MyContractService) {}

  resolve(route: ActivatedRouteSnapshot): ResolveResult<any> {
    return this.contractService.cancelContract(route.paramMap.get('code'));
  }
}
