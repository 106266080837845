<div *ngIf="data?.length" (deferLoad)="loadImage = true">
  <div class="partners-title no-bottom-line text-center">{{ title }}</div>
  <div class="partners-row row justify-content-center">
    <a
      *ngFor="let partner of data; let index = index"
      id="partner-{{ index }}"
      class="partner-logo"
      appClickable
      (click)="clickPartner(index)"
      [href]="partner.link"
      target="_blank"
      rel="noopener"
    >
      <img [alt]="partner.name" [src]="loadImage ? partner.icon : ''" />
    </a>
  </div>
</div>
