import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownArrowsModule } from '@directives/dropdown-arrows/dropdown-arrows.module';
import { ModalSingleSelectModule, ModalGymSelectByCityModule } from '../modal';
import { StateGymFilterComponent } from './state-gym-filter.component';

@NgModule({
  declarations: [StateGymFilterComponent],
  imports: [
    CommonModule,
    DropdownArrowsModule,
    ModalGymSelectByCityModule,
    ModalSingleSelectModule,
  ],
  exports: [StateGymFilterComponent],
})
export class StateGymFilterModule {}
