import { AppConstants } from '@utils/app-constants';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Schedule } from '@models';

@Component({
  selector: 'app-schedule-item',
  templateUrl: './schedule-item.component.html',
  styleUrls: ['../schedules-list.component.scss'],
})
export class ScheduleItemComponent {
  @Input() schedules: Schedule[];

  @Output() cancelClick = new EventEmitter<Schedule>();

  readonly locale = AppConstants.LOCALE;

  onCancelClick(item: Schedule) {
    this.cancelClick.emit(item);
  }
}
