import { Component, Input } from '@angular/core';
import { FooterSitemap } from '@models';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss'],
})
export class SitemapComponent {
  @Input() data: FooterSitemap;
}
