import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AnalyticsService } from '@services/analytics.service';
import { FaqBotService, Message } from '@services/faqbot.service';

@Component({
  selector: 'app-faqbot',
  templateUrl: './faqbot.component.html',
  styleUrls: ['./faqbot.component.scss'],
})
export class FaqbotComponent implements OnInit, AfterViewInit {

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly faqbotService: FaqBotService
  ) {}
  @ViewChild('chatBody', { static: false }) chatBody: ElementRef;

  messages: Observable<Message[]>;

  private scrollFn = () => {};

  ngOnInit() {
    this.messages = this.faqbotService.conversation.pipe(
      tap(() => setTimeout(this.scrollFn))
    );
  }

  ngAfterViewInit() {
    const el = this.chatBody.nativeElement;
    this.scrollFn = () => (el.scrollTop = el.scrollHeight);
  }

  open() {
    if (!this.faqbotService.conversationValue.length) {
      this.faqbotService.startConversation();
    }
  }

  send(input: HTMLInputElement) {
    if (!input.value) {
      return;
    }
    this.faqbotService.converse(input.value);

    this.analyticsService.trackEvent(
      `Dúvida: "${input.value}"`,
      'FAQ Bot',
      'Dúvida'
    );

    input.value = '';
  }
}
