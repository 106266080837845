import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FilterActivityModule } from '@pipes/filter-activity/filter-activity.module';
import { FuseModule } from '@pipes/fuse/fuse.module';
import { CheckboxModule } from '@sharedcomponents/checkbox/checkbox.module';
import { TabsModule } from '@sharedcomponents/tabs/tabs.module';
import { ModalGymActivityComponent } from './modal-gym-activity.component';

@NgModule({
  declarations: [ModalGymActivityComponent],
  imports: [
    CommonModule,
    CheckboxModule,
    FilterActivityModule,
    FormsModule,
    FuseModule,
    ModalModule,
    TabsModule,
  ],
  exports: [ModalGymActivityComponent],
})
export class ModalGymActivityModule {}
