import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  AlertMessage,
  ProfileAccount,
  MessageMap,
  UpdateUserCredentials,
} from '@models';
import { AlertMessageService } from '@services/alert-message.service';
import { LoadingService } from '@services/loading.service';
import { AppConstants } from '@utils/app-constants';
import { FormUtil } from '@utils/form-util';
import { UserService, AuthService } from '@services';
import { BrandService } from '@services/brand.service';

@Component({
  selector: 'app-profile-account',
  templateUrl: './profile-account.component.html',
  styleUrls: ['./profile-account.component.scss', '../profile.shared.scss'],
})
export class ProfileAccountComponent {
  @ViewChild('formAccount', { static: false }) form: NgForm;

  locale = AppConstants.LOCALE;
  info: ProfileAccount = {
    id: null,
    login: localStorage.getItem(AppConstants.STOR_LAST_LOGIN),
    email: localStorage.getItem(AppConstants.STOR_LAST_LOGIN),
    token: null,

    login1: null,
    login2: null,
    password: null,
  };

  loading = false;

  readonly emailRegexp = AppConstants.EMAIL_REGEXP;

  emptyMessage = MessageMap.INFO_NAO_DISPONIVEIS;

  constructor(
    private readonly alertMessageService: AlertMessageService,
    private readonly loadingService: LoadingService,
    private readonly userService: UserService,
    private readonly brandService: BrandService,
    private readonly authService: AuthService
  ) {}

  save() {
    FormUtil.touchForm(this.form.control);

    if (this.form.invalid) {
      this.alertMessageService.showToastr(
        AlertMessage.error(MessageMap.CAMPOS_EM_VERMELHO)
      );
      return;
    }
    this.updateUser();
  }

  private updateUser() {
    const user = this.authService.getUser();

    if (user && user.birthdate) {
      this.callUpdateService(user);
    } else {
      this.authService.getUserData().subscribe(
        (res) => {
          if (res?.customer && res.customer.birthdate) {
            this.callUpdateService(res.customer);
          } else {
            this.alertMessageService.showToastr(
              AlertMessage.warning(MessageMap.INFO_NAO_DISPONIVEIS)
            );
          }
        },
        () => {
          this.alertMessageService.showToastr(
            AlertMessage.warning(MessageMap.INFO_NAO_DISPONIVEIS)
          );
        }
      );
    }
  }

  updateCachedUser() {
    if (this.info.login1) {
      this.info.login = this.info.login1;
      const user = this.authService.getUser();
      user.login = this.info.login;
      this.authService.setUser(user);
    }
  }

  private callUpdateService(user: any) {
    const params: UpdateUserCredentials = {
      dateOfBirth: user.birthdate,
      brand: this.brandService.getBrand().toUpperCase(),
      registration: localStorage.getItem(
        AppConstants.STOR_APP_USER_REGISTRATION
      ),
    };
    if (this.info.password1) {
      params.password = this.info.password1;
    }
    if (this.info.login1) {
      params.email = this.info.login1;
    }

    this.loadingService.startLoading();
    this.userService.updateUserByRegistrationOrCpf(params).subscribe(
      (res) => {
        this.loadingService.stopLoading();
        if (res.data) {
          this.alertMessageService.showToastr(
            AlertMessage.success(MessageMap.INFO_SALVAS)
          );
          this.updateCachedUser();
          this.form.resetForm();
        }
      },
      () => {
        this.loadingService.stopLoading();
      }
    );
  }
}
