import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import {
  EvoGymMigratingRequest,
  EvoGymMigrationResponse,
  EvoMigratedUnitsListResponse,
} from '@models';
import { HttpCacheService } from '@services/http-cache.service';
import { back, BackEndpoints, external } from '@utils/app-endpoints';
import { EvoBaseUrls } from '@utils/evo';

@Injectable()
export class EvoGeneralService {
  constructor(
    private readonly http: HttpClient,
    private readonly httpCacheService: HttpCacheService
  ) {}

  openEvoSchedule(gymCode: number, isKids: boolean = false): void {
    let interpolatedUrl = external(
      environment.evo.scheduleBaseUrl + EvoBaseUrls.schedule,
      gymCode
    );

    if (isKids) {
      const ids = [
        1, // Kids 1
        2, // Kids 2
        3, // Kids 3
        4, // Kids 4
        5, // Kids
        7, // Bebê 1
        8, // Bebê 2
        10, // Treinamento
        11, // Pré equipe
        12, // Aperfeiçoamento
        13, // Kids Transição
        14, // Kids Adaptação
        15, // Kids Iniciação
        16, // Kids Aperfeiçoamento
        17, // Kids Avançado
        21, // Sub 4
        22, // Sub 5
        23, // Sub 6
        24, // Sub 7
        25, // Sub 8
        26, // Sub 9
        27, // Sub 10
        28, // Sub 11
        29, // Sub 12
        32, // 8 a 12 anos
        35, // 9 a 12 anos
        37, // 6 a 10 meses
        38, // 10 meses a 1 ano e 4 meses
        39, // 1 ano e 5 meses a 2 anos
        40, // 2 anos a 2 anos e 11 meses
        41, // 3 a 7 anos
        43, // 8 a 12 anos
        44, // 3 a 6 anos
        45, // 7 a 9 anos
        46, // 10 a 12 anos
        52, // 6 meses a 2 anos
        58, // 3 a 11 anos
        59, // 7 a 11 anos
        62, // Kids Adaptação 1
        63, // Kids Adaptação 2
        65, // Kids Adaptação 1
        66, // Kids Adaptação 2
        67, // Kids Iniciação
        68, // Kids Aperfeiçoamento
        69, // Kids Avançado
      ];
      interpolatedUrl += `?idsPublicos=${ids.join(',')}`;
    }

    window.open(interpolatedUrl);
  }

  checkMigratingGym(unit: number): Observable<EvoGymMigrationResponse> {
    const evoGymRequest: EvoGymMigratingRequest = { unit };

    return this.http.post<EvoGymMigrationResponse>(
      back(BackEndpoints.EvoCheckMigratingGym),
      evoGymRequest
    );
  }

  getMigratedUnitsList(): Observable<string[]> {
    return this.httpCacheService
      .get<EvoMigratedUnitsListResponse>(
        back(BackEndpoints.EvoMigratedUnitsList)
      )
      .pipe(map((response) => response.evoMigratedUnits));
  }
}
